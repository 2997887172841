@if (isReady) {
  <table>
    <tr class="table-header-row" [ngStyle]="rowHeaderStyle">
      @for (headerColumn of tableHeaderColumn; track headerColumn) {
        @if (headerColumn.sortable) {
          <th
            epSort
            [sortable]="headerColumn.key"
            [currentSortable]="orderByParams.ORDER_BY"
            [currentDirection]="orderByParams.DIRECTION"
            (sortBy)="sort($event)"
            [ngStyle]="headerColumn.style"
            class="cell header-cell sortable {{ headerColumn.key }}">
            <div class="wrap-sort">
              <span class="title">{{ headerColumn.title }}</span>
              <span class="sort-icon"></span>
            </div>
          </th>
        }
        @if (!headerColumn.sortable) {
          <th [ngStyle]="headerColumn.style" class="cell header-cell {{ headerColumn.key }}">
            <div class="wrap-sort">
              <span class="title">{{ headerColumn.title }}</span>
            </div>
          </th>
        }
      }
    </tr>
    @for (record of data; track trackBy(i, record); let i = $index) {
      <tr class="table-body-row" (click)="rowBodyClick(i, record)" [ngClass]="{'row-clicked': record['isExpended']}" [ngStyle]="rowBodyStyle">
        @for (column of tableColumn; track column) {
          <td class="cell body-cell" [ngStyle]="column.style">
            <ng-container *ngTemplateOutlet="customColumns[column.key] ? customColumns[column.key] : rowColumn; context: { value: record[column.key], column: column, index: i, record: record }"></ng-container>
          </td>
        }
      </tr>
      <!-- TODO: need to improve -->
      @if (record['isExpended']) {
        <tr class="table-body-row detail-row" [ngStyle]="rowBodyStyle">
          @for (column of expendedDetailColumns; track column) {
            @if (customColumns[column.key]) {
              <ng-container *ngTemplateOutlet="customColumns[column.key]; context: { value: record[column.key], column: column, index: i, record: record }"></ng-container>
            }
          }
        </tr>
      }
    }
  </table>
}

<ng-template #rowColumn let-value="value" let-column="column">
  @if (column.isTruncateText) {
    <div class="wrap-content">
      <span class="truncate-text">
        {{ value | default: column.defaultValue || '' }}
      </span>
    </div>
  }

  @if (!column.isTruncateText && !column.isBadge) {
    {{ column.formatDate ? (value | date: column.formatDate) :  (value | default: column.defaultValue || '') }}
  }
</ng-template>
