import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';

import { AppState } from '../../../reducers';
import { organizationsSwitch } from '@ngrx-app/global.actions';

@Injectable()
export class OrganizationsSwitchGuard {
  constructor(private store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (route.queryParamMap.get('organizationsSwitch')) {
      this.store.dispatch(organizationsSwitch({}));

      return false;
    } else {
      return true;
    }
  }
}
