@if (columns && tableData) {
  <table>
    <tr>
      @for (column of columns; track column) {
        <th [innerHtml]="column.header"></th>
      }
    </tr>
    @for (tableRow of tableData; track tableRow; let i = $index) {
      <tr
        [ngClass]="{ 'ng-star-inserted-highlighted': i === rowHighlighted }">
        @for (value of tableRow; track value) {
          <td [innerHtml]="value"></td>
        }
      </tr>
    }
  </table>
}
