import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartComponent } from './chart.component';
import { ChartService } from './chart.service';
import { ChartTooltipComponent } from './chart-tooltip/chart-tooltip.component';
import { AssurencePipesModule } from '@shared/pipes/assurence-pipes.module';
import { C3ChartComponent } from './c3-chart/c3-chart.component';
import { C3AdapterService } from './c3-chart/c3-chart.adapter.service';

@NgModule({
  imports: [CommonModule, AssurencePipesModule],
  declarations: [ChartComponent, ChartTooltipComponent, C3ChartComponent],
  exports: [ChartComponent, C3ChartComponent],
  providers: [ChartService, C3AdapterService],
})
export class ChartModule {}
