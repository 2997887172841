<ac-modal-header (close)="onModalClose()">
  <div class="modal-title">
    @if (!hasPermissionToShareViaEmail) {
      <span>Share Presentation</span>
    } @else {
      <span>Share Presentation by email or link</span>
    }
  </div>
</ac-modal-header>

<ac-modal-body>
  @if (!showSpinner) {
    <div class="modal-body">
      @if (!hasPermissionToShareViaEmail ) {
        <div
          [hidden]="!sharedLink"
          class="shared-link-block"
          >
          <input
            #sharedLinkElem
            type="text"
            class="form-control"
            [(ngModel)]="sharedLink"
            readonly
            />
          </div>
        } @else {
          @if (isAlreadyShared) {
            <div class="container-buttons pb-3">
              <se-button-group
                id="email"
                [buttons]="pageTabs"
                size="medium"
                (onSelectButton)="selectTab($event)"
              ></se-button-group>
            </div>
          }
          @switch (selectedTab) {
            @case ('addUser') {
              <form [formGroup]="sharePresentationForm">
                <div class="form-group">
                  <div class="d-flex flex-column position-relative">
                    <label class="label-text">Email</label>
                    <se-form-input
                      #emailInput
                      id="email"
                      formControlName="recipientEmail"
                      placeholder="email"
                    ></se-form-input>
                  </div>
                </div>
                <app-user-list
                  [users]="receiverUsersList"
                  [showRemoveButton]="true"
                  (removeUser)="onRemoveUserFromReceivers($event)"
                  (roleChange)="onSelectRole($event)"
                ></app-user-list>
                <div class="d-flex flex-column">
                  <label class="label-text">Add Note</label>
                  <se-textarea
                    id="note"
                    formControlName="shareNote"
                    name="note"
                    placeholder="Note"
                    resize="none"
                    [height]="56"
                    (keyup.enter)="$event.stopPropagation()"
                  ></se-textarea>
                </div>
              </form>
            }
            @case ('invitedUsers') {
              <app-user-list
                [users]="invitedUsersList"
                [showInviteInfo]="true"
              ></app-user-list>
            }
          }
        }
      </div>
    } @else {
      <div class="table-list-loader">
        <se-circle-loader type="circle-scale"></se-circle-loader>
      </div>
    }

  </ac-modal-body>

  @if (filteredUsersList.length && hasPermissionToShareViaEmail && sharePresentationForm.controls.recipientEmail.value) {
    <app-user-list
      [users]="filteredUsersList"
      [isAutocomplete]="true"
      [selectedIndex]="selectedIndex"
      [listPositionTop]="listPositionTop"
      [listPositionLeft]="listPositionLeft"
      [listWidth]="listWidth"
      (selectUser)="selectUser($event)"
    ></app-user-list>
  }

  <ac-modal-footer>
    @if (selectedTab !== 'invitedUsers') {
      <div
        class="d-flex btn-group"
        [ngClass]="{'flex-row-reverse justify-content-start': !hasPermissionToShareViaEmail, 'justify-content-between': hasPermissionToShareViaEmail}"
        >
        @if (isSendBtnDisabled || !receiverUsersList.length) {
          <se-button
            #copyBtn
            class="primary"
            size="medium"
            (click)="copyLinkToClipboard()"
            >
            Copy Link
            <img
              src="../../../../../assets/icons/copy-link-icon.svg"
              alt="Copy link icon"
              />
            </se-button>
          } @else {
            <se-button
              type="button"
              class="secondary"
              size="medium"
              (click)="copyLinkToClipboard()"
              >
              Copy Link
              <img
                src="../../../../../assets/icons/copy-link-icon-blue.svg"
                alt="Copy link icon"
                />
              </se-button>
            }
            <div class="action-btn-group btn-group">
              <se-button
                type="button"
                class="secondary"
                size="medium"
                (click)="onModalClose()"
                >
                Cancel
              </se-button>
              @if (hasPermissionToShareViaEmail) {
                <se-button
                  type="button"
                  class="primary"
                  size="medium"
                  [disabled]="isSendBtnDisabled"
                  (click)="onSendPresentationViaEmail()"
                  >
                  Send
                </se-button>
              }
            </div>
          </div>
        } @else {
          @if (!showSpinner) {
            <div class="container-pagination px-3">
              <se-pager
                [options]="paginationOptions"
                [pagesSliceCount]="5"
                (onPageChange)="setPaginationParams($event)"
              ></se-pager>
            </div>
          }
        }

      </ac-modal-footer>
