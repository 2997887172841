<ac-modal-header (close)="closeModal()">
  <p class="modal-title">Sales Story Added Date</p>
</ac-modal-header>

<ac-modal-body>
  <div class="modal-body-container d-flex flex-column">
    <ac-input>
      <input
        bsDatepicker
        #datePicker
        [(ngModel)]="currentVersionAddedDate"
        [bsConfig]="datePickerConfig"
        (bsValueChange)="changedVersionDate($event)"
        [minDate]="minDateInCalendar"
        [disabled]="!(versionsLoading$ | async)"
        class="form-control"
        placeholder="Select date"
        />
        <ac-icon
          acSuffix
          icon="calendar"
          [width]="24"
          [height]="24"
          color="#333333"
        ></ac-icon>
      </ac-input>

      <span class="selected-version font-weight-normal">
        Selected version: {{ currentVersionName }}
      </span>

      @if (versionsLoading$ | async) {
        <div class="table-versions">
          <div class="d-flex align-items-center">
            <span class="icon-info"></span>
            <span class="version-list">Versions</span>
          </div>
          <table
            class="table border-0 m-0"
          [class.table-body-scrollable]="
            listOfVersions.length > visibleVersionLimit
          "
            >
            <thead class="table-header">
              <tr>
                <th class="border-0 text-nowrap">Name</th>
                <th class="border-0">Start Date</th>
                <th class="border-0">End Date</th>
              </tr>
            </thead>
            <tbody class="table-body">
              @for (version of listOfVersions; track version) {
                <tr>
                  @if (version.startDate) {
                    <td class="border-0 p-1 text-nowrap">{{ version.versionName }}</td>
                    <td class="border-0 p-1">
                      {{ version.startDate | date: 'MM/dd/yyyy' }}
                    </td>
                    <td class="border-0 p-1">
                      {{
                      version.endDate
                      ? (version.endDate | date: 'MM/dd/yyyy')
                      : 'Now'
                      }}
                    </td>
                  }
                </tr>
              }
            </tbody>
          </table>
        </div>
      } @else {
        <div class="table-list-loader">
          <se-circle-loader type="circle-scale"></se-circle-loader>
        </div>
      }

    </div>
  </ac-modal-body>

  <ac-modal-footer>
    <div class="justify-content-start">
      <se-button class="ac-button mr-4" (click)="saveAndClose()">Save</se-button>
      <se-button class="secondary" (click)="closeModal()">Cancel</se-button>
    </div>
  </ac-modal-footer>
