import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { IAMService } from '@assurance/um-services';

@Injectable()
export class CustomPagePermissionGuard {
  constructor(private router: Router, private iamService: IAMService) {}

  canActivate(parentRoute): boolean {
    return this.checkAccess(parentRoute?.data['requiredPermission']);
  }

  canActivateChild(parentRoute: ActivatedRouteSnapshot): boolean {
    return this.checkAccess(parentRoute?.data['requiredPermission']);
  }

  private checkAccess(requiredPermission: string): boolean {
    if (requiredPermission && this.iamService.hasUserAccess(requiredPermission)) {
      return true;
    }

    this.router.navigate(['/home']);

    return false;
  }
}
