@if (questionsData$ | async) {
  <se-dynamic-form
    [questionsData]="questionsData$"
    [formId]="stateId"
    [params]="{}"
  ></se-dynamic-form>
}
<div class="file-upload">
  <se-file-uploader
    [config]="logoUploadConfig"
    (onUpload)="addImage($event)"
  ></se-file-uploader>
</div>

<hr />

<ep-images-preview
  [imagesList]="imagesList$ | async"
  (selectImage)="onSelectImage($event)"
  (deleteImage)="onDeleteImage($event)"
  (loadedImage)="onLoadedImage($event)"
></ep-images-preview>
