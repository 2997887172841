import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import * as apiConstant from '@core/constant';
import { pendingExports } from '@shared/constants';
import { getSearchQuery } from '@core/utils';
import { CareerPlan, CustomHttpParams, Presentation, Presentations, ResponseType } from '@core/model';
import {
  FullNameRes,
  CaseResponse,
  PageConfig,
  SalesConceptDataResponse,
  HomeTableRequestParams,
  PlansQuantity,
  Carriers,
  PlanOption,
  CompilePageOptions,
  CustomPage,
  PendingExportsData,
  NavbarCustomPage,
  NavbarCustomPagesOptions,
  CustomPagesConfig,
  DataSourcesConfig,
  CustomPageVersions,
  ShareableLinkResponse,
} from '@shared/models';
import { CustomPageCacheService } from '../../../components/presentation/setup/custom-page/custom-page-cache.service';
import { LocalStorageService } from '@core/service';
import { RoleCategory } from '@assurance/um-services/public-api';

// TODO: need to separate methods, move to corresponding service
@Injectable()
export class APIService {
  constructor(
    private http: HttpClient,
    private customPageCacheService: CustomPageCacheService,
    private localStorage: LocalStorageService
  ) {}

  getSupportInfo<T>(): Observable<T> {
    return this.http.get<T>(apiConstant.support);
  }

  public sendSharedLinkAnalyticsEvent(data: { [key: string]: string }) {
    return this.http.post(apiConstant.sharedAnalytics, data);
  }

  public getPendingExportsByTransaction(transactionUUID: string): Observable<ResponseType<PendingExportsData[]>> {
    const restUrl = `${pendingExports}/${transactionUUID}`;

    return this.http.get<ResponseType<PendingExportsData[]>>(restUrl);
  }

  public callCustomApi(data) {
    return this.http.post(apiConstant.customCall, data);
  }

  public callSharedCustomApi(data) {
    return this.http.post(apiConstant.sharedCustomCall, data);
  }

  public distributeTemplate<T extends { add: number[]; remove: string[] }>(
    templateId: number,
    request: { organizations: T; groups: T }
  ) {
    return this.http.post<ResponseType<any>>(
      `${apiConstant.presentation}/${templateId}/${apiConstant.distribute}`,
      request
    );
  }

  public copyPresentation(data: {
    agencyIds: number[];
    presentationsIds: number[];
    setRevisionHistory: boolean;
    storeCopyCount: boolean;
  }) {
    return this.http.post<ResponseType<any>>(`${apiConstant.presentation}/${apiConstant.clone}`, data);
  }

  // @deprecated
  public login(data: any) {
    return this.http.post<ResponseType<any>>(apiConstant.login, data);
  }

  // @deprecated
  public logout() {
    return this.http.get<ResponseType<any>>(apiConstant.logout);
  }

  public deleteCustomPageFromPresentation(presentationId: number, uiId: string) {
    return this.http.delete<ResponseType<any>>(
      `${apiConstant.presentation}/${presentationId}/${apiConstant.setupConfigs}/custom-page/${uiId}`
    );
  }

  public addPageConfigToPresentation<T>(presentationId: number, data: T): Observable<ResponseType<any>> {
    return this.http.post<ResponseType<any>>(
      `${apiConstant.presentation}/${presentationId}/${apiConstant.pageConfig}`,
      data
    );
  }

  public replacePageConfigInPresentation(presentationId: number, data: PageConfig): Observable<ResponseType<any>> {
    return this.http.put<ResponseType<any>>(
      `${apiConstant.presentation}/${presentationId}/${apiConstant.pageConfig}`,
      data
    );
  }

  public getPresentations(
    query: Record<string, string | number | boolean> | HomeTableRequestParams,
    productType?: string,
    withDeletedUsers?: boolean,
    templates = false,
    count = false
  ): Observable<ResponseType<Presentations>> {
    const restUrl = count ? apiConstant.presentationCount : apiConstant.presentation;

    if (productType) {
      Object.assign(query, { product_type: productType });
    }

    if (withDeletedUsers) {
      Object.assign(query, { DELETED_USERS: withDeletedUsers });
    }

    if (templates) {
      Object.assign(query, { TEMPLATE: templates });
    }

    return this.http.get<ResponseType<Presentations>>(restUrl, {
      params: getSearchQuery(query),
    });
  }

  public getPlansCountByIds(ids: number[]): Observable<ResponseType<PlansQuantity>> {
    return this.http.post<ResponseType<PlansQuantity>>(apiConstant.presentationsPlansCount, ids);
  }

  public updateVersionAddedDate(
    presentationId: number,
    customPageUUID: string,
    addedDate: string
  ): Observable<ResponseType<{ success: boolean }>> {
    const url = `${apiConstant.presentation}/${presentationId}/${apiConstant.setupConfigs}/${customPageUUID}/version`;

    return this.http.patch<ResponseType<{ success: boolean }>>(url, { addedDate: addedDate });
  }

  public getTransactionCaseId(params: { transactionUUID: string }) {
    return this.http.post<ResponseType<CaseResponse>>(apiConstant.transactionCaseData, params);
  }

  public getPresentationsQty(query: any, productType?: string) {
    const restUrl = apiConstant.presentationQty;

    if (productType) {
      Object.assign(query, { product_type: productType });
    }

    return this.http.get<ResponseType<any>>(restUrl, {
      params: getSearchQuery(query),
    });
  }

  public patchPresentation(id: number, data: any) {
    const restUrl = `${apiConstant.presentation}/${id}/update`;

    return this.http.patch<ResponseType<any>>(restUrl, data);
  }

  public removePresentation(id: number) {
    return this.http.delete<ResponseType<any>>(`${apiConstant.presentation}/${id}`);
  }

  public getCareerPlans(id: number): Observable<ResponseType<CareerPlan[]>> {
    return this.http.get<ResponseType<CareerPlan[]>>(`${apiConstant.presentation}/${id}/${apiConstant.carrierPlans}`);
  }

  public getInforceCSV(data: { inforceFilePath: string }) {
    return this.http.get<{ csv: string; fileName: string; success: boolean }>(apiConstant.downloadInforceCSV, {
      params: getSearchQuery(data),
    });
  }

  public getPresentationMetadataList() {
    return this.http.get<ResponseType<any>>(`${apiConstant.presentation}/${apiConstant.metadata}/list`);
  }

  public removePlan(id: number) {
    return this.http.delete<ResponseType<any>>(`${apiConstant.carrierPlanData}/${id}`);
  }

  public saveCarrierPlan(params: any) {
    const restUrl = `${apiConstant.presentation}/${params.presentationId}/${apiConstant.carrierPlans}`;

    return this.http.post<ResponseType<any>>(restUrl, params.jsonCarrierPlanData);
  }

  public updateCarrierPlan(params: any) {
    const restUrl = `${apiConstant.carrierPlanData}/${params.planId}`;

    return this.http.put<ResponseType<any>>(restUrl, params.jsonCarrierPlanData);
  }

  public getCarrierPlansConfig(organization?: string) {
    const query = {
      organization: organization,
    };

    return this.http.get<ResponseType<any>>(apiConstant.carrierPlansConfig, {
      params: getSearchQuery(query),
    });
  }

  public getSharedCarrierPlansConfig(organization?: string) {
    const query = {
      organization: organization,
    };

    return this.http.get<ResponseType<any>>(apiConstant.sharedCarrierPlansConfig, {
      params: getSearchQuery(query),
    });
  }

  getAllRoleCategories(): Observable<RoleCategory[]> {
    return this.http
      .get<ResponseType<RoleCategory[]>>(`/um/v1${apiConstant.roleCategory}?includeRoles=true`)
      .pipe(map((res: ResponseType<RoleCategory[]>) => res.data || []));
  }

  //TODO: this method use in presentation-sharing.service
  getSharedToken(presentationId: number): Observable<string> {
    return this.http
      .get<ResponseType<ShareableLinkResponse>>(`${apiConstant.baseSharedUrl}token`, {
        params: getSearchQuery({ presentationId }),
      })
      .pipe(map(response => response.data.token));
  }

  public updatePlansOrder(data: PlanOption[], presentationId: number) {
    const restUrl = `${apiConstant.presentation}/${presentationId}/${apiConstant.plansOrder}`;

    return this.http.put<ResponseType<any>>(restUrl, {
      plansOrder: data,
      presentationId,
    });
  }

  public getSetupPageConfig(presentationId: number, presentationType?: string) {
    const queryParams = {};

    if (presentationType) {
      Object.assign(queryParams, { type: presentationType });
    }

    const restUrl = `${apiConstant.presentation}/${presentationId}/${apiConstant.setupConfigs}`;

    return this.http.get<ResponseType<any>>(restUrl, {
      params: getSearchQuery(queryParams),
    });
  }

  public putSetupPageConfig(presentationId: number, data: any) {
    const restUrl = `${apiConstant.presentation}/${presentationId}/${apiConstant.setupConfigs}`;

    return this.http.put<ResponseType<any>>(restUrl, data);
  }

  public getDependentPageConfig(presentationId: number) {
    const restUrl = `${apiConstant.presentation}/${presentationId}/${apiConstant.dependentPagesConfigs}`;

    return this.http.get<ResponseType<any>>(restUrl);
  }

  public putDependentPageConfig(presentationId: number, data: Partial<CustomPagesConfig>[]) {
    const restUrl = `${apiConstant.presentation}/${presentationId}/${apiConstant.dependentPagesConfigs}`;

    return this.http.put<ResponseType<any>>(restUrl, data);
  }

  public getPresentationConfig(presentationId: number): Observable<ResponseType<Presentation>> {
    return this.http.get<ResponseType<Presentation>>(`${apiConstant.presentation}/${presentationId}`);
  }

  public getCoverLetter(presentationID: number) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.coverLetter}`;

    return this.http.get<ResponseType<any>>(restUrl);
  }

  public getPageConfig(presentationID: number, configKey: string) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.setupConfigs}/${configKey}`;

    return this.http.get<ResponseType<any>>(restUrl);
  }

  public putConfigByChunks(data: any, presentationID: number, configKey: string, uiId?: string) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.setupConfigs}/${configKey}`;

    return this.http.put<ResponseType<any>>(restUrl, data, {
      params: getSearchQuery({ uiId }),
    });
  }

  public getPagesList(params: CustomHttpParams) {
    return this.http.get<ResponseType<any>>(apiConstant.customPagesContent, {
      params: getSearchQuery(params),
    });
  }

  public getPages(params: Record<string, string | string[] | boolean>, isShared = false) {
    return this.http.get<ResponseType<any>>(
      isShared ? apiConstant.sharedContentServiceContent : apiConstant.contentServiceContentCustomPagesByUuId,
      {
        params: getSearchQuery(params),
      }
    );
  }

  public getPagesListByLabel(
    params: Record<string, string | string[] | boolean>
  ): Observable<ResponseType<{ data: CustomPage[] }>> {
    return this.http.get<ResponseType<{ data: CustomPage[] }>>(
      `${apiConstant.contentServiceContentCustomPagesByLabels}`,
      {
        params: getSearchQuery(params),
      }
    );
  }

  public getAgencyChildren(id: number) {
    return this.http.get<ResponseType<any>>(`${apiConstant.agency}/${id}/${apiConstant.agencyChildren}`);
  }

  public getCustomPagesInserts(params: CustomHttpParams, isShared = false) {
    const url = isShared
      ? `${apiConstant.sharedContentServiceContent}${apiConstant.pagesInserts}`
      : `${apiConstant.contentServiceContent}${apiConstant.pagesInserts}`;

    const uniqueObj = {
      params: getSearchQuery(params),
      isShared,
      url,
    };
    let cachedInsertsReq$ = this.customPageCacheService.getValue(uniqueObj);

    if (!cachedInsertsReq$) {
      cachedInsertsReq$ = this.http
        .get<ResponseType<any>>(url, {
          params: getSearchQuery(params),
        })
        .pipe(shareReplay(1));
      this.customPageCacheService.setValue(cachedInsertsReq$, uniqueObj);
    }

    return cachedInsertsReq$.pipe(map(res => res.data?.data));
  }

  public getCustomPagesInsertsByPageIds(params: CustomHttpParams, isShared = false) {
    const url = isShared
      ? `${apiConstant.sharedContentServiceContent}${apiConstant.insertsByPageIds}`
      : `${apiConstant.contentServiceContent}${apiConstant.insertsByPageIds}`;

    const uniqueObj = {
      params: getSearchQuery(params),
      isShared,
      url,
    };

    let cachedInsertsReq$ = this.customPageCacheService.getValue(uniqueObj);

    if (!cachedInsertsReq$) {
      cachedInsertsReq$ = this.http
        .get<ResponseType<any>>(url, { params: getSearchQuery(params) })
        .pipe(shareReplay(1));
      this.customPageCacheService.setValue(cachedInsertsReq$, uniqueObj);
    }

    return cachedInsertsReq$.pipe(map(res => res.data?.data));
  }

  public getCompiledPages(params: any, data: CompilePageOptions, isShared: boolean, presentationId: number) {
    const url = isShared
      ? `${apiConstant.sharedContentServiceContent}/${apiConstant.compile}`
      : `${apiConstant.contentServiceContent}/${apiConstant.compile}`;
    const options = {
      params: getSearchQuery(params),
    };

    if (presentationId) {
      Object.assign(options, {
        headers: new HttpHeaders({
          PresentationId: presentationId?.toString(),
        }),
      });
    }

    return this.http.post<ResponseType<any>>(url, data, options);
  }

  public downloadMetricsForSalesPage(
    uiId: string,
    data: CompilePageOptions,
    presentationId: number
  ): Observable<ArrayBuffer> {
    const options: any = {
      headers: new HttpHeaders({
        Accept: 'application/csv',
        'Content-Type': 'application/json',
      }),
      responseType: 'blob',
    };

    if (presentationId) {
      Object.assign(options, {
        headers: new HttpHeaders({
          PresentationId: presentationId?.toString(),
        }),
      });
    }

    return this.http.post(`${apiConstant.downloadMetricsCSV}/${uiId}`, data, options);
  }

  public getCompiledPage(
    uiId: string,
    data: CompilePageOptions,
    isShared: boolean,
    params: CustomHttpParams,
    presentationID: number,
    pageId: string,
    tookByFormula: boolean
  ) {
    const compilePath = tookByFormula ? apiConstant.compileByPageId : apiConstant.compile;
    const idPath = tookByFormula ? pageId : uiId;

    const url = isShared
      ? `${apiConstant.sharedContentServiceContent}/${compilePath}/${idPath}`
      : `${apiConstant.contentServiceContent}/${compilePath}/${idPath}`;

    const options = {
      params: getSearchQuery(params),
    };
    let headers = new HttpHeaders();

    if (presentationID) {
      headers = headers.set('PresentationId', presentationID.toString());
    }

    Object.assign(options, { headers });

    return this.http.post<ResponseType<any>>(url, data, options);
  }

  public uploadCustomImage(data: FormData) {
    return this.http.post<ResponseType<any>>(apiConstant.customImage, data);
  }

  public deleteCustomImage(fileName: string) {
    return this.http.delete<ResponseType<void>>(apiConstant.customImage, {
      params: getSearchQuery({ fileName }),
    });
  }

  public getSalesConceptData(presentationId: number): Observable<SalesConceptDataResponse[]> {
    let headers = new HttpHeaders();

    if (presentationId) {
      headers = headers.set('PresentationId', presentationId.toString());
    }

    return this.http
      .get<ResponseType<{ data: SalesConceptDataResponse[] }>>(`${apiConstant.salesConceptData}`, { headers })
      .pipe(map(response => response.data.data));
  }

  public getCarriersList(): Observable<ResponseType<Carriers>> {
    return this.http.get<ResponseType<Carriers>>(`${apiConstant.carriersList}`);
  }

  public getUsersFullName(params: { ids: number[] }): Observable<FullNameRes> {
    return this.http.get<FullNameRes>(apiConstant.usersFullName, {
      params: getSearchQuery(params),
    });
  }

  public downloadPDF(resource: string, sharedToken?: string) {
    const params = {
      resource,
    };

    if (sharedToken) {
      Object.assign(params, { sharedToken });
    }

    return this.http.get(apiConstant.downloadPDF, {
      params,
      responseType: 'blob',
      headers: {
        'shared-token': sharedToken || '',
      },
    });
  }

  public downloadFileFromObjectURL(url: string) {
    return this.http.get(url, { observe: 'response', responseType: 'blob' });
  }

  // TODO: this method is dublicated in CustomPageApiPage
  getCustomPage(id: string, params: { showAllInsertsUsages: boolean }): Observable<CustomPage> {
    return this.http
      .get<ResponseType<{ data: CustomPage }>>(`${apiConstant.contentServiceContent}/${id}`, {
        params: getSearchQuery(params),
      })
      .pipe(map((res: ResponseType<{ data: CustomPage }>) => res.data.data));
  }

  //TODO: move this method to custom-page module
  public removeContentServiceEntity(id: string) {
    return this.http.delete<ResponseType<any>>(`${apiConstant.contentServiceContent}/${id}`);
  }

  //TODO: move this method to custom-page module
  public removeDependentPageConfig(id: string, params: { insert?: boolean } = {}) {
    return this.http.delete<ResponseType<any>>(
      `${apiConstant.presentation}/${apiConstant.dependentPagesConfigs}/${id}`,
      {
        params: getSearchQuery(params),
      }
    );
  }
  //TODO: move this method to custom-page module
  public removeCustomPageConfigsFromPresentations(uiId: string) {
    return this.http.delete<ResponseType<any>>(
      `${apiConstant.presentation}/${apiConstant.setupConfigs}/custom-page/${uiId}`
    );
  }

  public putCoverLetter(presentationID: number, data: any) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.coverLetter}`;

    return this.http.put<ResponseType<any>>(restUrl, data);
  }

  public deleteCoverLetter(presentationID: number) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.coverLetter}`;

    return this.http.delete<ResponseType<any>>(restUrl);
  }

  public postCoverLetter(presentationID: number, data: any) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.coverLetter}`;

    return this.http.post<ResponseType<any>>(restUrl, data);
  }

  public createHistoryEvent(presentationID: number, data: any) {
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.events}`;

    return this.http.post<ResponseType<any>>(restUrl, data);
  }

  public getSubmittedPlans(data: Array<number | string>): Observable<ResponseType<CareerPlan[]>> {
    const restUrl = `${apiConstant.dropTicketPlans}`;

    return this.http.post<ResponseType<CareerPlan[]>>(restUrl, data);
  }

  public downloadCSV(planId: any): Observable<any> {
    const restUrl = `${apiConstant.carrierPlanData}/${planId}/${apiConstant.csv}`;

    const headersList: any = {};
    headersList.Accept = 'application/csv';
    headersList['Content-Type'] = 'application/json';

    const options: any = {
      headers: new HttpHeaders({ ...headersList }),
      responseType: 'blob',
    };

    return this.http.get(restUrl, options);
  }

  public downloadRawPayload(presentationId: number): Observable<any> {
    const restUrl = `${apiConstant.downloadIncomingData}`;

    const headersList: Record<string, string> = {};
    headersList.Accept = 'application/zip';
    headersList['Content-Type'] = 'application/zip';

    const options: Record<string, unknown> = {
      headers: new HttpHeaders({ ...headersList }),
      responseType: 'blob',
      observe: 'response',
      params: getSearchQuery({ presentationId }),
    };

    return this.http.get(restUrl, options);
  }

  public updatePresentationClientName(presentationID: number, clientName: string) {
    const data = { clientName };
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.clientName}`;

    return this.http.put<ResponseType<any>>(restUrl, data);
  }

  public updatePresentationName(presentationID: number, presentationName: string, presentationNewName: string) {
    const data = { presentationNewName, presentationName };
    const restUrl = `${apiConstant.presentation}/${presentationID}/${apiConstant.presentationName}`;

    return this.http.put<ResponseType<any>>(restUrl, data);
  }

  public getCurrentTheme() {
    return this.http.get(apiConstant.sharedCurrentTheme).pipe(map((res: any) => res.data));
  }

  public getCoverLetterTemplates(params: any): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedContentServiceContent, {
      params: getSearchQuery(params),
    });
  }

  public getDependentPages(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedDependentPagesConfig);
  }

  public getDependentPermissions(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedDependentPermissions);
  }

  /**
   * External request to EIQ for getting case info.
   */
  public getCaseInfo(caseId: number): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(
      `${this.localStorage.getNotJSONData('eiqApiUrl')}/single-entry-app/v1/cases/${caseId}/info`
    );
  }

  public getCases(payload: { [key: string]: string | number }): Observable<ResponseType<any>> {
    return this.http.post<ResponseType<any>>(
      `${this.localStorage.getNotJSONData('eiqApiUrl')}/single-entry-app/v1/cases/search`,
      payload
    );
  }

  public createCase(payload: unknown): Observable<ResponseType<any>> {
    return this.http.post<ResponseType<any>>(
      `${this.localStorage.getNotJSONData('eiqApiUrl')}/single-entry-app/v1/cases`,
      payload
    );
  }

  public getSharedCarrierPlans(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedCarrierPlanData);
  }

  public getSharedPresentationConfigs(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedPresentationConfigs);
  }

  public updateSharedPresentationVersion(
    sharedToken: string,
    customPageUUID: string,
    addedDate: string
  ): Observable<ResponseType<{ success: boolean }>> {
    const url = `${apiConstant.sharedPresentationConfigs}/${customPageUUID}/version`;
    const headers = new HttpHeaders({
      'shared-token': sharedToken,
    });

    return this.http.patch<ResponseType<{ success: boolean }>>(url, { addedDate: addedDate }, { headers: headers });
  }

  onOpenPresentationSharedByEmail(metaParam: string): Observable<ResponseType<{ success: boolean }>> {
    const url = `${apiConstant.baseSharedUrl}${apiConstant.shareByEmail}`;
    const payload = { meta: metaParam };

    return this.http.patch<ResponseType<{ success: boolean }>>(url, payload);
  }

  public getSharedMetadataList(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedMetadata);
  }

  public getSharedPresentation(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedPresentation);
  }

  public getSharedCoverLetter(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedPresentationCoverLetter);
  }

  public getSharedAgencyRules(): Observable<ResponseType<any>> {
    return this.http.get<ResponseType<any>>(apiConstant.sharedAgencyRules);
  }

  public createEvent(data: { eventName: string; extraInfo: string | null }): Observable<ResponseType<any>> {
    return this.http.post<ResponseType<any>>(apiConstant.sharedCreateEvent, data);
  }

  public sendFile(path: string, method: 'PUT' | 'POST', file: File): Observable<Record<string, unknown>> {
    const body: FormData = new FormData();
    body.append('file', file);

    const headers = new HttpHeaders().append('Accept', 'application/json');

    const options = {
      body,
      headers,
    };

    return this.http.request<Record<string, unknown>>(method, path, options);
  }

  getNavbarSalesConceptsAndDependentPages(
    body: {
      customPagesByUiId: NavbarCustomPagesOptions[];
      productsOrders: { id: number; order: number }[] | number[][];
    },
    isShared: boolean,
    presentationId: number
  ): Observable<NavbarCustomPage[]> {
    const url = isShared ? apiConstant.sharedNavbarCustomPages : apiConstant.navbarCustomPages;
    let headers = new HttpHeaders();

    if (presentationId) {
      headers = headers.set('PresentationId', presentationId.toString());
    }

    return this.http
      .post<ResponseType<{ data: NavbarCustomPage[] }>>(url, body, { headers })
      .pipe(map((data: ResponseType<{ data: NavbarCustomPage[] }>) => data.data.data));
  }

  getNavbarEndPages(
    body: Record<string, NavbarCustomPagesOptions[] | string[]>,
    isShared: boolean,
    presentationId: number
  ): Observable<NavbarCustomPage[]> {
    const url = isShared ? apiConstant.sharedNavbarCustomPages : apiConstant.navbarCustomPages;

    let headers = new HttpHeaders();

    if (presentationId) {
      headers = headers.set('PresentationId', presentationId.toString());
    }

    return this.http
      .post<ResponseType<{ data: NavbarCustomPage[] }>>(url, body, { headers })
      .pipe(map((data: ResponseType<{ data: NavbarCustomPage[] }>) => data.data.data));
  }

  getDataSourcesConfig(): Observable<DataSourcesConfig[]> {
    return this.http
      .get<ResponseType<DataSourcesConfig[]>>(apiConstant.dataSourcesConfig)
      .pipe(map((res: ResponseType<DataSourcesConfig[]>) => res.data));
  }

  getCustomPageVersions(uuId: string): Observable<CustomPageVersions> {
    return this.http
      .get<ResponseType<{ data: CustomPageVersions }>>(`${apiConstant.versions}/${uuId}`)
      .pipe(map((response: ResponseType<{ data: CustomPageVersions }>) => response.data.data));
  }
}
