import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { finalize, tap } from 'rxjs/operators';

import { AuthService } from '@assurance/um-services';
import { AlertService } from '@se/common';

import { PreviewCompiledPageService } from '@shared/services';
import { LocalStorageService } from '@core/service';
import { ensightTitle, userPilotStorageKey } from '@shared/constants';

@Component({
  selector: 'ensight-manage-password',
  styleUrls: ['../login.scss'],
  templateUrl: 'manage-password.component.html',
})
export class ManagePasswordComponent implements OnInit {
  transactionUUID = this.route.snapshot.queryParams.transactionUUID;
  first = this.route.snapshot.params.first;
  forced = this.route.snapshot.params.forced;

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private router: Router,
    public authService: AuthService,
    private previewCompiledPageService: PreviewCompiledPageService,
    private localStorage: LocalStorageService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(ensightTitle);
  }

  // TODO: need to change interface
  // submitForm(response: { success: boolean; data: { message: string; error: { message: string[] } } }): void {
  submitForm(response: any): void {
    if (response.success) {
      this.alertService.openAlert({
        type: 'neutral',
        body: 'The password has been successfully updated.',
        autoClose: 5000,
      });

      if (this.first) {
        this.router.navigate(['/home'], {
          queryParams: { transactionUUID: this.transactionUUID },
        });
      } else {
        this.authService
          .logout()
          .pipe(
            tap(() => {
              this.previewCompiledPageService.clearPresentationAndConfig();
              this.localStorage.setData(userPilotStorageKey, false);
            }),
            finalize(() => this.router.navigate(['/login']))
          )
          .subscribe();
      }
    } else {
      this.alertService.openAlert({
        type: 'error',
        body: response.data?.error?.message?.toString() || response.data.message,
        autoClose: 5000,
      });
    }
  }

  cancelForm(): void {
    this.router.navigate(['/']);
  }
}
