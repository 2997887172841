import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from '../../../../reducers';
import { getPdfGeneration } from '@ngrx-app/global.selectors';
import { CustomPageVersion, CustomPageVersions } from '@shared/models';
import { PermissionKeyEnum } from '@core/enums';
import { OperatorKey } from '@shared/constants';
import { IAMService } from '@assurance/um-services';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'ensight-custom-page-header',
  templateUrl: './custom-page-header.component.html',
  styleUrls: ['./custom-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPageHeaderComponent implements OnInit {
  pdfGeneration$ = this.store.select(getPdfGeneration);

  @Input() addNoteControl: UntypedFormControl;
  @Input() title: string;
  @Input() showSaveBtn: boolean;
  @Input() disableSaveBtn: boolean;
  @Input() loading: boolean;
  @Input() showSecondary: boolean;
  @Input() customPageVersions: CustomPageVersions;
  @Input() customPageVersionId: string;
  @Input() isNotEndPage: boolean;
  @Input() presentationName: string;
  @Input() isNotDependentPage: boolean;
  @Input() isEditMode: boolean;

  @Output() onSave = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onPdfDownload = new EventEmitter();
  @Output() onPageSettings = new EventEmitter();
  @Output() changeVersion = new EventEmitter<string>();
  @Output() openManageVersionModal = new EventEmitter<void>();
  @Output() openChoosePresentationModal = new EventEmitter<void>();
  @Output() clearPresentation = new EventEmitter<void>();
  @Output() openPageEditText = new EventEmitter<void>();
  @Output() openPageHistory = new EventEmitter<void>();
  @Output() exportCustomPage = new EventEmitter<void>();

  Permissions = PermissionKeyEnum;
  OperatorKey = OperatorKey;
  isMenuOpen = false;
  isBtnMenuShown = true;
  constructor(private store: Store<AppState>, private iamService: IAMService) {}

  ngOnInit(): void {
    this.setBtnMenu();
  }

  selectVersion(version: CustomPageVersion): void {
    this.changeVersion.emit(version.pageId);
  }

  manageVersion(): void {
    this.openManageVersionModal.emit();
  }

  choosePresentation(): void {
    this.openChoosePresentationModal.emit();
  }

  clearSelectedPresentation(): void {
    this.clearPresentation.emit();
  }

  openPageSettings(): void {
    this.onPageSettings.emit();
  }

  openEditText(): void {
    this.openPageEditText.emit();
  }

  leaveCustomPage(): void {
    this.onClose.emit();
  }

  savePage(): void {
    this.onSave.emit();
  }

  downloadPdf(): void {
    this.onPdfDownload.emit();
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  openHistory(): void {
    this.openPageHistory.emit();
  }

  exportPage(): void {
    this.exportCustomPage.emit();
  }

  clickOutside(): void {
    this.isMenuOpen = false;
  }

  setBtnMenu(): boolean {
    return (this.isBtnMenuShown =
      (this.iamService.hasUserAccess(this.Permissions.export_custom_page) && this.isNotEndPage) ||
      this.iamService.hasUserAccess(this.Permissions.view_custom_page_change_log));
  }
}
