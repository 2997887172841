<p>{{ labels?.tabs }}</p>

@if (isDynamicMetrics) {
  <se-button-group
    [ngClass]="{ 'btn-group-infinity': isPieDonutBarChart }"
    [buttons]="configurationButtons"
    size="medium"
    (onSelectButton)="onSelectConfiguration($event)"
  ></se-button-group>
}

@if (questionsData$ | async; as questionsData) {
  @for (qs of questionsData; track qs.name; let i = $index) {
    @if (i === activeConfigurationKey) {
      <se-dynamic-form
        [questionsData]="qs"
        [formId]="i"
        [params]="{}"
      ></se-dynamic-form>
    }
  }
}

@if (labels?.hint) {
  <label data-at-id="hint-label">
    {{ labels.hint }}
  </label>
}

@if (isPieDonutBarChart && isDynamicMetrics) {
  <div class="buttons-wrap">
    <se-button class="secondary" (click)="addNewMetric()">Add Metric</se-button>
    <se-button class="delete" [disabled]="configurationButtons.length === 1" (click)="deleteMetric()">Delete Metric</se-button>
  </div>
}
