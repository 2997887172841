<ac-modal-header (close)="modal.close(false)">
  <header class="title">
    {{ config.data?.title || 'Product Import' }}
  </header>
</ac-modal-header>

<ac-modal-body>
  <div class="content">
    <div class="plan-header">
      @if (!config.data?.disabledJurisdictionStates) {
        <span>
          You are attempting to import the following products that have not been
          approved for usage by the carrier:
        </span>
      }
      @if (config.data?.disabledJurisdictionStates) {
        <span>
          You are attempting to import products that are under a restricted
          jurisdiction: {{ config.data?.disabledJurisdictionStates }}
        </span>
      }
    </div>
    <div class="plans-list">
      @for (plan of config.data?.plans; track plan) {
        <div class="plan-list-item">
          <div class="company-name">{{ plan.companyName }}</div>
          <div data-at-id="product-name">{{ plan.productName }}</div>
        </div>
      }
    </div>
    @if (config.data?.availablePlans) {
      <div class="plan-header header-content">
        Do you want to continue importing the following products?
      </div>
      <div class="plans-list">
        @for (plan of config.data?.availablePlans; track plan) {
          <div
            class="plan-list-item"
            >
            <div class="company-name">{{ plan.companyName }}</div>
            <div data-at-id="product-name">{{ plan.productName }}</div>
          </div>
        }
      </div>
    }
  </div>
</ac-modal-body>

<ac-modal-footer>
  <div class="buttons-block">
    @if (config.data?.activateSubmit) {
      <ac-button
        type="submit"
        (click)="modal.close(true)"
        >
        OK
      </ac-button>
    }
    <ac-button class="secondary" (click)="modal.close(false)">Cancel</ac-button>
  </div>
</ac-modal-footer>
