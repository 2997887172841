@if (!salesConcepts || showSpinner) {
  <div class="spinnerHolder">
    <ac-loader></ac-loader>
  </div>
}

<ac-modal-header (close)="onCancel()">
  <h2 class="title">{{ views[selectedView].title }}</h2>
</ac-modal-header>

<ac-modal-body [ngClass]="{ 'settings-view': selectedView === 2 }">
  @switch (selectedView) {
    @case (0) {
      <section class="sidebar-container sales-concept-list" [ngClass]="{ withTemplates: salesConcepts && salesConcepts.length }">
        @if (salesConcepts && salesConcepts.length) {
          <aside
            class="sales-concepts-modal d-flex flex-column"
            >
            <se-form-input
              class="sales-concept-search"
              [formControl]="searchControl"
              [placeholder]="'Search by Name'"
              [type]="'search'"
            ></se-form-input>
            @if (showSearchSpinner) {
              <div class="spinner-container align-content-center">
                <ac-loader></ac-loader>
              </div>
            }
            @if (!showSearchSpinner) {
              @if (filteredSalesConcepts.length) {
                <ul class="mt-4">
                  @for (page of filteredSalesConcepts; track page; let i = $index) {
                    <li
            title="{{ page.salesConceptLabel }}{{
              formErrors.sidebarPage(
                  i,
                  page.productsRange[0],
                  page.carrierUiIds
                )
              }}"
            [class.disabled]="
              isPageDisabled(page) || !isProductTypeValid(page)
            "
                      [class.selected]="isActivePage(page.config.uiId)"
                      (click)="choosePage(page)"
                      >
                      <h5 class="item-title">{{ page.salesConceptLabel }}</h5>
                    </li>
                  }
                </ul>
              }
              @if (!filteredSalesConcepts.length) {
                <div
                  class="spinner-container no-item-display align-content-center align-self-center"
                  >
                  No items to display
                </div>
              }
            }
          </aside>
        }
        @if (activePage && !activePage?.description && !activePage?.previewFilePath) {
          <div class="template-block">
            <div class="preview-block">
              <p class="preview-message">The preview is not available</p>
            </div>
          </div>
        }
        @if (activePage?.description || activePage?.previewFilePath) {
          <div
            class="preview-block"
            [class.error]="!activePageAvailable"
            >
            @if (activePage?.description) {
              <p>{{ activePage?.description }}</p>
            }
            @if (activePage?.previewFilePath) {
              <figure class="preview-image-container">
                @if (previewImageLoading) {
                  <div class="spinnerHolder">
                    <ac-loader></ac-loader>
                  </div>
                }
                <img
                  class="preview-image"
                  [src]="activePage?.previewFilePath"
                  alt="preview"
                  (load)="onPreviewImageLoad()"
                  [hidden]="previewImageLoading"
                  />
                </figure>
              }
            </div>
          }
        </section>
      }
      @case (1) {
        <form class="body-container" [formGroup]="salesConceptForm" (ngSubmit)="views[selectedView].onSubmit()">
          <div class="name-field-container">
            <span class="label required">Sales Story Name:</span>
            <input
              title="{{ formErrors.errorMessage }}"
              formControlName="title"
              type="text"
              class="form-control"
              placeholder="Sales story name"
              />
            </div>
            <section class="sidebar-container">
              <div class="plans-container">
                <div class="plans-item header">
                  <h5>PRODUCTS ({{ plans.length }})</h5>
                  <div>{{ productRange }}</div>
                </div>
                <ul formArrayName="products">
                  @for (plan of formControls.products.controls; track plan; let i = $index) {
                    <li class="plans-item" title="{{ plans[i].errorMessage }}">
                      @if (showRadioButtons()) {
                        <div class="radio-btn align-content-center">
                          <se-radio-button
                            [disabled]="plan.disabled"
                            [formControlName]="i"
                            [checked]="plan.value"
                            [value]="plan.value"
                            (onSelect)="handleRadioButtonEvent(i)"
                          ></se-radio-button>
                        </div>
                      } @else {
                        <div class="checkbox">
                          <se-checkbox
                            [disabled]="plan.disabled"
                            [size]="'large'"
                            [checked]="plan.value"
                            [formControlName]="i"
                            [customIcon]="findById(plans[i].id)"
                            (onToggle)="checkboxEvent(i)"
                          ></se-checkbox>
                        </div>
                      }
                      <div class="content">
                        <h5>{{ plans[i].productName }}</h5>
                        <div class="mb-2">{{ plans[i].label }}</div>
                        <ensight-note-input
                          class="note-field"
                          [note]="plans[i].productNote"
                          (changedNote)="onSaveNote(plans[i], $event)"
                          (keydown.escape)="$event.stopPropagation()"
                        ></ensight-note-input>
                      </div>
                    </li>
                  }
                </ul>
              </div>
            </section>
          </form>
        }
        @case (2) {
          @if (settingsForm) {
            <form class="settings-form" [formGroup]="settingsForm" (ngSubmit)="views[selectedView].onSubmit()">
              @for (insert of inserts; track insert) {
                <div class="control-container">
                  <label class="label">{{ insert.metadata.placeholderName }}</label>
                  <se-form-input
                    class="form-input"
                    [formControl]="settingsForm.controls[insert.config.uiId]"
                    [inputPattern]="inputPattern"
                  ></se-form-input>
                  @for (message of getErrorMessages(settingsForm.controls[insert.config.uiId].errors, insert); track message) {
                    <span
                      class="error-message"
                      >
                      {{ message }}
                    </span>
                  }
                </div>
              }
            </form>
          }
        }
      }
    </ac-modal-body>

    <ac-modal-footer>
      <ac-button (click)="onCancel()" class="secondary" size="sm">Cancel</ac-button>
      @if (isBackBtnShown()) {
        <ac-button (click)="onBack()" class="secondary" size="sm">
          Back
        </ac-button>
      }
      <ac-button [disabled]="!formAvailable" (click)="views[selectedView].onSubmit()" type="submit" size="sm">
        {{ getButtonLabel(selectedView) }}
      </ac-button>
    </ac-modal-footer>
