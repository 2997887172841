<div class="search-container">
  <se-form-input
    [formControl]="searchControl"
    placeholder="Search by Case, Agent Name, Description, Created By"
  ></se-form-input>
  <span class="fa fa-search"></span>
</div>
@if (!dataLoading) {
  <div class="summary-table-container">
    @if (tableData[0]?.data.length) {
      <se-summary-table
        tableType="compact"
        emptyDataSymbol=""
        [classes]="['no-underline', 'divider']"
        [data]="tableData"
        [tableHeaders]="tableHeadersData"
        [rowHoverEffect]="true"
        (headerClick)="onHeaderClick($event)"
        (onRowClick)="onRowClick($event)"
      ></se-summary-table>
    }
    <se-pager
      [options]="pagination"
      (onPageChange)="onPageChange($event)"
    ></se-pager>
  </div>
}
@if (!dataLoading && !tableData[0]?.data.length) {
  <se-notification
    type="no-entities-found"
    [title]="tableLabels.noEntitiesFound.title"
    [message]="tableLabels.noEntitiesFound.message"
  ></se-notification>
}
@if (dataLoading) {
  <div class="table-list-loader">
    <se-circle-loader type="circle-scale"></se-circle-loader>
  </div>
}
