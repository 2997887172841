import { UPLOAD_CONFIG_VALIDATIONS } from '@shared/constants';
import { EligibleProductTypes, IFilterBlockDataPages, CustomPageVersions } from '@shared/models';
import { CustomPagesLists } from '../models';
import { INSERT_TYPE } from '@core/enums';

export const CUSTOM_PAGES_LIST_URL = '/custom-pages';
export const SUCCESSFUL_UPDATE_MESSAGE = 'Your changes have been successfully saved!';

export const EDITOR_FONT_SIZES =
  '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt 38pt 40pt 42pt 44pt 46pt 48pt';

export const CUSTOM_PAGE_SETTINGS_TYPES: IFilterBlockDataPages[] = [
  {
    label: 'Required page',
    id: 'requiredpage',
    selected: true,
  },
  {
    label: 'Dependent page',
    id: 'dependentpage',
    selected: false,
  },
  {
    label: 'Sales concept',
    id: 'salesconcept',
    selected: false,
  },
];

export const PRODUCT_TYPES: EligibleProductTypes[] = [
  {
    label: 'Guaranteed Universal Life',
    productType: 'GUL',
    selected: false,
  },
  {
    label: 'Indexed Universal Life',
    productType: 'IUL',
    selected: false,
  },
  {
    label: 'Long Term Care',
    productType: 'LTC',
    selected: false,
  },
  {
    label: 'Term Life',
    productType: 'Term',
    selected: false,
  },
  {
    label: 'Universal Life',
    productType: 'UL',
    selected: false,
  },
  {
    label: 'Whole Life',
    productType: 'WL',
    selected: false,
  },
  {
    label: 'Variable Universal Life',
    productType: 'VUL',
    selected: false,
  },
  {
    label: 'Annuity',
    productType: 'Annuity',
    selected: false,
  },
  {
    label: 'Variable Annuity',
    productType: 'Variable Annuity',
    selected: false,
  },
];

export const CUSTOM_PAGE_SETTINGS_PRODUCTS_QUANTITY = {
  min: 1,
  max: 40,
};

export const PREVIEW_UPLOAD_CONFIG = {
  type: 'image',
  fileExtension: 'image/*',
  image: {
    preview: true,
    title: 'Sales Concept Preview',
    addBtnLabel: 'Upload...',
    deleteBtnLabel: 'Remove',
    src: null,
  },
  validations: UPLOAD_CONFIG_VALIDATIONS,
};

export const CUSTOM_PAGE_SETTINGS_LAYOUT_BUTTONS = [
  {
    label: 'Landscape',
    key: 'landscape',
    classes: 'secondary',
    active: true,
    id: null,
    type: null,
  },
  {
    label: 'Portrait',
    key: 'portrait',
    classes: 'secondary',
    active: false,
    id: null,
    type: null,
  },
];

export const DefaultUnsupportedDataSourcesErrorMessage =
  'The sales story cannot be used with the illustrations in this presentation';
export const incorrectCurlyBracesNumberMessage = 'The number of opened and closed curly brackets doesn’t match.';

export const ALLIANZ_ORGANIZATION = 'allianz';
export const PACIFIC_LIFE_ORGANIZATION = 'pacific-life-annuity';

export const getEmptyVersions = (uiId: string): CustomPageVersions => ({
  customPageUUID: uiId,
  versions: [],
  pickVersionFormula: null,
});
export const getEmptyCustomPagesLists = (): CustomPagesLists => {
  const customPageList = { count: 0, data: [], currentPage: 1 };

  return {
    dependentpage: customPageList,
    salesconcept: customPageList,
    requiredpage: customPageList,
  };
};

// Properties 'id' and 'type' are required in se-button-group component, but here them don't need
export const PageButtons = [
  {
    label: 'Sales Concepts',
    key: 'salesconcept',
    classes: 'secondary',
    active: false,
    id: null,
    type: null,
    disabled: true,
  },
  {
    label: 'Dependent Pages w/o Sales Concepts',
    key: 'dependentpage',
    classes: 'secondary',
    active: false,
    id: null,
    type: null,
    disabled: true,
  },
  {
    label: 'Required Pages',
    key: 'requiredpage',
    classes: 'secondary',
    active: false,
    id: null,
    type: null,
    disabled: true,
  },
];

export const UsedInPagesTitle = 'insert is used on the following pages';

export const hintForProductsOrderFormula =
  '{{#each products}} {{#if (gt this.configjson.metadata.ipr_holding_period 0) }} {\n        "carrierPlanId": {{this.id}},\n        "order": 1\n    } {{else}} {\n        "carrierPlanId": {{this.id}},\n        "order": -1\n    } {{/if}} {{#unless @last}},\n    {{/unless}} \n{{/each}}';

export const CompilationResultMessages = {
  failed: 'Compilation failed with errors.',
  success: 'Compilation has been completed without errors.',
};

export const KeyForCopy = {
  [INSERT_TYPE.variable]: 'variables',
  [INSERT_TYPE.dropdown]: 'selectedDropdownIndex',
  [INSERT_TYPE.productSelector]: 'productSelector',
  [INSERT_TYPE.tab]: 'tabs',
};

export const PageLocation = [
  {
    value: false,
    label: 'At the end of the presentation',
  },
  {
    value: true,
    label: 'At the start of the presentation',
  },
];
