@if (isLoadingVersionOrCompiledPage && !loading) {
  <div class="spinner-holder">
    <ac-loader></ac-loader>
  </div>
}

<section class="content a-app-main-container-background-color" [ngClass]="{ loader: loading, hidden: !loading }"></section>

@if (customPage) {
  <div class="custom-page-wrapper" data-at-id="custom-page">
    @if (!PDFPrint) {
      <ensight-custom-page-header
        [title]="customPage?.name"
        [showSecondary]="true"
        [isNotEndPage]="isNotEndPage"
        [isNotDependentPage]="isNotDependentPage"
        [customPageVersionId]="customPage._id"
        [customPageVersions]="customPageVersions"
        [presentationName]="selectedPresentation?.name"
        (changeVersion)="changeVersion($event)"
        (onPdfDownload)="downloadPDF()"
        (onClose)="leaveCustomPage()"
        (onPageSettings)="openPageSettings()"
        (openManageVersionModal)="openManageVersionModal()"
        (openChoosePresentationModal)="openChoosePresentationModal()"
        (clearPresentation)="clearPresentation()"
        (openPageEditText)="openPageEditText()"
        (openPageHistory)="openPageHistory()"
        (exportCustomPage)="exportCustomPage()"
      ></ensight-custom-page-header>
    }
    @if (PDFPrint) {
      <div class="header-pdf"></div>
    }
    @if (!compiledCustomPage) {
      <section
        #templateRef
        [innerHTML]="htmlBody"
        class="custom-page-body"
        data-at-id="custom-page-body"
        [class.custom-page-body-pdf]="PDFPrint">
      </section>
    }
    @if (compiledCustomPage) {
      <div class="custom-page-body custom-page-compiled" [class.custom-page-body-pdf]="PDFPrint">
        <ep-insert-content
          #innerContent
          [inserts]="inserts"
          [isCompiledPagePreview]="true"
          [body]="compiledCustomPage.customFields.htmlBody">
        </ep-insert-content>
      </div>
    }
  </div>
}
