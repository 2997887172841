<div class="content-container" (mouseup)="stopPin.emit()">
  @for (item of data; track $index) {
  <ng-container [class.total]="item.isTotal">
    @if (!item.hideMetric) {
    <div
      class="content"
      [style.font-size]="chartOptions?.chart?.chartFontSizeToolTipLabel"
    >
      <span class="title">
        {{ item.title$ | async }}
      </span>
      @if (item.isPercentYAxis && !item.isWholeNumberYAxis) {
      <span
        class="value"
        [style.color]="item.color"
        [style.font-size]="chartOptions?.chart?.chartFontSizeToolTipNumber"
      >
        {{ item.y | planPersentage: 2:item.noValueLabel:item.isCustomMetric }}
      </span>
      } @if (!item.isPercentYAxis && !item.isWholeNumberYAxis) {
      <span
        class="value"
        [style.color]="item.color"
        [style.font-size]="chartOptions?.chart?.chartFontSizeToolTipNumber"
      >
        {{ item.y | planCurrency: 'USD':0:item.noValueLabel }}
      </span>
      } @if (item.isWholeNumberYAxis) {
      <span
        class="value"
        [style.color]="item.color"
        [style.font-size]="chartOptions?.chart?.chartFontSizeToolTipNumber"
      >
        {{ item.y | number: '1.0-0' }}
      </span>
      }
    </div>
    }
  </ng-container>
  }
</div>
