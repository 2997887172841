import { ElementRef, Injectable } from '@angular/core';

import { filter, first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  cloneDeep,
  find,
  forOwn,
  get,
  isArray,
  isEmpty,
  isNil,
  max,
  merge,
  min,
  orderBy,
  remove,
  sortBy,
  trim,
} from 'lodash-es';

import { INSERT_PLACEHOLDER_SELECTOR, SALES_CONCEPT_METRICS } from '@shared/constants';
import { InsertContentDirective } from '@shared/pipes/insert-content.pipe';
import {
  ColorScheme,
  CustomPageInsert,
  CustomPagesConfig,
  CustomPagesItemConfig,
  DataLimits,
  DataLimitsSource,
  Insert,
} from '@shared/models';
import { getPresentationPlans, getXAxisSource } from '../../../components/presentation/presentation.selectors';
import { presentationPlansUpdateSuccess } from 'src/app/components/presentation/presentation.actions';
import { AppState } from '../../../reducers';
import { Store } from '@ngrx/store';

import {
  CHART_BACKGROUNDS,
  CHART_HEIGHT,
  C3JS_CHART_TYPES,
  NON_DISPLAYABLE_PIN_TYPE,
  PIN_LINE_HEIGHT,
  PIN_VALUE_MARGIN,
  X_AXIS_SOURCE_DEFAULT,
  X_AXIS_SOURCES,
} from '@shared/components/chart/chart.constants';
import { ChartOptionsService } from '@core/service/ChartOptions.service';
import { ChartDataService } from '@core/service/ChartData.service';
import { Global, MetricsService } from '@shared/services';
import { pixels } from '@core/utils/parse-pixels';
import {
  ActivePlan,
  CareerPlan,
  ChartBackgroundType,
  ChartMetric,
  ChartPlaceholder,
  ChartType,
  ConfigPlanOrganisationData,
  CustomMetricPlaceholder,
  ExtendedChartMetadata,
  ExtendedPlaceholderMetadata,
  InsertType,
  Metric,
  XAxisSourceType,
} from '@core/model';
import { CALCULATED_DATA_TARGET, ChartTypes, ChartDataSource, INSERT_TYPE } from '@core/enums';
import { MetricType } from '@shared/constants/metric-type.enum';
import { isDefined } from '@core/utils';

const PLACEHOLDER_SELECTOR = '.app-chart';

@Injectable()
export class ChartsInjectService {
  private plans: CareerPlan[];
  private xAxisSource: XAxisSourceType;
  private selectedMetrics: Metric[] = [];

  constructor(
    public store: Store<AppState>,
    public chartOptionsService: ChartOptionsService,
    public chartDataService: ChartDataService,
    public global: Global,
    private metricsService: MetricsService
  ) {}

  public getChartsPlaceholdersMetadata(content?: ElementRef): ExtendedPlaceholderMetadata[] {
    const appCharts: NodeListOf<HTMLElement> = this.getChartPlaceholdersElements(content);

    return this.getPlaceholdersMetadata(appCharts);
  }

  public getInsertsMetadata(
    insertContentRef: InsertContentDirective,
    selectedPageInserts: CustomPageInsert[],
    parentUiId: string,
    superParent = null,
    themeColors: ColorScheme[],
    customPagesConfig: CustomPagesConfig[],
    dataLimits: DataLimitsSource,
    isPdf = false
  ): ExtendedPlaceholderMetadata[] {
    const insertInstances = this.getInsertInstances(insertContentRef);
    this.extendInsertsMetadata(insertInstances, selectedPageInserts, parentUiId, superParent, themeColors);
    insertInstances.forEach(
      // TODO: need to change !!!
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (item: ExtendedPlaceholderMetadata<ExtendedChartMetadata>) =>
        item.isChartPlaceholder &&
        this.injectChartsData(item, customPagesConfig, item.id, dataLimits, isPdf, selectedPageInserts)
    );

    return insertInstances;
  }

  public extendInsertsMetadata(
    insertInstances: ExtendedPlaceholderMetadata[],
    selectedPageInserts: CustomPageInsert[],
    parentUiId: string,
    superParent = null,
    themeColors: ColorScheme[]
  ) {
    // TODO: need to change !!!
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    insertInstances.forEach((item: ExtendedPlaceholderMetadata<ExtendedChartMetadata>) => {
      const page = find(selectedPageInserts, { metadata: { id: item.id } });

      if (page) {
        const data = item.isChartPlaceholder
          ? this.convertChartInserts(page as CustomPageInsert<ChartPlaceholder>, parentUiId, superParent, themeColors)
          : {
              calculatedData: page.calculatedData,
              editable: page.config.editable,
              ...page.metadata,
              filesLinks: page.filesLinks,
            };
        merge(item, data);
      } else {
        item.deleted = true;
      }
    });
    remove(
      // TODO: need to change !!!
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      insertInstances,
      (inst: ExtendedPlaceholderMetadata<ExtendedChartMetadata>) => inst.deleted
    );
  }

  // public extendInsertsMetadata1(
  //   insertInstances: ExtendedPlaceholderMetadata[],
  //   selectedPageInserts: CustomPageInsert[],
  //   parentUiId: string,
  //   superParent = null,
  //   themeColors: ColorScheme[]
  // ): ExtendedPlaceholderMetadata[] {
  //
  //   return selectedPageInserts.reduce((acc, item: CustomPageInsert) => {
  //     const insertInstance = insertInstances.find(item => item.id === item.id);
  //
  //     const data = insertInstance?.isChartPlaceholder
  //       ? this.convertChartInserts(item as CustomPageInsert<ChartPlaceholder>, parentUiId, superParent, themeColors)
  //       : {
  //           calculatedData: item.calculatedData,
  //           editable: item.config.editable,
  //           ...item.metadata,
  //           filesLinks: item.filesLinks,
  //         };
  //
  //     return [...acc, merge(item, data)];
  //   }, []);
  // }

  public getInsertInstances(insertContentRef: InsertContentDirective): ExtendedPlaceholderMetadata[] {
    const result: ExtendedPlaceholderMetadata[] = [];
    const refs: HTMLElement[] = this.getInsertPlaceholdersRefs(insertContentRef);
    refs.forEach(ref => {
      ref.removeAttribute('style');
      const idRef = ref.querySelector('.id');
      const typeRef = ref.querySelector('.type');
      const id = idRef ? idRef.textContent : null;
      const insertType = typeRef ? (typeRef.textContent.toLowerCase() as InsertType) : null;

      if (id && insertType) {
        const styles = window.getComputedStyle(ref);
        const margin =
          parseFloat(styles.paddingLeft) +
          parseFloat(styles.paddingRight) +
          parseFloat(styles.marginLeft) +
          parseFloat(styles.marginRight);

        const item: ExtendedPlaceholderMetadata = {
          id,
          insertType,
          elementRef: ref,
          body: '',
          isChartPlaceholder: insertType === 'chart',
          width: ref.parentElement.offsetWidth - (margin || 0),
        };
        result.push(item);
      }
    });

    return result;
  }

  public getInsertPlaceholdersRefs(insertContentRef: InsertContentDirective): HTMLElement[] {
    return insertContentRef?.viewContainerRef.element.nativeElement.querySelectorAll(`.${INSERT_PLACEHOLDER_SELECTOR}`);
  }

  public injectChartsData(
    metadata: ExtendedPlaceholderMetadata<ExtendedChartMetadata>,
    customPagesConfig: CustomPagesConfig[],
    pageId: string,
    dataLimits: DataLimitsSource,
    isPdf = false,
    selectedPageInserts: CustomPageInsert[] | Insert[]
  ) {
    this.setSelectedMetrics(metadata, selectedPageInserts as CustomPageInsert<CustomMetricPlaceholder>[]);
    this.setChartData(metadata, dataLimits);

    this.setChartOptions(metadata, customPagesConfig, pageId, isPdf);
  }

  private setSelectedMetrics(
    metadata: ExtendedPlaceholderMetadata<ExtendedChartMetadata>,
    selectedPageInserts: CustomPageInsert<CustomMetricPlaceholder>[] = []
  ) {
    const isStaticMetrics = metadata.chartDataSource === ChartDataSource.staticMetrics;
    const customMetricsInserts = selectedPageInserts.filter(
      insert => insert.metadata.insertType === INSERT_TYPE.customMetric
    );

    const source =
      (isStaticMetrics && metadata.metricDataSource === CALCULATED_DATA_TARGET.metadata) ||
      metadata.type === ChartTypes.clusteredBarChart
        ? 'meta'
        : 'data';
    const metrics = this.global.getPlansConfig[source].map((item: ConfigPlanOrganisationData) =>
      this.metricsService.getMetric(item.db, [], source)
    );

    const defaultMetrics = [
      ...SALES_CONCEPT_METRICS,
      ...metrics,
      ...customMetricsInserts.map(insert => ({
        isIRR: false,
        key: insert.metadata.placeholderKey,
      })),
    ];

    if (typeof metadata.metrics === 'string') {
      metadata.metrics = this.parseMetrics(<string>metadata.metrics);
    }

    this.selectedMetrics = [];

    if (isStaticMetrics) {
      this.setSelectedStaticsMetrics(metadata, defaultMetrics);
    } else {
      this.setSelectedDynamicMetrics(metadata, defaultMetrics, customMetricsInserts, source);
    }

    metadata.isPercentYAxis = get(this.selectedMetrics, '[0].isPercentYAxis');

    if (Array.isArray(metadata.metrics)) {
      this.selectedMetrics.forEach(selectedMetric => {
        const metricKey = selectedMetric.key;
        const foundMetric = (metadata.metrics as ChartMetric[])?.find(metric => metric.metricKey === metricKey);

        if (foundMetric) {
          foundMetric.isCustomMetric = selectedMetric.isCustomMetric;
        }
      });
    }
  }

  private setSelectedDynamicMetrics(
    metadata: any,
    defaultMetrics: any[],
    customMetricsInserts: any[],
    source: string
  ): void {
    metadata.metrics.forEach(item => {
      const metric = find(defaultMetrics, { key: item.metricKey });

      if (metric) {
        this.metricsService.addTitleToMetric(metric, source);
        const chartType = item.chartType || metadata.type;
        const isPieOrDonutOrBar =
          chartType === ChartTypes.pie || chartType === ChartTypes.donut || chartType === ChartTypes.clusteredBarChart;
        const customMetricTitle = customMetricsInserts.find(insert => item.metricKey === insert.metadata.placeholderKey)
          ?.metadata.placeholderName;
        const customMetric = customMetricsInserts.find(
          insert => item.metricKey === insert.metadata.placeholderKey
        )?.metadata;

        const isPersentCustomMetric = customMetric?.metricType === MetricType.percent;
        const isWholeNumberCustomMetric = customMetric?.metricType === MetricType.wholeNumber;

        const metricTitlePieOrDonut = item.metricTitleForPieOrDonutChart
          ? item.metricTitleForPieOrDonutChart
          : (metric as Metric).title ?? customMetricTitle;

        const metricTitle = isPieOrDonutOrBar ? metricTitlePieOrDonut : item.metricTitle || customMetricTitle;

        this.selectedMetrics.push({
          ...metric,
          order: item.order,
          chartType: item.chartType || metadata.type,
          stepLineType: item.stepLineType,
          productIndex: item.productIndex,
          background: item.background,
          metricTitle,
          opacity: item?.opacity,
          stackedMetric: item.stackedMetric,
          stackedGroup: item.stackedGroup,
          isPercentYAxis: metric.isPercentYAxis || isPersentCustomMetric,
          isWholeNumberYAxis: isWholeNumberCustomMetric,
          isCustomMetric: !!customMetricsInserts.find(insert => item.metricKey === insert.metadata.placeholderKey),
          yAxis: item.yAxis,
        });
      }
    });
  }

  private setSelectedStaticsMetrics(metadata: any, defaultMetrics: any[]): void {
    const metricInfo = metadata.metrics[0];
    const metricKeyData = find(defaultMetrics, { key: metricInfo.metricKeyData });
    const metricKeyLabel = find(defaultMetrics, { key: metricInfo.metricKeyLabels });

    this.selectedMetrics.push({
      metricKeyData: metricInfo.metricKeyData,
      metricKeyLabels: metricInfo.metricKeyLabels,
      order: metricInfo.order,
      chartType: metadata.type,
      productIndex: metricInfo.productIndex,
      staticMetrics: { metricKeyData, metricKeyLabel },
    });
  }

  public setData(
    activePlans: ActivePlan[],
    calculatedData: { [metricKey: string]: number[] }[] = [],
    hasNewProductsOrder = false,
    newProductsOrder: number[]
  ): Observable<unknown>[] {
    const getPresentationPlans$ = this.store.select(getPresentationPlans).pipe(
      filter((data: CareerPlan[]) => !!data.length),
      map((data: CareerPlan[]) => {
        let plans;

        if (hasNewProductsOrder && activePlans?.length) {
          const indexMap = new Map(newProductsOrder.map((id, index) => [id.toString(), index]));
          plans = sortBy(activePlans, [obj => indexMap.get(obj.carrierPlanId.toString())]).map(plan =>
            data.find(product => product.id === plan.carrierPlanId)
          );
        } else if (!hasNewProductsOrder && activePlans?.length) {
          plans = orderBy(activePlans, ['order']).map((activePlan: ActivePlan) =>
            find(data, product => product.id === activePlan.carrierPlanId)
          );
        } else {
          plans = orderBy(data, ['order']);
        }

        this.plans = plans.map((plan: CareerPlan, index) => {
          return {
            ...plan,
            configjson: {
              ...plan.configjson,
              data: {
                ...plan.configjson.data,
                ...(calculatedData[index] || {}),
              },
              metadata: {
                ...plan.configjson.metadata,
                ...(calculatedData[index] || {}),
              },
            },
          };
        });

        return this.plans;
      }),
      first()
    );

    const getXAxisSource$ = this.store.select(getXAxisSource).pipe(
      filter(data => !!data),
      map(data => (this.xAxisSource = data)),
      first()
    );

    return [getPresentationPlans$, getXAxisSource$];
  }

  public setPlansForPreview(activePlans: CareerPlan[], calculatedData: { [metricKey: string]: number[] }[] = []): void {
    this.plans = activePlans.map((plan, index) => {
      return {
        ...plan,
        configjson: {
          ...plan.configjson,
          data: {
            ...plan.configjson.data,
            ...(calculatedData[index] || {}),
          },
          metadata: {
            ...plan.configjson.metadata,
            ...(calculatedData[index] || {}),
          },
        },
      };
    });

    this.store.dispatch(presentationPlansUpdateSuccess({ plans: this.plans }));
  }

  public setXAxisSourceForPreview(xAxisSource: XAxisSourceType): void {
    this.xAxisSource = xAxisSource;
  }

  public setChartData(metadata: ExtendedPlaceholderMetadata<ExtendedChartMetadata>, dataLimits: DataLimitsSource) {
    const xAxis: XAxisSourceType =
      metadata.xAxisSource === 'metric' ? metadata.xAxisSource : this.getXAxisValue(metadata.xAxis);
    const limits: DataLimits = this.getCurrentLimits(xAxis, dataLimits);
    const data = this.chartDataService.getChartData({
      plans: this.plans,
      selectedMetrics: this.selectedMetrics,
      keyX: xAxis,
      chartType: metadata.type,
      xMin:
        metadata.xAxisSource === 'metric'
          ? metadata.xAxisSourceStart || null
          : metadata.xAxisSourceStart || limits.minAgeDisplay,
      xMax:
        metadata.xAxisSource === 'metric'
          ? metadata.xAxisSourceEnd || null
          : metadata.xAxisSourceEnd || limits.maxAgeDisplay,
      yMin: metadata?.isYAxisAffectedByLimits ? limits.bottomIrr : undefined,
      yMax: metadata?.isYAxisAffectedByLimits ? limits.topIrr : undefined,
      area: metadata['chart-background'] === 'solid',
      metricBasedArea: metadata['chart-background'] === 'metricBased',
      metricBase: true,
      isStaticMetric: metadata.chartDataSource === ChartDataSource.staticMetrics,
      metricDataSource: metadata.metricDataSource,
      chartInterpolate: metadata.chartInterpolate,
      xAxisMetric: metadata.xAxisMetric,
    });
    metadata.data = cloneDeep(data.data);
  }

  public setChartOptions(
    metadata: ExtendedPlaceholderMetadata<ExtendedChartMetadata>,
    customPagesConfig: CustomPagesConfig[],
    pageId: string,
    isPdf: boolean
  ) {
    const xAxisMetricLabel: string =
      metadata.xAxisName || this.global.getPlansConfig.data.find(config => config.db === metadata.xAxisMetric)?.csv;
    const xAxisSource: XAxisSourceType =
      metadata.xAxisSource === 'metric' ? metadata.xAxisSource : this.getXAxisValue(metadata.xAxis);
    const options = this.chartOptionsService.getOptions({
      xAxisSource,
      xAxisMetricLabel,
      yKey: metadata.yAxisName,
      isPercentYAxis: metadata.isPercentYAxis,
      isWholeNumberYAxis: metadata.isWholeNumberYAxis,
      pageID: pageId,
      pinValue:
        this.getPinOptions<number>(metadata, customPagesConfig, 'pinValue') || this.getDefaultPinValue(metadata),
      type: metadata.type,
      isPdf,
    });
    metadata.options = merge(options, {
      chart: {
        height: metadata.height,
        chartHeight: metadata.chartHeight,
        chartFontSizeAxesLabels: metadata.chartFontSizeAxesLabels,
        chartFontSizeXAxesLabels: metadata.chartFontSizeXAxesLabels,
        chartFontSizeYAxesLabels: metadata.chartFontSizeYAxesLabels,
        fontSizeValueOnChart: metadata.fontSizeValueOnChart,
        chartFontSizeAxesNames: metadata.chartFontSizeAxesNames,
        chartFontSizeToolTipLabel: metadata.chartFontSizeToolTipLabel,
        chartFontSizeToolTipNumber: metadata.chartFontSizeToolTipNumber,
        chartFontSizeXAxisValuePin: metadata.chartFontSizeXAxisValuePin,
        margin: {
          top: 10,
        },
        useInteractiveGuideline: false,
        pinLineHeight: this.getPinHeight(metadata.height),
        legendType: metadata.legendType,
        legendPosition: metadata.legendPosition,
        valueDisplay: metadata.valueDisplay,
        donutWidth: metadata.donutWidth,
        defaultColors: metadata.defaultColors,
        fontSizeOfChartLabels: metadata.fontSizeOfChartLabels,
        stackedMetric: metadata.stackedMetric,
        stackedGroup: metadata.stackedGroup,
        secondLineDescription: metadata.secondLineDescription,
        legendTitle: metadata.legendTitle,
        legendSubtitle: metadata.legendSubtitle,
        chartDataSource: metadata.chartDataSource,
        metricDataSource: metadata.metricDataSource,
        metricTitleForPieOrDonutChart: metadata.metricTitleForPieOrDonutChart,
        axes: metadata.axes,
        additionalYAxisName: metadata.additionalYAxisName,
        hideAdditionalYAxis: metadata.hideAdditionalYAxis,
        xAxisYearHighlight: metadata.xAxisYearHighlight,
        xAxisYearHighlightColor: metadata.xAxisYearHighlightColor,
        barWidth: metadata.barWidth,
        rotatedAxes: metadata.rotatedAxes,
        hideYAxisOnBarChart: metadata.hideYAxisOnBarChart,
        hideXAxisOnBarChart: metadata.hideXAxisOnBarChart,
        valueDisplayOnBarChart: metadata.valueDisplayOnBarChart,
        yAxisMaxValueMetric: this.getMaxAxisValue(metadata.metrics, metadata.yAxisMaxValueMetric),
        xAxisMaxValueMetric: this.getMaxAxisValue(metadata.metrics, metadata.xAxisMaxValueMetric),
      },
      pinDisable: this.getPinDisableOption(metadata, customPagesConfig),
    });
  }

  public getPinDisableOption(
    metadata: ExtendedPlaceholderMetadata<ExtendedChartMetadata>,
    customPagesConfig: CustomPagesConfig[]
  ): boolean {
    return (
      isEmpty(metadata.pin) ||
      metadata.pin === NON_DISPLAYABLE_PIN_TYPE ||
      this.getPinOptions<boolean>(metadata, customPagesConfig, 'pinDisable')
    );
  }

  public setChartPlaceholdersWidth(content?: ElementRef) {
    const chartsMetadata: ExtendedPlaceholderMetadata[] = this.getChartsPlaceholdersMetadata(content);
    // TODO: need to change !!!
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    chartsMetadata.forEach((item: ExtendedPlaceholderMetadata<ExtendedChartMetadata>) => {
      item.elementRef.style.height = `${item.height}px`;
    });
  }

  public getChartPlaceholdersElements(content?: ElementRef): NodeListOf<HTMLElement> {
    const doc = content ? content.nativeElement : document;

    return doc.querySelectorAll(PLACEHOLDER_SELECTOR);
  }

  public convertChartInserts(
    item: CustomPageInsert<ChartPlaceholder>,
    parentUiId: string,
    superParent = null,
    themeColors: ColorScheme[]
  ): ExtendedPlaceholderMetadata {
    const isChartPlaceholder = item.metadata.insertType === 'chart';
    const chartHeight = item.metadata.chartHeight;

    if (!isChartPlaceholder) {
      return;
    }

    const height = typeof chartHeight === 'number' ? chartHeight : Math.ceil(pixels(chartHeight));
    const defaultColors = item.metadata.defaultColors ? [...item.metadata.defaultColors.split(',')] : [];
    let colorScheme;

    if (item.metadata.metrics[0] && item.metadata.metrics[0].color) {
      colorScheme = item.metadata.metrics.map(metric => metric.color);
    } else {
      colorScheme = defaultColors || '';
    }

    const xAxisSourceStart = Number(item.metadata.xAxisSourceStart);
    const xAxisSourceEnd = Number(item.metadata.xAxisSourceEnd);
    const [chartType, chartInterpolate] = item.metadata.chartType.split('.');
    const type = this.validateValue(chartType as ChartType, C3JS_CHART_TYPES);

    return {
      body: '',
      id: item.metadata.id,
      parentUiId,
      superParent,
      type,
      metrics: item.metadata.metrics,
      'chart-background': this.validateValue(item.metadata.chartBackground, CHART_BACKGROUNDS),
      xAxis: this.validateValue(item.metadata.xAxisSource, X_AXIS_SOURCES),
      chartInterpolate,
      yAxisName: item.metadata.yAxisName?.trim(),
      height,
      pin: item.metadata.pinType,
      defaultPinValue: item.metadata.defaultPinValue,
      colorScheme: this.parseColors(colorScheme, themeColors, type),
      rendered: false,
      isChartPlaceholder: isChartPlaceholder,
      xAxisSourceStart: isNaN(xAxisSourceStart) ? null : xAxisSourceStart,
      xAxisSourceEnd: isNaN(xAxisSourceEnd) ? null : xAxisSourceEnd,
      pinTotalType: item.metadata.pinTotalType,
      optimalVerticalPosition: item.metadata.optimalVerticalPosition,
      pinTotalLabel: item.metadata.pinTotalLabel,
      pinTotalColor: item.metadata.pinTotalColor,
      chartFontSize: item.metadata.chartFontSize,
      chartFontSizeAxesLabels: item.metadata.chartFontSizeAxesLabels,
      chartFontSizeXAxesLabels: item.metadata.chartFontSizeXAxesLabels,
      chartFontSizeYAxesLabels: item.metadata.chartFontSizeYAxesLabels,
      chartFontSizeAxesNames: item.metadata.chartFontSizeAxesNames,
      fontSizeValueOnChart: item.metadata.fontSizeValueOnChart,
      chartFontSizeToolTipLabel: item.metadata.chartFontSizeToolTipLabel,
      chartFontSizeToolTipNumber: item.metadata.chartFontSizeToolTipNumber,
      chartFontSizeXAxisValuePin: item.metadata.chartFontSizeXAxisValuePin,
      chartTheme: item.metadata.chartTheme,
      chartName: item.metadata.chartName,
      isYAxisAffectedByLimits: item.metadata.isYAxisAffectedByLimits,
      xAxisSource: item.metadata.xAxisSource,
      xAxisMetric: item.metadata.xAxisMetric,
      xAxisName: item.metadata.xAxisName?.trim(),
      legendType: item.metadata.legendType,
      legendPosition: item.metadata.legendPosition,
      valueDisplay: item.metadata.valueDisplay,
      donutWidth: item.metadata.donutWidth,
      defaultColors: item.metadata.defaultColors,
      fontSizeOfChartLabels: item.metadata.fontSizeOfChartLabels,
      stackedMetric: item.metadata.stackedMetric,
      stackedGroup: item.metadata.stackedGroup,
      secondLineDescription: item.metadata.secondLineDescription,
      legendTitle: item.metadata.legendTitle,
      legendSubtitle: item.metadata.legendSubtitle,
      chartDataSource: item.metadata.chartDataSource,
      metricDataSource: item.metadata.metricDataSource,
      metricTitleForPieOrDonutChart: item.metadata.metricTitleForPieOrDonutChart,
      axes: item.metadata.axes,
      additionalYAxisName: item.metadata.additionalYAxisName?.trim(),
      hideAdditionalYAxis: item.metadata.hideAdditionalYAxis,
      xAxisYearHighlight: item.metadata.xAxisYearHighlight,
      xAxisYearHighlightColor: item.metadata.xAxisYearHighlightColor,
      barWidth: item.metadata.barWidth,
      rotatedAxes: item.metadata.rotatedAxes,
      hideYAxisOnBarChart: item.metadata.hideYAxisOnBarChart,
      hideXAxisOnBarChart: item.metadata.hideXAxisOnBarChart,
      valueDisplayOnBarChart: item.metadata.valueDisplayOnBarChart,
      yAxisMaxValueMetric: item.metadata.yAxisMaxValueMetric,
      xAxisMaxValueMetric: item.metadata.xAxisMaxValueMetric,
    };
  }

  public getPlaceholdersMetadata(contentList: NodeListOf<HTMLElement>): ExtendedPlaceholderMetadata[] {
    const result: ExtendedPlaceholderMetadata[] = [];
    contentList.forEach((elem: HTMLElement) => {
      const type = this.getInnerText<ChartType>('.type', elem);
      const metricsString = this.getInnerText<string>('.metrics', elem);
      const xAxis = <XAxisSourceType>this.getInnerText('.xAxis', elem);
      const chartBackground = this.getInnerText<ChartBackgroundType>('.chart-background', elem);
      const yAxisName = this.getInnerText<string>('.yAxisName', elem);
      const height = this.getPlaceholdersHeight(elem);
      const metrics: ChartMetric[] = this.parseMetrics(metricsString);
      result.push({
        body: '',
        type: this.validateValue(type, C3JS_CHART_TYPES),
        metrics,
        'chart-background': this.validateValue(chartBackground, CHART_BACKGROUNDS),
        xAxis: this.validateValue(xAxis, X_AXIS_SOURCES),
        yAxisName,
        height,
        elementRef: elem,
        rendered: false,
        isChartPlaceholder: true,
      });
    });

    return result;
  }

  private parseColors(colorScheme: string | string[] = [], themeColors: ColorScheme[], type: ChartType): string[] {
    const theme = themeColors?.map(color => color.color);

    if (!isArray(colorScheme)) {
      colorScheme = colorScheme.split(',');
    }

    let colors: string[] = [...colorScheme];
    colors = colors.concat(theme?.filter(color => !colors.includes(color)));

    return type === ChartTypes.pie || type === ChartTypes.donut ? colors : colors.slice(0, 5);
  }

  private getPinHeight(height: number): number {
    const heightNumber = Number(height);

    return isNaN(heightNumber) || heightNumber <= CHART_HEIGHT
      ? PIN_LINE_HEIGHT
      : PIN_LINE_HEIGHT + (heightNumber - CHART_HEIGHT);
  }

  private getXAxisValue(xAxis: XAxisSourceType | undefined): XAxisSourceType {
    return xAxis || this.xAxisSource || X_AXIS_SOURCE_DEFAULT;
  }

  private getCurrentLimits(xAxisSource: XAxisSourceType, dataLimits: DataLimitsSource): DataLimits {
    const limits = cloneDeep(dataLimits);

    if (
      xAxisSource !== limits.xAxisSource ||
      (xAxisSource === 'policy_year' && dataLimits.maxAgeDisplay === limits.maxAgeDisplay)
    ) {
      forOwn(limits, (value, key) => {
        if (!isNil(value) && key !== 'xAxisSource') {
          const newValue = this.chartDataService.agePolicyYearConvert(value, xAxisSource, cloneDeep(this.plans));
          isNil(newValue) || (limits[key] = newValue);
        }
      });
    }

    return limits;
  }

  private validateValue<T>(value: T, allowedValues: T[]): T {
    return allowedValues.includes(value) ? value : undefined;
  }

  private getPlaceholdersHeight(elem: HTMLElement): number {
    const height = Number(<string>this.getInnerText('.height', elem)) || CHART_HEIGHT;

    return height;
  }

  private parseMetrics(metricsString: string): ChartMetric[] {
    const list: string[] = metricsString && this.sanitizeList(metricsString.split(','));
    const result: ChartMetric[] = [];
    list &&
      list.forEach(item => {
        const [productIndex, metricKey] = item.replace(/(\[)|(\])/g, '').split('.');
        result.push({
          productIndex: productIndex,
          metricKey,
        });
      });

    return result.splice(0, 4);
  }

  private sanitizeValue<T extends string>(textContent: T): T {
    return <T>trim(textContent.replace(/%/g, ''));
  }

  private sanitizeList(list: string[]) {
    remove(list, item => !item);

    return list.map(trim);
  }

  private getInnerText<T extends string>(selector: string, elem: HTMLElement): T {
    const element: HTMLElement = elem.querySelector(selector);
    const textContent = this.replaceTips(element ? element.textContent : '');

    return this.sanitizeValue<T>(<T>textContent);
  }

  private replaceTips(textContent: string): string {
    return textContent.replace(/(&lt;--)(.*)(--&gt;)/gi, '');
  }

  private getDefaultPinValue(metadata: ExtendedPlaceholderMetadata<ExtendedChartMetadata>): number {
    if (!metadata || !this.plans || typeof metadata.metrics === 'string') {
      return PIN_VALUE_MARGIN;
    }

    const first = [];
    metadata.metrics.forEach(metric => {
      const metricData = get(
        this.plans,
        `${metric.productIndex}.${CALCULATED_DATA_TARGET.data}.${this.getXAxisValue(metadata.xAxis)}[0]`
      );
      isNil(metricData) || first.push(Number(metricData));
    });
    const start: number = min(first);
    let lastXValue;

    if (metadata.data[0]?.values?.length) {
      lastXValue = metadata.data[0].values[metadata.data[0].values.length - 1].x;
    }

    const defaultPinValueOnChart = metadata.defaultPinValue?.trim() !== '' ? Number(metadata.defaultPinValue) : null;

    if (isDefined(defaultPinValueOnChart) && defaultPinValueOnChart >= start && defaultPinValueOnChart <= lastXValue) {
      return defaultPinValueOnChart;
    } else {
      return PIN_VALUE_MARGIN + start;
    }
  }

  private getPinOptions<T>(
    metadata: ExtendedPlaceholderMetadata<ExtendedChartMetadata>,
    customPagesConfig: CustomPagesConfig[],
    fieldName: string
  ): T {
    const chartConfig = this.getChartConfig(metadata, customPagesConfig);

    return get(chartConfig, fieldName);
  }

  private getChartConfig(
    metadata: ExtendedPlaceholderMetadata<ExtendedChartMetadata>,
    customPagesConfig: CustomPagesConfig[]
  ) {
    const mainConf =
      metadata.superParent !== metadata.parentUiId && find(customPagesConfig, { uiId: metadata.superParent });
    const configs = metadata.superParent && mainConf ? mainConf.pages : customPagesConfig;
    const config = <CustomPagesItemConfig>find(configs, { uiId: metadata.parentUiId });

    if (!config) {
      return;
    }

    return find(config.charts, { uiId: metadata.id });
  }

  private getMaxAxisValue(metrics: ChartMetric[] | string, metricKey: string): string | number {
    if (!this.plans || typeof metrics === 'string' || !metrics?.length) {
      return;
    }

    const values = [];

    metrics.forEach(metric => {
      const meta = get(this.plans, `${metric.productIndex}.${CALCULATED_DATA_TARGET.metadata}.${metricKey}`);

      if (meta) {
        values.push(Number(meta));
      }
    });

    return max(values);
  }
}
