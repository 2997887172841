@if (showMessage) {
  <div class="browser-message-container">
    <img
      class="alert-sign"
      src="../../../../../assets/images/alert_sign.svg"
      alt="alert"
      />
      <span>
        You are using an unsupported browser. Some features may not work properly.
        Please use one of the following browsers to ensure the best experience:
      </span>
      <a href="https://www.google.com/chrome/" target="_blank">
        <img
          class="browser-sign"
          src="../../../../../assets/images/chrome_sign.svg"
          alt="alert"
          />
          Chrome,
        </a>
        <a href="https://www.mozilla.org/en-US/firefox/" target="_blank">
          <img
            class="browser-sign"
            src="../../../../../assets/images/firefox_sign.svg"
            alt="alert"
            />
            Firefox,
          </a>
          <a
            href="https://www.microsoft.com/en-us/windows/microsoft-edge"
            target="_blank"
            >
            <img
              class="browser-sign"
              src="../../../../../assets/images/edge_sign.svg"
              alt="alert"
              />
              Edge
            </a>
          </div>
        }
