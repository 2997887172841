import { Component, OnDestroy, OnInit } from '@angular/core';

import { keyBy, merge, orderBy, some, values } from 'lodash-es';
import { DragulaService } from 'ng2-dragula';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ModalConfig, ModalRef } from '@assurance/bootstrap';

import { DragulaDropModel, IFilterBlockDataPages } from '@shared/models';

@UntilDestroy()
@Component({
  selector: 'ep-dependent-pages-modal',
  templateUrl: './dependent-pages-modal.component.html',
  styleUrls: ['./dependent-pages-modal.component.scss'],
})
export class DependentPagesModalComponent implements OnInit, OnDestroy {
  public dependentPages: IFilterBlockDataPages[];
  public pagesDragModel = { bag: 'pages-bag', data: [] };

  constructor(public modal: ModalRef, public config: ModalConfig, private dragulaService: DragulaService) {}

  get selectedPages() {
    return orderBy(
      this.dependentPages.filter(page => page.selected),
      ['order']
    );
  }

  ngOnInit(): void {
    this.dependentPages = this.config.data.dependentPages;
    this.initDragBlocks();
  }

  ngOnDestroy(): void {
    this.dragulaService.destroy(this.pagesDragModel.bag);
  }

  onCancel() {
    this.modal.close({ submitted: false });
  }

  onAction(event: IFilterBlockDataPages, flag: boolean): void {
    event.selected = flag;
    event.order = flag ? this.selectedPages.length : null;
    this.setDragData();
  }

  // searchData(event: { search: string }) {
  //TODO: need to add interface
  searchData(event: any): void {
    if (!event.search) {
      this.setDependentPages(this.config.data.dependentPages);

      return;
    }

    this.dependentPages = this.config.data.dependentPages.filter(data => {
      const label = data.label.toLowerCase();

      return data.selected || label.includes(event.search.toLowerCase());
    });
  }

  hasSelectedPage(): boolean {
    return some(this.dependentPages, { selected: true });
  }

  apply(): void {
    this.modal.close({
      submitted: true,
      selectedPages: this.dependentPages,
    });
  }

  setDependentPages(pages): void {
    const merged = merge(keyBy(this.dependentPages, 'id'), keyBy(pages, 'id'));
    this.dependentPages = values(merged);
  }

  private initDragBlocks(): void {
    this.setDragData();
    this.dragulaService
      .dropModel()
      .pipe(untilDestroyed(this))
      .subscribe((dropModel: DragulaDropModel<IFilterBlockDataPages>) => {
        this.pagesDragModel.data = dropModel.sourceModel.map((data, index) => {
          return {
            ...data,
            order: index,
          };
        });
        this.setDependentPages(this.pagesDragModel.data);
      });
  }

  private setDragData(): void {
    this.pagesDragModel.data = this.selectedPages;
  }
}
