<section class="controls-section">
  <div class="control-container">
    <label class="label">Tab Name</label>

    <se-form-input
      [formControl]="tabNameControl"
      placeholder="Untitled tab"
    ></se-form-input>

    @if (
      tabNameControl.invalid &&
      (tabNameControl.dirty || tabNameControl.touched)
      ) {
      @if (tabNameControl.errors?.required) {
        <span class="error-message">
          Value is required
        </span>
      }
      @if (tabNameControl.errors?.maxlength) {
        <span class="error-message">
          Please enter up to 50 characters
        </span>
      }
      @if (tabNameControl.errors?.tabsQuantity) {
        <span class="error-message">
          Only {{ maxTabsQuantity }} or less tabs are allowed
        </span>
      }
      @if (tabNameControl.errors?.uniqueTabTitle) {
        <span class="error-message">
          The tab name is not unique
        </span>
      }
    }
  </div>
</section>

<footer class="btn-block">
  <se-button class="secondary" (click)="cancel()">Cancel</se-button>

  @if (editMode) {
    <se-button
      [disabled]="!tabNameControl?.valid"
      (click)="editTab()"
      >
      Confirm
    </se-button>
  }

  @if (createMode) {
    <se-button
      [disabled]="!tabNameControl?.valid"
      (click)="createTab()"
      >
      Add Tab
    </se-button>
  }
</footer>
