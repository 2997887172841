@if (!isLoginRoute) {
  <nav
    [ngClass]="{ 'collapsed-left': collapsedStyleEditor }"
    class="side-bar style-editor-bar col-4 col-lg-2 print-elem-hide"
    >
    <tabset>
      <tab
        heading="Themes"
        [active]="mode !== 'edit'"
        class="themes-tab"
        (selectTab)="mode = 'create'"
        [class.loader]="showSpinner"
        >
        <accordion [closeOthers]="false" class="all-themes-accordion">
          <accordion-group
            class="style-editor-article"
            [isOpen]="true"
            [isDisabled]="false"
            >
            <label class="a-app-themes-search-label" for="a-app-themes-search">
              <input
                type="text"
                id="a-app-themes-search"
                class="a-app-theme-title"
                [(ngModel)]="searchQuery"
                (ngModelChange)="filter()"
                placeholder="Search theme..."
                />
                @if (!searchQuery) {
                  <span
                    class="fa fa-search text-muted"
                    aria-hidden="true"
                  ></span>
                }
                @if (searchQuery) {
                  <span
                    class="fa fa-times text-muted"
                    aria-hidden="true"
                    (click)="filter('')"
                  ></span>
                }
              </label>
              @if (themes?.length) {
                <ul class="list-unstyled style-editor-block">
                  @for (theme of themes; track theme) {
                    <li class="style-editor-block-item">
                      <div class="theme-wrapper">
                        <a
                          href="#"
                          (click)="applyTheme(theme.id, $event)"
                          class="theme-logo-preview-link"
                          [attr.title]="theme.title"
                          >
                          <img
                            [ensightImageSetter]="{ value: theme.logo }"
                            class="theme-logo-preview"
                            />
                            <span>{{ theme.title }}</span>
                          </a>
                          <div class="action-icons">
                            @if (theme.id === selectedThemeId) {
                              <span
                                class="themes-service-icons float-left theme-apply-id"
                                >
                                <i class="fa fa-check fa-lg" aria-hidden="true"></i>
                              </span>
                            }
                            @if (!theme.default) {
                              <a
                                tooltip="Delete theme"
                                placement="left"
                                (click)="deleteTheme(theme.id)"
                                class="themes-service-icons float-right text-muted"
                                >
                                <i class="fa fa-trash-o fa-lg" aria-hidden="true"></i>
                              </a>
                            }
                            @if (!theme.default) {
                              <a
                                tooltip="Edit theme"
                                placement="left"
                                (click)="editTheme(theme.id)"
                                class="themes-service-icons float-right text-muted"
                                >
                                <i
                                  class="fa fa-pencil-square-o fa-lg"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            }
                            <a
                              tooltip="Export theme"
                              placement="left"
                              (click)="exportTheme(theme.id)"
                              class="themes-service-icons float-right text-muted"
                              >
                              <i class="fa fa-upload fa-lg" aria-hidden="true"></i>
                            </a>
                          </div>
                        </div>
                      </li>
                    }
                  </ul>
                }
              </accordion-group>
              @if (!themes?.length) {
                <h3 class="text-center text-muted">
                  No created themes
                </h3>
              }
              <section class="styles-service-btns-wrapper text-center">
                <label
                  class="text-muted text-uppercase import-style-btn"
                  for="a-app-export-theme-uploader"
                  >
                  <i class="fa fa-download fa-lg" aria-hidden="true"></i>
                  import
                  <input
                    id="a-app-export-theme-uploader"
                    class="hide"
                    type="file"
                    accept=".json"
                    (change)="importNewTheme($event)"
                    />
                  </label>
                </section>
              </accordion>
            </tab>
            @if (styleScheme) {
              <tab
                [heading]="styleScheme.id ? 'Edit Theme' : 'Create New Theme'"
                [active]="mode === 'edit'"
                class="new-theme-tab"
                (selectTab)="mode = 'edit'"
                [class.loader]="showSpinner"
                >
                <section>
                  <article class="style-editor-article">
                    <accordion [closeOthers]="false">
                      <accordion-group [isOpen]="true" [isDisabled]="true">
                        <div accordion-heading>Theme title</div>
                        <label
                          class="a-app-theme-title-label"
                          for="a-app-theme-title"
                          [ngClass]="{ invalid: !styleScheme.title }"
                          >
                          <input
                            type="text"
                            id="a-app-theme-title"
                            class="a-app-theme-title"
                            [(ngModel)]="styleScheme.title"
                            placeholder="Enter theme title"
                            />
                            <span
                              class="fa fa-pencil fa-lg text-muted"
                              aria-hidden="true"
                            ></span>
                          </label>
                        </accordion-group>
                      </accordion>
                    </article>
                    <article class="style-editor-article">
                      <accordion [closeOthers]="true">
                        @for (styleBlock of styleScheme.sections; track styleBlock; let first = $first) {
                          <div
                            >
                            <accordion-group [isOpen]="first" [isDisabled]="false">
                              <div accordion-heading>
                                {{ styleBlock.title }}
                                @if (
                                  styleBlock.sectionKey === 'other_elements_color_scheme'
                                  ) {
                                  <i
                                    class="fa fa-external-link fa-lg text-muted redirect-icon"
                                    aria-hidden="true"
                                    tooltip="Show element on one page"
                                    (click)="onBlockClick($event, styleBlock)"
                                  ></i>
                                }
                                <i
                    class="
                      fa fa-lg fa-angle-right
                      float-right
                      chevron-icon-right
                    "
                                  aria-hidden="true"
                                ></i>
                                <i
                    class="
                      fa fa-lg fa-angle-down
                      float-right
                      chevron-icon-bottom
                    "
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <ul class="list-unstyled style-editor-block">
                                @for (item of styleBlock.items; track item) {
                                  <li
                                    class="style-editor-block-item"
                                    [ngClass]="{ blocked: item.valueType === 'text' }"
                                    >
                                    <h6 class="option">
                                      {{ item.title }}
                                      <a
                                        class="btn btn-link highlight-crosshairs"
                                        (click)="highlightElements(item.className)"
                                        tooltipPopupDelay="3000"
                                        tooltip="Show element on page"
                                        >
                                        <i
                                          class="fa fa-crosshairs fa-lg"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                      @if (item.changed) {
                                        <a
                                          class="btn btn-link restore-icon"
                                          (click)="restoreItem(item)"
                                          tooltipPopupDelay="3000"
                                          tooltip="Restore changes"
                                          >
                                          <i class="fa fa-repeat fa-lg" aria-hidden="true"></i>
                                        </a>
                                      }
                                      @if (item.valueType === 'color' || item.valueType === 'line' || item.valueType === 'hover-color') {
                                        <span
                                          [colorPicker]="nameOrRgb2hex(item.value)"
                                          [style.background]="nameOrRgb2hex(item.value)"
                                          (colorPickerChange)="changeColor(item, $event)"
                                          [cpPosition]="'left'"
                                          [cpPositionOffset]="'50%'"
                                          [cpPositionRelativeToArrow]="true"
                                          [cpCancelButton]="true"
                                          [cpCancelButtonClass]="'a-app-cp-cancel-button-class'"
                                          [cpCancelButtonText]="'&#x2715;'"
                                          [cpAlphaChannel]="item.className !== 'a-app-main-container-background-color' ? hex6 : 'disabled'"
                                          class="style-editor-color-picker float-right"
                                        ></span>
                                      }
                                      @if (item.valueType === 'image') {
                                        <div
                                          class="float-right"
                                          >
                                          <label
                                            [attr.for]="item.className"
                          class="
                            ensight-icon-out
                            text-muted
                            a-app-logo-uploader-label
                          "
                                          ></label>
                                          <input
                                            type="file"
                                            class="logo-uploader-input"
                                            [attr.id]="item.className"
                                            (change)="fileChange(item, $event)"
                                            />
                                          </div>
                                        }
                                        @if (item.valueType === 'text') {
                                          <label
                                            for="a-app-welcome-text"
                                            class="text-muted a-app-welcome-text float-right"
                                            >
                                            <textarea
                                              id="a-app-welcome-text"
                                              type="text"
                                              rows="10"
                                              (input)="onInputText($event.target, item)"
                                              >
                                              {{ item.value }}</textarea
                                              >
                                            </label>
                                          }
                                        </h6>
                                      </li>
                                    }
                                  </ul>
                                </accordion-group>
                              </div>
                            }
                          </accordion>
                        </article>
                        <section class="styles-service-btns-wrapper text-center">
                          <button
            class="
              aapp-btn aapp-btn-default
              btn btn-secondary btn-lg
              text-uppercase
            "
                            type="button"
                            (click)="restoreStyles()"
                            >
                            restore
                          </button>
                          @if (!styleScheme.id) {
                            <button
                              class="aapp-btn btn btn-primary btn-lg text-uppercase"
                              type="button"
                              (click)="saveStyle()"
                              >
                              save
                            </button>
                          }
                          @if (styleScheme.id) {
                            <button
                              class="aapp-btn btn btn-primary btn-lg text-uppercase"
                              type="button"
                              (click)="updateStyle()"
                              >
                              update
                            </button>
                          }
                        </section>
                      </section>
                    </tab>
                  }
                </tabset>
                <div class="collapse-btn" (click)="collapseStyleEditor()">
                  <a>
                    <span
                      class="fa fa-chevron-left"
                      [ngClass]="{ 'fa-chevron-left': collapsedStyleEditor, 'fa-chevron-right': !collapsedStyleEditor  }"
                      aria-hidden="true"
                    ></span>
                  </a>
                </div>
              </nav>
            }

            <div
              bsModal
              #childModal="bs-modal"
              class="modal aapp-modal fade theme-remove-modal"
              tabindex="-1"
              role="dialog"
              aria-hidden="true"
              >
              <div class="modal-container modal-md a-app-confirm-info-modal-color">
                <div class="modal-content">
                  <div class="modal-header aapp-modal-header">
                    <h3 class="modal-title">Confirmation</h3>
                    <button
                      type="button"
                      class="close"
                      aria-label="Close"
                      (click)="hideChildModal()"
                      >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="text-center">
                      This theme cannot be deleted, because some agencies already use it.
                      <i class="agencies-block" [innerHTML]="modalInfo"></i>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <div class="mx-auto">
                      <button
                        type="button"
            class="
              aapp-btn
              a-app-confirm-info-modal-confirm-button-color
              btn btn-primary btn-lg
              text-uppercase
            "
                        (click)="childModal.hide()"
                        >
                        <span>Ok</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
