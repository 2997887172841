<section
  class="content a-app-main-container-background-color"
  [ngClass]="{ loader: loading, hidden: !loading }"
></section>

<ensight-custom-page-header
  [title]="customPageInfo?.name"
  (onClose)="leavePage()"
></ensight-custom-page-header>

<div class="container-search">
  <se-form-input
    [type]="'search'"
    [formControl]="searchControl"
    class="custom-page-history-records-search"
    [placeholder]="'Search Title, Page Name, Modified Fields and Author'"
  ></se-form-input>
</div>

@if (!loading && !loadingRecords && records.length) {
  <div class="container-table">
    <ep-table
      [data]="records"
      [tableHeaderColumn]="tableHeaderColumn"
      [tableColumn]="tableColumn"
      [orderByParams]="currentOrderByParams"
      (sortBy)="setOrderByValue($event)"
      [trackByKey]="'pageName'"
      >
      <ng-container epCustomTableColumn columnName="action">
        <ng-template let-index="index">
          <th class="cell body-cell" [ngStyle]="{ width: '82px' }">
            <ac-button class="secondary bnt-details" size="sm">
              Details
            </ac-button>
          </th>
        </ng-template>
      </ng-container>
      <ng-container epCustomTableColumn columnName="menu">
        <ng-template let-index="index">
          <th class="cell body-cell" [ngStyle]="{ width: '50px' }">
            <div class="container-menu-custom-page">
              <button
                class="btn-menu"
                epClickOutside
                #menuBtn
                [trigger]="['btn-menu-item', 'btn-menu']"
                (click)="toggleMenu(index)"
                (close)="clickOutside()"
              ></button>
              @if (isMenuOpen && currentRecordIndex === index) {
                <div
                  class="menu"
                  epDynamicPositionOfMenu
                  [menuBtn]="menuBtn"
                  >
                  <button
                    type="button"
                    class="btn-menu-item"
                    (click)="download(index)"
                    >
                    Download list of changes
                  </button>
                  <button
                    type="button"
                    class="btn-menu-item"
                    (click)="revert(index)"
                  *accessControl="{
                    user: [Permissions.revert_custom_page_changes]
                  }"
                    >
                    Revert to this snapshot
                  </button>
                  <button
                    type="button"
                    class="btn-menu-item"
                    (click)="exportRevision(index)"
                  *accessControl="{
                    user: [Permissions.export_custom_page]
                  }"
                    >
                    Export Revision
                  </button>
                </div>
              }
            </div>
          </th>
        </ng-template>
      </ng-container>
    </ep-table>
  </div>
  <div class="container-pagination">
    <se-pager
      [options]="paginationOptions"
      [pagesSliceCount]="5"
      (onPageChange)="setPaginationParams($event)"
    ></se-pager>
  </div>
}

@if (loadingRecords) {
  <div class="container-spinner-message">
    <se-circle-loader></se-circle-loader>
  </div>
}

@if (!loadingRecords && !records.length) {
  <se-notification
    type="no-entities-found"
    [title]="noDataMessage.title"
    [message]="noDataMessage.message"
  ></se-notification>
}
