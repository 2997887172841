@if (!isAutocomplete) {
  <div class="invited-user-list">
    @for (user of users; track user) {
      <div
        class="invited-user-item d-flex flex-row justify-content-between pb-3"
        >
        <div class="d-flex flex-row align-items-center">
          <div class="user-avatar d-flex">
            @if (user.avatarUrl) {
              <img
                [src]="user.avatarUrl"
                alt="User Avatar"
                />
            }
            @if (!user.avatarUrl) {
              <div
                class="d-flex justify-content-center align-items-center"
                >
                {{ user.avatarInitial }}
              </div>
            }
          </div>
          <div class="user-info">
            <div class="user-name">{{ user.firstName }} {{ user.lastName }}</div>
            <span class="user-email">{{ user.email || user.receiverEmail }}</span>
            @if (user.isInvalidEmail) {
              <img
                class="pl-2"
                src="../../../../../assets/icons/warning-icon.svg"
                alt="Warning invalid email Icon"
                title="Invalid email address"
                />
            }
          </div>
        </div>
        <div class="action-container d-flex align-items-center">
          @if (!user.isInvalidEmail && user.roleCategory?.length) {
            <div
              class="role-category-selector"
              >
              <select
                [ngModel]="selectedRoleIds[user.email]"
                [disabled]="user.isRoleDisabled"
                class="role-dropdown border-0"
                (ngModelChange)="onRoleChange(user, $event)"
                >
                @for (category of user.roleCategory; track category) {
                  <option
                    [ngValue]="category.name"
                    >
                    {{ category.name }}
                  </option>
                }
              </select>
              @if (user.isRoleDisabled) {
                <span
                  class="tooltiptext d-flex align-items-center justify-content-center position-absolute text-center"
                  >
                  To change the role of an existing user please contact Customer
                  Support.
                </span>
              }
            </div>
          }
          @if (showRemoveButton) {
            <se-button class="delete" (click)="onRemoveUser(user.email)">
              Remove
            </se-button>
          } @else {
            @if (showInviteInfo) {
              <div class="d-flex flex-column align-items-end">
                <span>
                  Invited on:
                  <strong>{{ user.invitedOn | date: 'MM/dd/yyyy h:mm a' }}</strong>
                </span>
                <span>
                  Opened on:
                  <strong>
                    {{ (user.openedOn | date: 'MM/dd/yyyy h:mm a') || '-' }}
                  </strong>
                </span>
              </div>
            }
          }
        </div>
      </div>
    }
  </div>
} @else {
  <ul
    class="autocomplete-list"
    [style.top.px]="listPositionTop"
    [style.left.px]="listPositionLeft"
    [style.width.px]="listWidth"
    >
    @for (user of users; track user; let i = $index) {
      <li
        role="option"
        [attr.aria-selected]="i === selectedIndex"
        >
        <div
          class="user-item d-flex flex-row align-items-center"
        [ngClass]="{
          'user-item-extended': user.firstName,
          selected: i === selectedIndex
        }"
          (click)="onSelectUser(user)"
          >
          <div class="user-avatar d-flex">
            @if (user.avatarUrl) {
              <img
                [src]="user.avatarUrl"
                alt="User Avatar"
                />
            }
            @if (!user.avatarUrl) {
              <div
                class="d-flex justify-content-center align-items-center"
                >
                {{ user.avatarInitial }}
              </div>
            }
          </div>
          <div class="user-info">
            @if (user.firstName) {
              <div class="user-name">
                {{ user.firstName }} {{ user.lastName }}
              </div>
            }
            <div class="user-email">{{ user.email }}</div>
          </div>
        </div>
      </li>
    }
  </ul>
}

