<div class="choose-presentation-area d-flex justify-content-between align-items-center p-3">
  <div>
    @if (getPlaceholders$ | async; as placeholders) {
      <se-button class="secondary bg-white"
        [disabled]="!selectedPresentation || !placeholders.length || compilationPreviewInProgress"
        (click)="onCompileInserts()">
        Compile All
      </se-button>
    }
    @if (compilationPreviewInProgress) {
      <span class="px-4">
        Compilation is in progress...
      </span>
    }
  </div>
  <div>
    <se-button class="secondary bg-white" (click)="onChoosePresentationModal()">
      Choose Presentation
    </se-button>
    <span class="presentation-name px-4">
      {{ selectedPresentation?.name || 'No Presentation Selected' }}
    </span>
    @if (selectedPresentation) {
      <button class="btn-clear border-0" (click)="onClearPresentation()">
        Clear
        <img src="/assets/icons/close-icon.svg" alt="Close icon" />
      </button>
    }
  </div>
</div>
