import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash-es';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { ModalConfig, ModalRef } from '@assurance/bootstrap';
import { filter, first, debounceTime } from 'rxjs/operators';
import { EventObj } from '@tinymce/tinymce-angular/editor/Events';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  globalCoverLetterUpdateSuccess,
  globalCoverLetterUpdatePending,
  globalCoverLetterUpdateFailure,
  coverSheetLandscape,
} from '@ngrx-app/global.actions';
import { getCoverLetter, getCoverLetterLoading, getCoverLetterLoaded } from '@ngrx-app/global.selectors';
import { getCoverLetterTemplatesFiltered, getCoverLetterTemplatesLoading } from './cover-sheet-modal.selectors';
import { AppState } from '../../../../reducers';

import { APIService, GAService, Global } from '@shared/services';
import { SetupService } from '../../setup/setup.service';
import { CoverSheetModalService } from './cover-sheet-modal.service';
import { coverSheetInputsLength, TEMPLATES_IDS } from './cover-sheet-modal.constants';
import { PresentationInfo } from '../../cover-letter/cover-lettermodels';
import { coversheetPageName } from '@shared/components/assurance-navbar/navbar.constants';
import { CoverLetter, CoverLetterTemplate } from '@shared/models';
import { FormFields } from './cover-sheet-modal.enum';
import { CoverSheetInputChipsComponent } from './cover-sheet-input-chips/cover-sheet-input-chips.component';

@UntilDestroy()
@Component({
  providers: [SetupService],
  selector: 'ensight-cover-sheet-modal',
  styleUrls: ['cover-sheet-modal.component.scss', '../shared/sidebar-modals.scss'],
  templateUrl: 'cover-sheet-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverSheetModalComponent implements OnInit, OnDestroy {
  maxLength = coverSheetInputsLength;
  coverSheet: CoverLetter;
  coverForm: UntypedFormGroup;
  activeId: string;
  isCoverSheetLoading$: Observable<boolean>;
  isCoverSheetLoaded$: Observable<boolean>;
  isCoverSheetTemplatesLoading$: Observable<boolean>;
  templates: CoverLetterTemplate[];
  presentationInfo: PresentationInfo;
  Fields = FormFields;

  @ViewChildren(CoverSheetInputChipsComponent) private fields: QueryList<CoverSheetInputChipsComponent>;

  private editorInstance: any;

  constructor(
    private store: Store<AppState>,
    private modal: ModalRef,
    private config: ModalConfig,
    private apiService: APIService,
    private fb: UntypedFormBuilder,
    private global: Global,
    private setupService: SetupService,
    private coverSheetModalService: CoverSheetModalService,
    private cdr: ChangeDetectorRef,
    private gaService: GAService
  ) {}

  ngOnInit(): void {
    this.initCoverForm();
    this.watchForFormControlTexBody();
    this.presentationInfo = this.global.getPresentationInfo;
    this.coverSheetModalService.getCoverSheetTemplates().pipe(untilDestroyed(this)).subscribe();
    this.isCoverSheetLoading$ = this.store.select(getCoverLetterLoading);
    this.isCoverSheetLoaded$ = this.store.select(getCoverLetterLoaded);
    this.isCoverSheetTemplatesLoading$ = this.store.select(getCoverLetterTemplatesLoading);
    this.watchForCoverSheet();
  }

  ngOnDestroy(): void {
    if (this.editorInstance) {
      this.editorInstance.destroy();
    }

    //TODO: need to destroy component correct https://assuranceapp.atlassian.net/browse/DAT-7917
    this.fields.forEach(component => component.ngOnDestroy());
  }

  onModalClose(event: EventObj<KeyboardEvent>): void {
    const key = event.event.key || event.event.keyCode;

    if (key === 'Escape' || key === 'Esc' || key === 27) {
      this.modal.close();
    }
  }

  setEditorInstance(event): void {
    this.editorInstance = event;
  }

  chooseTemplate(id: string): void {
    this.activeId = id;
  }

  //TODO: need to refact. This method use in template
  isFormEnabled(): boolean {
    return (
      this.activeId === TEMPLATES_IDS.allianz ||
      this.activeId === TEMPLATES_IDS.allianzretirement ||
      (this.coverForm &&
        ((this.activeId === TEMPLATES_IDS.lowcolor && this.coverForm.valid) ||
          (this.activeId === TEMPLATES_IDS.text && this.coverForm.value.textBody)))
    );
  }

  //TODO: need to refact. Subscription inside subscription, it can be simplified
  saveCoverSheet(): void {
    this.store.dispatch(globalCoverLetterUpdatePending());
    const apiRequestType = this.hasPresetData() ? 'putCoverLetter' : 'postCoverLetter';
    const formValues = { ...this.coverForm.value, uiId: this.activeId };

    this.apiService[apiRequestType](this.global.getActivePresentationId, formValues).subscribe(
      (response: any) => {
        this.setupService.getUpdatedCarrierPlans().subscribe(() => {
          this.updateCoverSheetParams();
          this.store.dispatch(globalCoverLetterUpdateSuccess({ payload: response.data }));
        });

        if (!this.config?.data?.isEditing) {
          this.gaService.sendAddPageEvent(coversheetPageName);
        }

        this.modal.close();
      },
      (error: any) => {
        this.store.dispatch(globalCoverLetterUpdateFailure({ error }));
      }
    );
  }

  closeModal(): void {
    this.modal.close();
  }

  private watchForCoverSheet(): void {
    combineLatest(
      this.store.select(getCoverLetterTemplatesFiltered).pipe(filter(templates => templates.length > 0)),
      this.store.select(getCoverLetter)
    )
      .pipe(first(), untilDestroyed(this))
      .subscribe(([templates, coverSheet]) => {
        this.templates = templates;
        this.coverSheet = coverSheet;
        this.activeId = this.coverSheet ? this.coverSheet.uiId : templates[0].uiId;
        this.initCoverSheetData();
      });
  }

  private initCoverForm(): void {
    this.coverForm = this.fb.group({
      [FormFields.title]: ['', [Validators.required]],
      [FormFields.body]: ['', [Validators.required]],
      [FormFields.signature]: [''],
      [FormFields.footer]: [''],
      [FormFields.textBody]: '',
    });
  }

  private watchForFormControlTexBody(): void {
    this.coverForm.controls.textBody.valueChanges
      .pipe(debounceTime(100), untilDestroyed(this))
      .subscribe(() => this.cdr.markForCheck());
  }

  private initCoverSheetData(): void {
    if (this.hasPresetData()) {
      this.coverForm.patchValue({ ...this.coverSheet });
    }
  }

  private hasPresetData(): boolean {
    return !_.isEmpty(this.coverSheet);
  }

  private updateCoverSheetParams(): void {
    this.store.dispatch(
      coverSheetLandscape({
        payload: this.activeId !== TEMPLATES_IDS.allianz && this.activeId !== TEMPLATES_IDS.allianzretirement,
      })
    );
  }
}
