@if (type === 'simple') {
  <span [class.disabled]="disable" class="toggle-group toggle-container">
    @for (value of values; track value) {
      <label>
        @if (value.checked) {
          <span [class]="value.checkedClass" aria-hidden="true"></span>
        }
        @if (!value.checked) {
          <span [class]="value.uncheckedClass" aria-hidden="true"
          ></span>
        }
        <input
          id="simple-toggle"
          [value]="value.key"
          type="radio"
          #checkElem
          [name]="name"
          [checked]="value.checked"
          (click)="toggleElement($event)"
          />
          @if (value.label) {
            <a class="label-value btn btn-link pp-action-button-link">
              {{ value.label }}
            </a>
          }
        </label>
      }
    </span>
  }
  @if (type === 'replace') {
    <span class="toggle-group">
      <se-toggler
        class="epv-switch"
        [class.loading]="loading"
        [readonly]="disable || readonly"
        #checkElem
        [checked]="checked"
        (onChange)="switchElement($event)"
        (click)="readonlyClick()"
      ></se-toggler>
    </span>
  }
