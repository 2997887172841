import { Injectable } from '@angular/core';
import Clipboard from 'clipboard';

export { ClipboardCustom } from './plans-grid.model';

@Injectable()
export class PlansGridService {
  private fakeElem: any;
  private fakeHandler: any;
  private fakeHandlerCallback: any;

  public getClipboardListener(selector: string, innerHtml: string): Clipboard {
    return new Clipboard(selector, {
      target: () => {
        const removeFake = () => {
          if (this.fakeHandler) {
            document.body.removeEventListener('click', this.fakeHandlerCallback);
            this.fakeHandler = null;
            this.fakeHandlerCallback = null;
          }

          if (this.fakeElem) {
            document.body.removeChild(this.fakeElem);
            this.fakeElem = null;
          }
        };
        const isRTL = document.documentElement.getAttribute('dir') === 'rtl';
        removeFake();
        this.fakeHandlerCallback = function () {
          return removeFake();
        };
        this.fakeHandler = document.body.addEventListener('click', this.fakeHandlerCallback as any);
        this.fakeElem = document.createElement('div');
        this.fakeElem.style.fontSize = '12pt';
        this.fakeElem.style.border = '0';
        this.fakeElem.style.padding = '0';
        this.fakeElem.style.margin = '0';
        this.fakeElem.style.position = 'absolute';
        this.fakeElem.style[isRTL ? 'right' : 'left'] = '-9999px';
        const yPosition = window.pageYOffset || document.documentElement.scrollTop;
        this.fakeElem.addEventListener('focus', window.scrollTo(0, yPosition));
        this.fakeElem.style.top = yPosition + 'px';
        this.fakeElem.setAttribute('readonly', '');
        this.fakeElem.innerHTML = innerHtml;
        document.body.appendChild(this.fakeElem);

        return this.fakeElem;
      },
    });
  }
}
