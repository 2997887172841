<section class="choose-custom-page-body">
  <section class="choose-custom-page-section">

    <div class="search-container">
      <se-form-input [formControl]="searchControl" placeholder="Search Custom Page"></se-form-input>
      <span class="fa fa-search"></span>
    </div>

    @if (tableData && tableData[0]?.data.length && !dataLoading) {
      <se-summary-table
        tableType="compact"
        emptyDataSymbol=""
        [data]="tableData"
        [showRadioButton]="true"
        [tableHeaders]="tableHeadersData"
        (onRowSelect)="onRowSelect($event)"
      ></se-summary-table>
      <div class="container-pagination">
        <se-pager
          [options]="pagination"
          [pagesSliceCount]="1"
          (onPageChange)="onPageChange($event)"
        ></se-pager>
      </div>
    }

    @if (dataLoading) {
      <div class="table-list-loader">
        <se-circle-loader type="circle-scale"></se-circle-loader>
      </div>
    }
    @if (tableData && !tableData[0]?.data.length && !dataLoading) {
      <se-notification
        type="no-entities-found"
        [title]="tableLabels.noEntitiesFound.title"
        [message]="tableLabels.noEntitiesFound.message"
      ></se-notification>
    }
  </section>
</section>
