<header class="page-header">
  <section class="major-section" data-at-id="custom-page-title">
    <div class="controls-container">
      <h3 class="page-title" data-at-id="custom-page-title-name">
        {{ title }}
      </h3>

      @if (isNotEndPage) {
        <ng-container
          *accessControl="{ user: [Permissions.view_custom_pages] }"
          >
          <ng-select
            dropdownPosition="bottom"
            class="ng-select-version ng-select-header"
            bindLabel="versionName"
            bindValue="pageId"
            [multiple]="false"
            [items]="customPageVersions.versions"
            [clearable]="false"
            [searchable]="false"
            [placeholder]="'Select Version'"
            (change)="selectVersion($event)"
            [(ngModel)]="customPageVersionId"
          ></ng-select>
          <button
            *accessControl="{ user: [Permissions.manage_custom_page_versions] }"
            class="btn-manage-version btn"
            (click)="manageVersion()"
            >
            <span class="ensight-icon-settings"></span>
          </button>
        </ng-container>
      }
    </div>

    @if (showSaveBtn && isEditMode) {
      <section
        data-at-id="notes-container"
        class="header-form"
        >
        <label>Note:</label>
        <div class="note-control">
          <input
            type="text"
            maxlength="100"
            [formControl]="addNoteControl"
            placeholder="Add edit notes">
          </div>
        </section>
      }

      <div class="controls-container">
        @if (showSaveBtn) {
          <se-button
            class="save-btn"
            data-at-id="custom-page-title-save"
            (click)="savePage()"
            size="medium"
            [disabled]="disableSaveBtn"
            [loading]="loading"
            >
            Save
          </se-button>
        }

        <button
          (click)="leaveCustomPage()"
          class="btn close-page-btn"
          data-at-id="custom-page-title-close"
          >
          <i class="ensight-icon-se-close"></i>
        </button>
      </div>
    </section>

    @if (showSecondary) {
      <section
        class="secondary-section"
        data-at-id="custom-page-btns"
        [class.end-page]="!isNotEndPage"
        >
        @if (isNotEndPage) {
          <ng-container *accessControl="{ user: [Permissions.view_custom_pages] }">
            <div class="container-presentation">
              <span
                class="presentation-name"
                [ngClass]="{ 'no-presentation-selected': !presentationName }"
                >
                {{ presentationName || 'No Presentation Selected' }}
              </span>
              @if (presentationName) {
                <se-button
                  class="secondary btn-clear"
                  (click)="clearSelectedPresentation()"
                  >
                  Clear
                </se-button>
              }
              <se-button class="secondary" (click)="choosePresentation()">
                Choose Presentation
              </se-button>
            </div>
          </ng-container>
        }
        <div class="container-buttons">
          <button
        *accessControl="{
          user: [Permissions.view_custom_pages]
        }"
            (click)="downloadPdf()"
            class="btn-download ep-action-link"
            data-at-id="custom-page-btns-download"
            [class.disabled]="pdfGeneration$ | async"
            >
            <span class="ensight-icon-out"></span>
            PDF
            @if (pdfGeneration$ | async) {
              <span class="ep-action-message">
                <ep-pdf-loading-message
                  message="Your PDF is being generated."
                ></ep-pdf-loading-message>
              </span>
            }
          </button>
          <button
            class="btn-text-edit"
            (click)="openEditText()"
            *accessControl="{ user: [Permissions.edit_text_on_custom_page] }"
            >
            <span class="ensight-icon-pencil"></span>
            Text Edit
          </button>
          <button
            class="btn-setting"
            (click)="openPageSettings()"
            *accessControl="{ user: [Permissions.edit_custom_page] }"
            data-at-id="custom-page-btns-settings"
            >
            <span class="ensight-icon-settings"></span>
            Page Settings
          </button>
          @if (isNotDependentPage) {
            <div class="container-menu-custom-page">
              @if (isBtnMenuShown) {
                <button
                  class="btn-menu"
                  epClickOutside
                  [trigger]="['btn-menu-item']"
                  (click)="toggleMenu()"
                  (close)="clickOutside()"
                ></button>
              }
              @if (isMenuOpen) {
                <div class="menu">
                  <button
              *accessControl="{
                user: [Permissions.view_custom_page_change_log]
              }"
                    type="button"
                    class="btn-menu-item"
                    (click)="openHistory()"
                    >
                    Change log
                  </button>
                  @if (isNotEndPage) {
                    <button
                *accessControl="{
                  user: [Permissions.export_custom_page]
                }"
                      type="button"
                      class="btn-menu-item"
                      (click)="exportPage()"
                      >
                      Export
                    </button>
                  }
                </div>
              }
            </div>
          }
        </div>
      </section>
    }
  </header>
