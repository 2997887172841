<ac-modal-header class="container-header" (close)="close()">
  <h2 class="title">Manage Version</h2>
</ac-modal-header>

<ac-modal-body class="container-body">
  <button (click)="create()" type="button" [disabled]="isCreationDisabled" class="bnt-create rounded">
    Create Version
  </button>

  <div class="container-manage-version">
    <div class="container-form-header d-flex flex-wrap align-items-center">
      <div class="name header">Version Name</div>
      <div class="version-id header">Version ID</div>
      <div class="date text-right header">Start Date</div>
      <div class="reset"></div>
      <div class="date end-date-column text-right header">End Date</div>
      <div class="action header">Actions</div>
    </div>

    <form class="container-form-body" [formGroup]="form">
      <ng-container formArrayName="versions">
        @for (versionFrom of form.get('versions')['controls']; track versionFrom; let isFirst = $first; let isLast = $last; let i = $index) {
          <div class="group d-flex flex-wrap align-items-center" [formGroup]="versionFrom">
            <div class="name">
              <ac-input>
                <input formControlName="versionName" placeholder="Version Name" class="rounded">
              </ac-input>
            </div>
            <div class="version-id d-flex align-items-center justify-content-between {{ copiedIndex === i }}">
              <p class="m-0">{{ versionFrom.controls.pageId.value || '-' }}</p>
              @if (versionFrom.controls.pageId.value) {
                <button
                  (click)="copyToClipboard(versionFrom.controls.pageId.value, i)"
                  type="button"
                  [popover]="'Copied to clipboard!'"
                  [triggers]="null"
                  [outsideClick]="true"
                  [isOpen]="copiedIndex === i"
                  class="btn-copy fa fa-regular fa-copy rounded ml-2 mb-0">
                </button>
              }
            </div>
            <div class="date position-relative text-right">
              <ac-input>
                @if (!isFirst) {
                  <ac-icon acPrefix icon="calendar" [width]="23" [height]="18" [active]="true"></ac-icon>
                }
                <!-- show no active/disabled input in case of first version -->
                @if (isFirst) {
                  <input
                    readonly
                    class="start-date no-active border-0 text-right pr-0 shadow-none"
                    placeholder="Start Date"
                    [value]="versions[i].value.startDate | date:'MM/dd/yyyy'"
                    />
                }
                <!-- show active input/datepicker in case of not first version -->
                @if (!isFirst) {
                  <input
                    readonly
                    #datePicker
                    bsDatepicker
                    class="start-date rounded"
                    placement="bottom"
                    placeholder="Start Date"
                    [isDisabled]="isFirst || !versions[i - 1].value.startDate"
                    [bsConfig]="datePickerConfig"
                    (bsValueChange)="changeStartDate($event, i)"
                    [minDate]="i > 0 ? (versions[i - 1].value.startDate | convertToDate: true) : null"
                    [maxDate]="versions[i + 1] ? versions[i + 1].value.startDate : null"
                    formControlName="startDate"/>
                }
              </ac-input>
            </div>
            <div class="reset">
              @if (isLast && !isFirst && versions[i].value.startDate) {
                <button
                  type="button"
                  class="btn-reset rounded mb-0"
                  (click)="reset(i)">
                  Reset
                </button>
              }
            </div>
            <div class="date text-right position-relative end-date-column">
              <div class="end-date no-active border-0 text-right pr-0 shadow-none end-date-label d-flex justify-content-end align-items-center rounded">
                <span>
                  {{ versions[i + 1] && versions[i + 1].value.startDate ? (versions[i + 1].value.startDate  | date:'MM/dd/yyyy') : '-' }}
                </span>
              </div>
            </div>
            <div class="action text-center">
              @if (!isFirst) {
                <button
                  type="button"
                  class="btn-delete fa fa-lg fa-trash border-0 text-decoration-none" (click)="showConfirmModal(i)">
                </button>
              }
            </div>
          </div>
        }
      </ng-container>
      <div class="version-selector-formula">
        <p>Version Selector Formula</p>
        <se-textarea formControlName="pickVersionFormula" class="d-block w-100" [height]="140" resize="none"></se-textarea>
      </div>
    </form>
  </div>
</ac-modal-body>

<ac-modal-footer class="container-footer d-flex align-items-center justify-content-between">
  <span>All the dates are displayed in the EDT timezone</span>
  <div class="d-flex align-items-end">
    <ac-button (click)="close()" class="secondary bnt-cancel" size="sm">Cancel</ac-button>
    <ac-button (click)="save()" [disabled]="form.invalid || !form.dirty" size="sm">Save</ac-button>
  </div>
</ac-modal-footer>

@if (isConfirmVisible) {
  <div class="container-confirm">
    <div class="content">
      <div class="confirm-header">
        <h2 class="title">Confirm</h2>
        <button type="button" class="btn-confirm-close" (click)="cancel()">
          <span class="btn-text">esc</span>
          <ac-icon class="btn-icon" icon="close" [width]="21" [height]="21" [active]="false"></ac-icon>
        </button>
      </div>
      <div class="confirm-body">
        You are going to remove {{ versionNameToRemove }}. Continue?
      </div>
      <div class="confirm-footer d-flex align-items-center justify-content-center">
        <ac-button (click)="remove()" class="bnt-yes" size="sm">Yes</ac-button>
        <ac-button (click)="cancel()" class="secondary" size="sm">Cancel</ac-button>
      </div>
    </div>
  </div>
}
