import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ChangeDetectorRef,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { debounceTime, filter, first, groupBy, mergeMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { js_beautify } from 'js-beautify';

import { AppState } from '../../../../../reducers';
import { configurationForm, getCurrentCustomPage, selectFormValidation } from '../../../redux';
import { CustomPage, FormConfigurations, MappedPresentation } from '@shared/models';
import {
  CompilationEditorOptions,
  CUSTOM_METRIC_FORMULA_STATE,
  CUSTOM_METRIC_STATE,
  CustomMetricButtons,
  FormulaEditorOptions,
} from '../../../constants';
import { ButtonConfiguration, CompiledCustomMetric, FormValue, MappedCustomPage } from '../../../models';
import { FormSharedService } from '@se/dynamic-form';
import { CustomPagePreviewService, CustomPageService } from '../../../services';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

@UntilDestroy()
@Component({
  selector: 'ep-custom-metrics',
  templateUrl: './custom-metrics.component.html',
  styleUrls: ['./custom-metrics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomMetricsComponent implements OnInit {
  @Input() id: string;
  @Input() configurationStateId: string;
  @Input() customMetricFormulaStateId: string;
  @Input() editMode: boolean;

  @ViewChildren(CodemirrorComponent) editors: QueryList<CodemirrorComponent>;

  questionsData$: Observable<{ data: any }>;
  formulaQuestions$: Observable<{ data: any }>;
  activeTab: string;
  buttons: any[] = CustomMetricButtons;
  compilation = '';
  formulaEditorOptions = FormulaEditorOptions;
  compilationEditorOptions = CompilationEditorOptions;
  isNewVersion = false;
  form: UntypedFormGroup;
  selectedPresentation: MappedPresentation;
  customPage: CustomPage;
  isError = false;
  isValidForm = false;

  private currentCustomPageAndVersions: MappedCustomPage;

  constructor(
    private store: Store<AppState>,
    private formSharedService: FormSharedService,
    private cdr: ChangeDetectorRef,
    private customPagePreviewService: CustomPagePreviewService,
    private customPageService: CustomPageService
  ) {}

  ngOnInit(): void {
    this.selectConfiguration({ key: this.editMode ? CUSTOM_METRIC_FORMULA_STATE.id : CUSTOM_METRIC_STATE.id });
    this.currentCustomPageAndVersions = this.customPageService.getCurrentCustomPageAndVersions();
    this.selectedPresentation = this.customPagePreviewService.getPresentation;
    this.questionsData$ = this.initializeForm(this.configurationStateId);
    this.formulaQuestions$ = this.initializeForm(this.customMetricFormulaStateId);
    this.watchForFormState();
    this.getCurrentCustomPage();
    this.watchForValidForm();
  }

  selectConfiguration(event: ButtonConfiguration<string> | Record<string, string>): void {
    this.activeTab = event.key;

    this.buttons = this.buttons.map((item: ButtonConfiguration<string>) => {
      return {
        ...item,
        active: event.key === item.key,
      };
    });

    setTimeout(() => this.editors.forEach(e => e.codeMirror.refresh()), 300);

    this.cdr.markForCheck();
  }

  asFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl;
  }

  enableVersion2(): void {
    this.updateToVersion2();
    this.form.get('isNewVersion').setValue(true);
  }

  format(): void {
    const control = this.getFormControl();

    control.setValue(js_beautify(control.value));
  }

  compile(): void {
    const addedDate = this.getAddedDate();

    this.compilationEditorOptions.placeholder = 'Loading...';
    this.compilation = '';

    this.customPagePreviewService
      .compileCustomMetric(this.selectedPresentation.id, addedDate, this.customPage)
      .pipe(untilDestroyed(this))
      .subscribe((data: CompiledCustomMetric) => {
        this.isError = data.errorMessage;
        this.compilation = data.compilationResult;
        this.compilationEditorOptions.placeholder = 'Click "Compile" to see preview';
        this.cdr.markForCheck();
      });
  }

  private initializeForm(formId: string): Observable<{ data: FormConfigurations }> {
    return this.store.pipe(
      select(
        configurationForm({
          id: this.id,
          stateId: formId,
        })
      ),
      filter(item => !!item),
      first()
    ) as Observable<{ data: FormConfigurations }>;
  }

  private watchForFormState(): void {
    (this.formSharedService.valueChangePure$ as Observable<FormValue>)
      .pipe(
        groupBy((form: FormValue) => form.formId),
        mergeMap(formGroup$ => formGroup$.pipe(debounceTime(100))),
        filter((data: FormValue) => data.formId === this.customMetricFormulaStateId),
        untilDestroyed(this)
      )
      .subscribe((data: FormValue) => {
        this.form = data.form;
        this.isNewVersion = data.form.value.isNewVersion;
        this.cdr.markForCheck();
      });
  }

  private updateToVersion2(): void {
    const control = this.getFormControl();
    const replaceCurlyBracket = (regexp: RegExp, str: string) => {
      const match = regexp.exec(str);
      const index = match.index + match[0].length;

      return str.slice(0, index) + ' return ' + str.slice(index);
    };

    const newValue = control.value
      .replace(/\.\[\?\]\.value/gi, '?.[iterator]')
      .replace(/\.\[\?-1\]\.value/gi, '?.[iterator - 1]')
      .replace(/products\.\[\?\]\.configjson\.data\./gi, '+get_.product.configjson.data?.')
      .replace(/products\.\[\?\]\.configjson\.metadata\./gi, '+get_.product.configjson.metadata?.')
      .replace(/selectedProduct\./gi, '+get_.allProducts[selectedProductIndex].')
      .replace(/\{\?/gi, '')
      .replace(/\?\}/gi, '')
      .replace(/products\.\[\d\]\.configjson\.data\./gi, (str: string) => {
        const matches = str.match(/(\d+)/);

        return matches[0]
          ? `+get_.allProducts[${matches[0]}].configjson.data?.`
          : '+get_.allProducts[0].configjson.data?.';
      })
      .replace(/products\.\[\d\]\.configjson\.metadata\./gi, (str: string) => {
        const matches = str.match(/(\d+)/);

        return matches[0]
          ? `+get_.allProducts[${matches[0]}].configjson.metadata?.`
          : '+get_.allProducts[0].configjson.metadata?.';
      })
      .replace(/if\s*\([^)]*\)\s*{/gis, (str: string) => replaceCurlyBracket(/if\s*\([^)]*\)\s*{/gis, str))
      .replace(/(else)\s*\{/gis, (str: string) => replaceCurlyBracket(/(else)\s*\{/gis, str))
      .replace(/products\.0\./gi, '+get_.products.[0].');

    control.setValue(newValue);
  }

  private getFormControl(): UntypedFormControl {
    return this.asFormControl(this.form.get('formula'));
  }

  private getCurrentCustomPage(): void {
    this.store
      .select(getCurrentCustomPage)
      .pipe(untilDestroyed(this))
      .subscribe((customPage: CustomPage) => {
        this.customPage = customPage;
        this.cdr.markForCheck();
      });
  }

  private getAddedDate(): string {
    return this.currentCustomPageAndVersions.customPageVersions.versions.find(i => i.pageId === this.customPage._id)
      ?.startDate;
  }

  private watchForValidForm(): void {
    this.store
      .select(selectFormValidation)
      .pipe(untilDestroyed(this))
      .subscribe(isValid => {
        this.isValidForm = isValid;
        this.cdr.markForCheck();
      });
  }
}
