<div class="modal-container">
  <div class="modal-header">
    <div class="modal-header-title">{{ editMode ? title :  activeInsertTypeState?.title }}</div>
    <div (click)="closeModal()" class="header-close">
      <div class="esc">esc</div>
      <div class="ensight-icon-se-close"></div>
    </div>
  </div>

  <div class="modal-body">
    <div>
      @switch (activeInsertTypeState?.id) {
        @case (chartConfigurationStateId) {
          <ep-configuration
            [configurationStateId]="chartConfigurationStateId"
            [plansConfigData]="plansConfigData"
            [plansConfigMeta]="plansConfigMeta"
            [id]="id"
          ></ep-configuration>
        }
        @case (chartStyleConfigurationStateId) {
          <ep-configuration
            [configurationStateId]="chartStyleConfigurationStateId"
            [id]="id"
          ></ep-configuration>
        }
        @case (textConfigurationStateId) {
          <ep-configuration
            [configurationStateId]="textConfigurationStateId"
            [id]="id"
          ></ep-configuration>
        }
        @case (productsDescriptionStateId) {
          <ep-configuration
            [configurationStateId]="productsDescriptionStateId"
            [id]="id"
          ></ep-configuration>
        }
        @case (apiCallConfigurationStateId) {
          <ep-configuration
            [configurationStateId]="apiCallConfigurationStateId"
            [id]="id"
          ></ep-configuration>
        }
        @case (postApiCallStateId) {
          <ep-configuration
            [configurationStateId]="postApiCallStateId"
            [id]="id"
          ></ep-configuration>
        }
        @case (apiCallResponseStateId) {
          <ep-multiple-configurations
            [configurationStateId]="apiCallResponseStateId"
            [id]="id"
          ></ep-multiple-configurations>
        }
        @case (customTableStateId) {
          <ep-configuration
            [configurationStateId]="customTableStateId"
            [id]="id"
          ></ep-configuration>
        }
        @case (customTableColumnsStateId) {
          <ep-multiple-configurations
            [configurationStateId]="customTableColumnsStateId"
            [id]="id"
          ></ep-multiple-configurations>
        }
        @case (customMetricStateId) {
          <ep-custom-metrics
            [id]="id"
            [editMode]="editMode"
            [configurationStateId]="customMetricStateId"
          [customMetricFormulaStateId]="customMetricFormulaStateId"></ep-custom-metrics>
        }
        @case (variableConfigurationStateId) {
          <ep-configuration
            [configurationStateId]="variableConfigurationStateId"
            [id]="id"
          ></ep-configuration>
        }
        @case (buttonConfigurationStateId) {
          <ep-configuration
            [configurationStateId]="buttonConfigurationStateId"
            [id]="id"
          ></ep-configuration>
        }
        @case (chartMetricsStateId) {
          <ep-multiple-configurations
            [plansConfigData]="plansConfigData"
            [plansConfigMeta]="plansConfigMeta"
            [configurationStateId]="chartMetricsStateId"
            [id]="id"
          ></ep-multiple-configurations>
        }
        @case (insertTypeStateId) {
          <ep-insert-type
            [selectedInsertType]="selectedInsertType"
          ></ep-insert-type>
        }
        @case (customPageStateId) {
          <ep-choose-custom-page></ep-choose-custom-page>
        }
        @case (insertsStateId) {
          <ep-tree-inserts></ep-tree-inserts>
        }
        @case (imageStateId) {
          <ep-set-image
            [id]="id"
            (uploadImage)="setImageOnUpload($event)"
            (selectImage)="setImageSelect($event)"
            (deleteImage)="setImageDelete($event)"
            (loadedImage)="setImageLoaded($event)"
          ></ep-set-image>
        }
        @case (dropdownStateId) {
          <ep-configuration
            [configurationStateId]="dropdownStateId"
            [id]="id"
          ></ep-configuration>
        }
        @case (productSelectorStateId) {
          <ep-configuration
            [configurationStateId]="productSelectorStateId"
            [id]="id"
          ></ep-configuration>
        }
        @case (tabStateId) {
          <ep-configuration
            [configurationStateId]="tabStateId"
            [id]="id"
          ></ep-configuration>
        }
        @case (tabAreaStateId) {
          <ep-multiple-configurations
            [configurationStateId]="tabAreaStateId"
            [id]="id"
          ></ep-multiple-configurations>
        }
      }
    </div>
  </div>

  <div class="modal-footer">
    @if (isRequiredFieldsLabelShown) {
      <span data-at-id="all-fields-required">All fields are required</span>
    }
    <div class="buttons-block">
      @if (isBackBtnShown) {
        <se-button class="secondary" (click)="back()">
          Back
        </se-button>
      }
      <se-button
        (click)="next()"
        [disabled]="!isValidForm || !selectedInsertType"
        >
        {{ buttonLabel }}
      </se-button>
    </div>
  </div>
</div>
