<div data-at-id="note-container" class="note">
  @if (!editMode) {
    <button
    [ngClass]="{
      'disabled-mode': disabled,
      'add-note-btn': !note,
      'edit-note-btn': note
    }"
      [class.editable]="!disabled"
      class="p-0 text-start border-0"
      (click)="onEditProductNote(true, $event)"
      >
      <span>{{ note || 'Add Note' }}</span>
      <img
        src="../../../../../assets/icons/edit-pencil.svg"
        alt="edit-note-icon"
        class="edit-pencil-icon"
        />
      </button>
    }

    @if (editMode) {
      <div class="input-container d-flex w-100">
        <input
          class="form-input w-100"
          placeholder="Add Note"
      [ngClass]="{
        'save-note-mode': saveMode,
        'is-nav-bar': isNavBar
      }"
          [disabled]="disabled"
          #noteInput
          maxlength="40"
          [(ngModel)]="note"
          (ngModelChange)="noteChanged($event)"
          (keydown.enter)="onSaveNote($event)"
          (keydown.esc)="onResetChanges()"
          (click)="onInputClick($event)"
          />
          @if (saveMode) {
            <se-button
              class="secondary save-note"
              (click)="onSaveNote($event)"
              >
              Save
            </se-button>
          }
        </div>
      }
    </div>
