<div class="insert-dropdown-container">
  <button
    epDisableTabIndex
    class="insert-dropdown-button"
    [ngClass]="{'insert-dropdown-open': dropdownOpen}"
    type="button"
    (click)="toggleDropdown()">
    @if (!selectedValue) {
      <span>{{ title }}</span>
    }
    @if (selectedValue) {
      <span class="insert-dropdown-value">{{ selectedValue.value }}</span>
    }
  </button>

  <ul class="insert-dropdown-list" [ngClass]="{'insert-dropdown-open': dropdownOpen }">
    @for (option of options; track option; let index = $index) {
      @if (option.value.toString().trim() !== '') {
        <li
          [ngClass]="{'selected' : selectedValue === option }"
          tabindex="0" (click)="select(index)"
          role="option"
          [innerHTML]="option.value">
        </li>
      }
    }
  </ul>
</div>
