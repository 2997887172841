import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { filter, baseUrl } from '@core/constant';

@Injectable()
export class FilterModalService {
  constructor(private http: HttpClient) {}

  public getFilterState(params: any = { isTemplate: false }): Promise<any> {
    return this.http.post(`${baseUrl}${filter}/state`, params).pipe(catchError(this.handleError)).toPromise();
  }

  // TODO params interface
  public getFilterData(params: any): Promise<any> {
    const uiId = params.uiId || '';

    return this.http.post(`${baseUrl}${filter}/data/${uiId}`, params).pipe(catchError(this.handleError)).toPromise();
  }

  public saveFilterState(params: any): Promise<any> {
    return this.http.put(`${baseUrl}${filter}/state`, params).pipe(catchError(this.handleError)).toPromise();
  }

  public getFilterQuantity(params: any): Promise<any> {
    const requestData = params.data ? params : { ...params, default: true };
    const uiId = params.uiId || '';

    return this.http
      .post(`${baseUrl}${filter}/qty/${uiId}`, requestData)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  private handleError(error: any) {
    return throwError(`${error.status} - ${error.statusText}`);
  }
}
