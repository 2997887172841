import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { first, tap, map, switchMap } from 'rxjs/operators';

import { DEFAULT_METRIC_VALUE } from '../../constants';
import { AppState } from '../../../../reducers';
import {
  dynamicFormValue,
  getSelectedInserts,
  placeholdersMetadata,
  selectedImageName,
  selectedInsertType,
} from '../../redux';
import { persistImagesList, setPlaceholdersDataSuccess, updateDynamicFormFields } from '../../redux';
import { Insert, WizardModalResponse } from '@shared/models';
import { PlaceholdersOrderService } from '../placeholders-order/placeholders-order.service';
import { INSERT_TYPES } from '@shared/constants';
import {
  ChartPlaceholder,
  ImagePlaceholder,
  InsertType,
  Placeholder,
  PlaceholderAction,
  PlaceholderMetric,
  PlaceholdersDynamicFormValue,
  TableColumn,
  TextPlaceholder,
  VariablePlaceholder,
} from '@core/model';
import { ChartTypes, INSERT_TYPE } from '@core/enums';

@Injectable()
export class PlaceholdersWizardService {
  constructor(private store: Store<AppState>, private placeholderOrderService: PlaceholdersOrderService) {}

  setPlaceholdersFromOtherPage(): Observable<Placeholder[]> {
    return this.store.select(getSelectedInserts).pipe(
      map((inserts: Insert[]) => {
        return inserts.map(i => {
          const { metadata, usedInPages } = i;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { order, ...metadataWithoutOrder } = metadata;

          return {
            ...metadataWithoutOrder,
            usedInPages,
          };
        }) as Placeholder[];
      }),
      switchMap((newPlaceholders: Placeholder[]) => this.createPlaceholdersByStep(newPlaceholders))
    );
  }

  private createPlaceholdersByStep(newPlaceholders: Placeholder[]): Observable<Placeholder[]> {
    return this.store.select(placeholdersMetadata).pipe(
      first(),
      map(createdPlaceholders => {
        const updatedPlaceholdersList = [];
        newPlaceholders.forEach(newPlaceholder => {
          const exist = createdPlaceholders.some(createdPlaceholder => createdPlaceholder.id === newPlaceholder.id);

          if (!exist) {
            updatedPlaceholdersList.push({ ...newPlaceholder, reused: true, create: true });
          }
        });
        this.addPlaceholder(createdPlaceholders, updatedPlaceholdersList);

        return newPlaceholders;
      })
    );
  }

  private addPlaceholder(createdPlaceholders: Placeholder[], updatedPlaceholdersList: Placeholder[]): void {
    this.store.dispatch(
      setPlaceholdersDataSuccess({
        metadata: this.placeholderOrderService.addNewPlaceholders(createdPlaceholders, updatedPlaceholdersList),
      })
    );
  }

  deletePlaceholderStateById(placeholder: Placeholder): Observable<Placeholder[]> {
    return this.store.pipe(
      select(placeholdersMetadata),
      first(),
      tap(placeholders => {
        this.store.dispatch(
          setPlaceholdersDataSuccess({
            metadata: this.placeholderOrderService.reorderOnDeletePlaceholder(placeholders, placeholder),
          })
        );
      })
    );
  }

  setPlaceholderStateById(data: PlaceholderAction & { id: string }): Observable<WizardModalResponse> {
    return combineLatest([
      this.store.pipe(select(dynamicFormValue)),
      this.store.pipe(select(selectedImageName)),
      this.store.pipe(select(selectedInsertType)),
      this.store.pipe(select(placeholdersMetadata)),
    ]).pipe(
      first(),
      map(([formValue, selectedImage, selectedType, placeholders]) => {
        if (selectedType === INSERT_TYPE.image && selectedImageName) {
          formValue = { ...formValue, selectedImage };
        }

        if (data.create) {
          this.createPlaceholder(formValue, selectedType, placeholders, data.id);
        } else if (data.edit) {
          this.editPlaceholder(formValue, selectedType, placeholders, data.id);
        }

        if (selectedType === INSERT_TYPE.customMetric || selectedType === INSERT_TYPE.productsDescription) return;

        return {
          id: data.id,
          type: INSERT_TYPES.find(({ type }) => type === selectedType).label,
          name: (formValue as ChartPlaceholder).chartName || (formValue as TextPlaceholder).placeholderName,
          width: (formValue as ImagePlaceholder).width,
          height: (formValue as ImagePlaceholder).height,
          hidden: (formValue as VariablePlaceholder).hidden,
          hiddenOnSharedView: (formValue as VariablePlaceholder).hiddenOnSharedView,
        };
      })
    );
  }

  setGeneratedPlaceholderKey(form: UntypedFormGroup): string {
    const placeholderKey = form.value.placeholderName
      .replace(/[^A-Z0-9\s]+/gi, '')
      ?.trim()
      .toLowerCase()
      .replace(/\s+/g, '_');
    form.controls.placeholderKey.setValue(placeholderKey, { emitEvent: false });

    return placeholderKey;
  }

  updateDynamicFormFields(newFormValue: Omit<ChartPlaceholder, 'metrics'> | TextPlaceholder, valid: boolean): void {
    const fields = { ...newFormValue };
    const fieldsToConvert = [
      'placeholderMaxLength',
      'placeholderDecimals',
      'placeholderMinValue',
      'placeholderMaxValue',
      'columnsNumber',
      'apiResultsNumber',
      'tabsNumber',
    ];
    fieldsToConvert.forEach(field => this.convertFieldToNumber(fields, field));
    this.store.dispatch(updateDynamicFormFields({ fields, valid }));
  }

  updateDynamicFormMetrics(
    formId: number,
    newFormValue: Omit<PlaceholderMetric<string>, 'order'>,
    valid: boolean
  ): void {
    const metric: PlaceholderMetric = {
      order: formId,
      ...newFormValue,
      productIndex: isNaN(+newFormValue.productIndex) ? newFormValue.productIndex : +newFormValue.productIndex - 1,
    };
    // TODO: need to change interface
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.store.pipe(select(dynamicFormValue), first()).subscribe((formValue: Placeholder.ChartPlaceholder) => {
      if (!formValue) return;

      this.store.dispatch(
        updateDynamicFormFields({
          valid,
          fields: {
            metrics: formValue.metrics ? this.getUpdatedMetrics(metric, ...formValue.metrics) : [],
          },
        })
      );
    });
  }

  public updateDynamicFormTabs(
    formId: number,
    newFormValue: Omit<TableColumn, 'index | placeholderKey'>,
    valid: boolean,
    tabsField: 'columns' | 'results' | 'tabs' = 'columns'
  ) {
    const tab = {
      index: formId,
      ...newFormValue,
    };

    this.store.pipe(select(dynamicFormValue), first()).subscribe((formValue: any) => {
      if (!formValue) return;

      this.store.dispatch(
        updateDynamicFormFields({
          valid,
          fields: {
            [tabsField]: formValue[tabsField] ? this.getUpdatedColumns(tab, ...formValue[tabsField]) : [],
          },
        })
      );
    });
  }

  private convertFieldToNumber(fields: Omit<ChartPlaceholder, 'metrics'> | TextPlaceholder, field: string): void {
    fields[field] && (fields[field] = Number(fields[field]));
  }

  private createPlaceholder(
    formValue: PlaceholdersDynamicFormValue,
    selectedType: InsertType,
    oldPlaceholders: Placeholder[],
    id: string
  ): void {
    if (!id) return;

    const newPlaceholders = [];
    newPlaceholders.push({
      ...formValue,
      id,
      create: true,
      insertType: selectedType,
    });

    this.store.dispatch(
      setPlaceholdersDataSuccess({
        metadata: this.placeholderOrderService.addNewPlaceholders(oldPlaceholders, newPlaceholders),
      })
    );
    selectedType === INSERT_TYPE.image && this.store.dispatch(persistImagesList({ id }));
  }

  private editPlaceholder(
    formValue: PlaceholdersDynamicFormValue,
    selectedType: InsertType,
    placeholders: Placeholder[],
    id: string
  ): void {
    const metadata = placeholders.map(placeholder => {
      return placeholder.id === id
        ? {
            ...placeholder,
            ...formValue,
            edit: placeholder.reused ? placeholder.reused : !placeholder.create,
            create: placeholder.reused ? !placeholder.reused : placeholder.create,
            delete: false,
          }
        : placeholder;
    });

    this.store.dispatch(
      setPlaceholdersDataSuccess({ metadata: this.placeholderOrderService.handleOrdering(metadata) })
    );
    selectedType === INSERT_TYPE.image && this.store.dispatch(persistImagesList({ id }));
  }

  private getUpdatedMetrics(metric: PlaceholderMetric, ...updatedMetrics: PlaceholderMetric[]): PlaceholderMetric[] {
    const metricExists = updatedMetrics.some(metricState => metricState.order === metric.order);

    if (metricExists) {
      updatedMetrics =
        metric.metricKey === DEFAULT_METRIC_VALUE
          ? updatedMetrics.filter(metricState => !(metricState.order === metric.order))
          : updatedMetrics.map(metricState => (metricState.order === metric.order ? metric : metricState));
    } else {
      metric.metricKey !== DEFAULT_METRIC_VALUE && updatedMetrics.push(metric);
    }

    return this.setStepLineType(metric, updatedMetrics);
  }

  private setStepLineType(metric: PlaceholderMetric, updatedMetrics: PlaceholderMetric[]): PlaceholderMetric[] {
    const chartTypes = [ChartTypes.step, ChartTypes.areaStep];

    if (!chartTypes.includes(metric.chartType)) return updatedMetrics;

    return updatedMetrics.map(updatedMetric => ({
      ...updatedMetric,
      stepLineType: metric.stepLineType,
    }));
  }

  private getUpdatedColumns(column: TableColumn, ...updatedColumns: TableColumn[]): TableColumn[] {
    const columnExists = updatedColumns.some(item => item.index === column.index);

    if (columnExists) {
      updatedColumns = updatedColumns.map(item => (item.index === column.index ? column : item));
    } else {
      updatedColumns.push(column);
    }

    return updatedColumns;
  }
}
