<div class="container-custom-pages container-header">
  <div class="container-logo">
    <a routerLink="/home" class="logo"></a>
  </div>
  <div class="container-menu">
    <ensight-user-menu></ensight-user-menu>
  </div>
</div>

<div class="container-title">
  <p class="title">Custom Pages</p>
</div>

<div class="container-buttons">
  <!-- TODO:  need change style of buttons, because they have movement when active -->
  <se-button-group
    [buttons]="pageButtons"
    size="medium"
    (onSelectButton)="selectPage($event)"
  ></se-button-group>
</div>

<div class="container-custom-pages container-search">
  <div class="wrap-search">
    <se-form-input
      class="custom-page-search"
      [formControl]="searchControl"
      [placeholder]="
        activeButtonKey === 'salesconcept'
          ? 'Search by Page Name, Label and Sales Story Label'
          : 'Search by Page Name and Label'
      "
      [type]="'search'"
    ></se-form-input>
  </div>

  <div class="wrap-btns">
    <se-button
      *accessControl="{ user: [Permissions.import_custom_page] }"
      type="file"
      [options]="{ format: '.json' }"
      class="secondary"
      [loading]="importLoading"
      size="medium"
      (onInputChange)="importCustomPage($event)"
      format=".json"
      content="Import"
    ></se-button>

    <se-button
      *accessControl="{ user: [Permissions.create_custom_page] }"
      class="primary"
      size="medium"
      (click)="goToPage(['create'])"
      >
      Create
    </se-button>
  </div>
</div>

<!--TODO: Need to be improved, need to avoid duplication-->
@if (!loadingRecords && tableData.length) {
  <div class="container-table">
    <ep-table
      class="table-parent"
      [data]="tableData"
      [tableHeaderColumn]="tableHeaderColum"
      [tableColumn]="tableBodyColum"
      [trackByKey]="'name'"
      (rowClick)="expendRow($event)"
      [orderByParams]="currentOrderByParams"
      (sortBy)="setSortedCustomList($event)"
      [expendedDetailColumns]="expendedColumns"
      >
      <ng-container epCustomTableColumn columnName="icon">
        <ng-template>
          <div class="wrap-icon">
            <span
              class="icon"
            [ngClass]="{
              'icon-dependent-page': activeButtonKey === 'dependentpage',
              'icon-end-page': activeButtonKey === 'requiredpage'
            }"
            ></span>
          </div>
        </ng-template>
      </ng-container>
      <ng-container epCustomTableColumn columnName="expend">
        <ng-template let-record="record" let-index="index">
          @if (record.numDependentPages > 0) {
            <div
              class="wrap-expend"
              [ngClass]="{ expended: isExpended && currentRowIndex === index }"
              >
              <span class="icon-expend"></span>
            </div>
          }
        </ng-template>
      </ng-container>
      <ng-container epCustomTableColumn columnName="name">
        <ng-template let-value="value" let-record="record">
          <a
            [routerLink]="[listUrl, record._id]"
            class="wrap-name custom-page-title"
            >
            {{ value | default: '-' }}
          </a>
        </ng-template>
      </ng-container>
      <ng-container epCustomTableColumn columnName="menu">
        <ng-template let-record="record" let-index="index">
          <div class="container-menu-custom-page">
            <button
              class="btn-menu"
              #menuBtn
              epClickOutside
              [trigger]="['btn-menu', 'btn-menu-item']"
              (click)="toggleSalesConceptMenu(index); $event.stopPropagation()"
              (close)="closeMenus()"
            ></button>
            @if (isSalesConceptMenuOpen && currentSalesConceptIndex === index) {
              <div
                class="menu"
                epDynamicPositionOfMenu
                [menuBtn]="menuBtn"
                >
                <button
                  type="button"
                  class="btn-menu-item"
                  *accessControl="{ user: [Permissions.edit_custom_page] }"
                  (click)="goToPage([record._id, 'edit'])"
                  >
                  Page Settings
                </button>
                <button
                  type="button"
                  class="btn-menu-item"
                  *accessControl="{ user: [Permissions.edit_text_on_custom_page] }"
                  (click)="goToPage([record._id, 'edit-text'])"
                  >
                  Text Edit
                </button>
                @if (activeButtonKey === 'salesconcept') {
                  <button
                    type="button"
                    class="btn-menu-item"
                (click)="
                  exportCustomPage(record.uiId, record.name);
                  $event.stopPropagation()
                "
                    *accessControl="{ user: [Permissions.export_custom_page] }"
                    >
                    Export
                  </button>
                  <button
                    type="button"
                    class="btn-menu-item"
                *accessControl="{
                  user: [Permissions.view_custom_page_change_log]
                }"
                    (click)="goToPage([record._id, 'history'])"
                    >
                    View Changelog
                  </button>
                }
                <button
                  type="button"
                  class="btn-menu-item"
              (click)="
                copyCustomPage(record.uiId, record.name);
                $event.stopPropagation()
              "
                  *accessControl="{ user: [Permissions.create_custom_page] }"
                  >
                  Copy
                </button>
                <button
                  type="button"
                  class="btn-menu-item btn-delete"
                  (click)="removeCustomPage(record); $event.stopPropagation()"
                  [disabled]="record?.usedInGroupsCount"
                  *accessControl="{ user: [Permissions.delete_custom_page] }"
                  >
                  Delete
                </button>
              </div>
            }
          </div>
        </ng-template>
      </ng-container>
      <ng-container epCustomTableColumn columnName="usedInGroups">
        <ng-template let-record="record">
          @if (record.usedInGroupsCount) {
            <div class="wrap-content">
              <span
                class="badge-content"
                [ngClass]="{ 'no-clickable': !record.usedInGroups.length }"
                (click)="openUsedInGroupsModal(record); $event.stopPropagation()"
                >
                {{ record.usedInGroupsCount }}
              </span>
            </div>
          }
        </ng-template>
      </ng-container>
      <ng-container epCustomTableColumn columnName="expendedDetails">
        <ng-template let-record="record" let-index="index" let-column="column">
          <ng-container
          *ngTemplateOutlet="
            dependentPagesTable;
            context: { record, index, column }
          "
          ></ng-container>
        </ng-template>
      </ng-container>
    </ep-table>
  </div>
}

@if (!loadingRecords && tableData.length) {
  <div class="container-pagination">
    <se-pager
      [options]="paginationOptions"
      [pagesSliceCount]="5"
      (onPageChange)="setPaginationParams($event)"
    ></se-pager>
  </div>
}

@if (loadingRecords) {
  <div class="container-spinner-message">
    <se-circle-loader></se-circle-loader>
  </div>
}

@if (!loadingRecords && !tableData.length) {
  <se-notification
    type="no-entities-found"
    [title]="noDataMessage.title"
    [message]="noDataMessage.message"
  ></se-notification>
}
<!--TODO: Need to be improved, need to avoid duplication-->
<ng-template
  #dependentPagesTable
  let-record="record"
  let-index="index"
  let-column="column"
  >
  @if (isExpended && currentRowIndex === index) {
    <td
      [colSpan]="column.colSpan"
      >
      <div
        class="container-dependent-pages"
        [@detailExpand]="isExpended && currentRowIndex === index"
        >
        @if (!isDependentPagesLoading && dependentPages.length) {
          <ep-table
            [data]="dependentPages"
            [tableColumn]="dependentTableRowColumn"
            [rowHeaderStyle]="rowHeaderStyle"
            [rowBodyStyle]="rowBodyStyle"
            [trackByKey]="'name'"
            >
            <ng-container epCustomTableColumn columnName="pageIndex">
              <ng-template let-index="index">{{ index + 1 }}.</ng-template>
            </ng-container>
            <ng-container epCustomTableColumn columnName="name">
              <ng-template let-value="value" let-record="record">
                <a
                  [routerLink]="[listUrl, record._id]"
                  class="wrap-name custom-page-title"
                  (click)="$event.stopPropagation()"
                  >
                  {{ value | default: '-' }}
                </a>
              </ng-template>
            </ng-container>
            <ng-container epCustomTableColumn columnName="menu">
              <ng-template let-record="record" let-index="index">
                <div class="container-menu-custom-page">
                  <button
                    class="btn-menu"
                    epClickOutside
                    #menuDependentPageBtn
                    [trigger]="['btn-menu', 'btn-menu-item']"
                    (click)="toggleDependentPageMenu(index)"
                    (close)="closeMenus()"
                  ></button>
                  @if (
                    isDependentPageMenuOpen && currentDependentPageIndex === index
                    ) {
                    <div
                      class="menu"
                      epDynamicPositionOfMenu
                      [menuBtn]="menuDependentPageBtn"
                      >
                      <button
                        type="button"
                        class="btn-menu-item"
                        *accessControl="{ user: [Permissions.edit_custom_page] }"
                        (click)="goToPage([record._id, 'edit'])"
                        >
                        Page Settings
                      </button>
                      <button
                        type="button"
                        class="btn-menu-item"
                  *accessControl="{
                    user: [Permissions.edit_text_on_custom_page]
                  }"
                        (click)="goToPage([record._id, 'edit-text'])"
                        >
                        Text Edit
                      </button>
                      <button
                        type="button"
                        class="btn-menu-item"
                  (click)="
                    copyCustomPage(record.uiId, record.name);
                    $event.stopPropagation()
                  "
                        *accessControl="{ user: [Permissions.create_custom_page] }"
                        >
                        Copy
                      </button>
                    </div>
                  }
                </div>
              </ng-template>
            </ng-container>
          </ep-table>
        }
        @if (isDependentPagesLoading) {
          <ep-skeleton-loader
            [numberOfDependentPages]="record.numDependentPages"
          ></ep-skeleton-loader>
        }
      </div>
    </td>
  }
</ng-template>
