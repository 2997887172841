import { Injectable } from '@angular/core';

import { CareerPlan, ConfigPlanOrganisation, PresentationConfigs } from '@core/model';
import { ColorScheme, MappedPresentation } from '@shared/models';
import { LocalStorageService } from '@core/service';
import { Global } from '@shared/services';
import {
  CareerPlansPreviewKey,
  ColorSchemaPreviewKey,
  PlanOrganizationPreviewKey,
  PresentationConfigPreviewKey,
  PresentationPreviewKey,
  PreviewKeys,
} from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class PreviewCompiledPageService {
  set setPresentation(presentation: MappedPresentation) {
    this.localStorage.setData<MappedPresentation>(PresentationPreviewKey, presentation);
  }

  get getPresentation(): MappedPresentation {
    return this.localStorage.getData<MappedPresentation>(PresentationPreviewKey);
  }

  protected set setConfigs(config: PresentationConfigs) {
    this.localStorage.setData<PresentationConfigs>(PresentationConfigPreviewKey, config);
  }

  protected get getConfigs(): PresentationConfigs {
    return this.localStorage.getData<PresentationConfigs>(PresentationConfigPreviewKey);
  }

  protected set setPlans(plans: CareerPlan[]) {
    this.localStorage.setData<CareerPlan[]>(CareerPlansPreviewKey, plans);
  }

  protected get getPlans(): CareerPlan[] {
    return this.localStorage.getData<CareerPlan[]>(CareerPlansPreviewKey);
  }

  protected set setColorScheme(scheme: ColorScheme[]) {
    this.localStorage.setData<ColorScheme[]>(ColorSchemaPreviewKey, scheme);
  }

  protected get getColorScheme(): ColorScheme[] {
    return this.localStorage.getData<ColorScheme[]>(ColorSchemaPreviewKey);
  }

  protected set setPlansConfig(configs: ConfigPlanOrganisation) {
    this.localStorage.setData<ConfigPlanOrganisation>(PlanOrganizationPreviewKey, configs);
  }

  protected get getPlansConfig(): ConfigPlanOrganisation {
    return this.localStorage.getData<ConfigPlanOrganisation>(PlanOrganizationPreviewKey);
  }

  constructor(protected localStorage: LocalStorageService, protected global: Global) {}

  clearPresentationAndConfig(): void {
    this.global.setPresentation = null;
    this.global.setPlansConfig = null;
    PreviewKeys.forEach((key: string) => this.localStorage.removeItem(key));
  }
}
