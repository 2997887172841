<!--TODO: need to find another way to handle such case-->

@if ((questionsData$ | async) && !isInlineEditMode) {
  <se-dynamic-form
    [questionsData]="questionsData$"
    [formId]="configurationStateId"
    [params]="{}"
  ></se-dynamic-form>
}

@if ((questionsData$ | async) && isInlineEditMode) {
  <se-dynamic-form
    [questionsData]="questionsData$"
    [formId]="configurationStateId"
    [params]="{}"
  ></se-dynamic-form>
}
