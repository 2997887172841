import { Injectable } from '@angular/core';

import { Userpilot } from 'userpilot';
import { AuthService, IAMService, UserService } from '@assurance/um-services';
import { filter, tap } from 'rxjs/operators';

import { Global, Utils } from '@shared/services';
import { BrowserSessionStorage, LocalStorageService } from '@core/service';
import { AUTH_KEYS, userPilotStorageKey } from '@shared/constants';
import { PermissionKeyEnum } from '@core/enums';

@Injectable()
export class UserPilotService {
  constructor(
    private userService: UserService,
    private global: Global,
    private localStorage: LocalStorageService,
    private browserSessionStorage: BrowserSessionStorage,
    private iamService: IAMService,
    private utils: Utils,
    private authService: AuthService
  ) {}

  track(data: Record<string, string>): void {
    if (this.utils.readCookie('loggedIn')) {
      this.trackUser(data);
    } else {
      this.trackNoLoggedInSharedUser(data);
    }
  }

  identify(): void {
    this.localStorage.setData(userPilotStorageKey, true);

    Userpilot.identify(this.userService.user.id.toString(), {
      name: this.userService.user.username,
      email: this.userService.user.emails?.length ? this.userService.user.emails[0] : '',
      company: {
        id: this.userService.organization.id,
        name: this.userService.organization.name,
      },
      organizationId: this.userService.organization.id,
      organizationName: this.userService.organization.name,
      roleId: this.userService.user.role,
      // should be 'EP' or 'EUM' based on the portal user is on,
      portal: 'EP',
      // presentationId for EP, userId/groupId/roleId/organizationId/dataSourceId for EUM
      currentEntityId: this.global.getActivePresentationId || '',
    });
  }

  private trackUser(data: Record<string, string>): void {
    if (this.global.isSharedPresentation()) {
      this.authService
        .auth(AUTH_KEYS)
        .pipe(
          filter((isAuthenticated: boolean) => isAuthenticated && this.hasUserAccess()),
          tap(() => {
            if (!this.localStorage.getData(userPilotStorageKey)) {
              this.identify();
            }
          })
        )
        .subscribe(() => Userpilot.track(data.eventAction, { ...data, portal: 'EP' }));
    } else if (this.hasUserAccess()) {
      if (!this.localStorage.getData(userPilotStorageKey)) {
        this.identify();
      }

      Userpilot.track(data.eventAction, { ...data, portal: 'EP' });
    }
  }

  private trackNoLoggedInSharedUser(data: Record<string, string>): void {
    if (!this.localStorage.getData(userPilotStorageKey)) {
      this.identifySharedPage();
    }

    Userpilot.track(data.eventAction, { ...data, portal: 'EP' });
  }

  private identifySharedPage(): void {
    const token = this.browserSessionStorage.getItem('shared-token');

    Userpilot.identify(token, {
      name: 'Shared Page',
      email: '',
      portal: 'EP',
      currentEntityId: token,
    });

    this.localStorage.setData(userPilotStorageKey, true);
  }

  private hasUserAccess(): boolean {
    return !this.iamService.hasUserAccess(PermissionKeyEnum.exclude_from_analytic_tools_ep);
  }
}
