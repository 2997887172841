@if (modalInitComplete) {
  <div
    bsModal
    #infoChildModal="bs-modal"
    (onHide)="close()"
    class="{{ windowClass }} modal fade aapp-modal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    >
    <div class="modal-container modal-md a-app-confirm-info-modal-color">
      <div class="modal-content">
        @if (!resolve.data?.isWarning) {
          <div
            class="modal-header aapp-modal-header"
            >
            <h3 class="modal-title">{{ resolve.data.header || 'Info' }}</h3>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="close()"
              >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }
        <div class="modal-body text-center">
          <h5>{{ resolve.data.message }}</h5>
        </div>
        <div class="modal-footer">
          @if (!resolve.data?.isWarning) {
            <button
          class="
            aapp-btn
            a-app-confirm-info-modal-confirm-button-color
            aapp-btn-default
            btn btn-secondary btn-lg
            text-uppercase
          "
              type="button"
              (click)="close()"
              >
              Ok
            </button>
          }
          @if (resolve.data?.isWarning) {
            <button
          class="
            aapp-btn aapp-btn-default
            btn btn-secondary btn-lg
            text-uppercase
          "
              type="button"
              (click)="close()"
              >
              Ok
            </button>
          }
        </div>
      </div>
    </div>
  </div>
}
