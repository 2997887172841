@if ((isCoverSheetLoading$ | async) || (isCoverSheetTemplatesLoading$ | async)) {
  <div class="spinnerHolder">
    <ac-loader></ac-loader>
  </div>
}

<ac-modal-header (close)="closeModal()">
  <h2 class="title">Coversheet Details</h2>
</ac-modal-header>

<ac-modal-body>
  <section  class="sidebar-container coversheet-columns"  [ngClass]="{ withTemplates: templates && templates.length }">
    @if (templates && templates.length) {
      <aside class="wrap-list">
        <ul class="list">
          @for (template of templates; track template) {
            <li [class.selected]="activeId === template.uiId">
              <figure (click)="chooseTemplate(template.uiId)">
                <img src="{{ 'preview_' + template.uiId | setTemplateSrc: template.files }}" alt="{{ template.name}}"/>
                <figcaption>
                  <h5 class="item-title">{{ template.name }}</h5>
                </figcaption>
              </figure>
            </li>
          }
        </ul>
      </aside>
    }

    <div class="template-block wrap-block">
      @switch (activeId) {
        @case ('text') {
          <ensight-tiny-editor
            controlName="textBody"
            [form]="coverForm"
            (onKeyUp)="onModalClose($event)"
            (onEditorInstanceReady)="setEditorInstance($event)"
          ></ensight-tiny-editor>
        }
        @case ('lowcolor') {
          <ng-container *ngTemplateOutlet="lowHighColor"></ng-container>
        }
        @case ('highcolor') {
          <ng-container *ngTemplateOutlet="lowHighColor"></ng-container>
        }
        @case ('allianz') {
          <ensight-cover-sheet-template
            [landscape]="true"
            [coverSheet]="coverSheet"
            [coverSheetLoaded]="isCoverSheetLoaded$ | async"
            [coverSheetTemplates]="templates"
            [selectedTemplateUid]="activeId"
            [presentationInfo]="presentationInfo"
          ></ensight-cover-sheet-template>
        }
        @case ('allianzretirement') {
          <ensight-cover-sheet-template
            [landscape]="true"
            [coverSheet]="coverSheet"
            [coverSheetLoaded]="isCoverSheetLoaded$ | async"
            [coverSheetTemplates]="templates"
            [selectedTemplateUid]="activeId"
            [presentationInfo]="presentationInfo"
          ></ensight-cover-sheet-template>
        }
        @default {
          <ng-container *ngTemplateOutlet="lowHighColor"></ng-container>
        }
      }
    </div>

    <ng-template #lowHighColor>
      <article class="content-article">
        <div class="container-placeholder">
          <ep-cover-sheet-placeholder></ep-cover-sheet-placeholder>
        </div>
        <form [formGroup]="coverForm" class="input-holder" (ngSubmit)="saveCoverSheet()">
          <div class="plan-container">
            <span class="title required">Proposal Title</span>
            <ep-cover-sheet-input-chips
              [field]="Fields.title"
              [control]="coverForm.get(Fields.title)">
            </ep-cover-sheet-input-chips>
            @if (coverForm.get(Fields.title).errors && coverForm.get(Fields.title).dirty) {
              <div class="cover-error">
                Title cannot be empty.
              </div>
            }
          </div>

          <div class="plan-container cover-textarea cover-textarea-big">
            <span class="title required">Text Included in Coversheet</span>
            <ep-cover-sheet-input-chips
              [field]="Fields.body"
              [style]="{ height: '170px' }"
              [control]="coverForm.get('body')">
            </ep-cover-sheet-input-chips>
            @if (coverForm.get(Fields.body).errors && coverForm.get(Fields.body).dirty) {
              <div class="cover-error">
                Coversheet body cannot be empty.
              </div>
            }
          </div>

          <div class="plan-container">
            <span class="title">Coversheet Signature</span>
            <div>
              <ep-cover-sheet-input-chips
                [field]="Fields.signature"
                [control]="coverForm.get(Fields.signature)">
              </ep-cover-sheet-input-chips>
            </div>
          </div>

          <div class="plan-container cover-textarea">
            <span class="title">Text Displayed in Footer</span>
            <ep-cover-sheet-input-chips
              [field]="Fields.footer"
              [style]="{ height: '72px' }"
              [control]="coverForm.get(Fields.footer)">
            </ep-cover-sheet-input-chips>
          </div>
        </form>
      </article>
    </ng-template>
  </section>
</ac-modal-body>

<ac-modal-footer>
  <ac-button type="submit" size="sm" [disabled]="!isFormEnabled()" (click)="saveCoverSheet()">
    Submit
  </ac-button>
  <ac-button [class]="'secondary'" (click)="closeModal()" size="sm">
    Cancel
  </ac-button>
</ac-modal-footer>
