@if (isLoading) {
  <div class="spinner-holder">
    <ac-loader></ac-loader>
  </div>
}

<div class="container-tree">
  @if (tree && !isLoading) {
    <ac-tree [data]="tree" (selected)="selectedInsert($event)"></ac-tree>
  }
</div>
