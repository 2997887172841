import { CHART_HEIGHT } from '@shared/components/chart/chart.constants';

export const PRESENTATION_CONFIGS_SESSION_STORAGE_KEY = 'presentation-configs';
export const PRODUCTS_SESSION_STORAGE_KEY = 'products';

export const chart = {
  colorScheme: [
    { color: '#006bb7', free: true },
    { color: '#00c972', free: true },
    { color: '#fc5716', free: true },
    { color: '#9c27b0', free: true },
    { color: '#57F900', free: true },
  ],
  options: {
    duration: 1200,
    height: CHART_HEIGHT,
    margin: {
      bottom: 60,
      left: 140,
      right: 20,
      top: 20,
    },
    showLegend: false,
    useInteractiveGuideline: false,
  },
};

export const staticPagesNavbarUiIds = ['target_premium'];
export const events = {
  changeSelectedSinglePolicy: 'changeSelectedSinglePolicy',
};
export const activateFullScreen = () => {
  const doc: any = document.documentElement;

  if (doc.requestFullscreen) {
    document.documentElement.requestFullscreen();
  } else if (doc.msRequestFullscreen) {
    doc.msRequestFullscreen();
  } else if (doc.mozRequestFullScreen) {
    doc.mozRequestFullScreen();
  } else if ((document.documentElement as any).webkitRequestFullscreen) {
    (document.documentElement as any).webkitRequestFullscreen();
  }
};
export const deactivateFullScreen = () => {
  const doc: any = document;

  if (doc.cancelFullScreen) {
    doc.cancelFullScreen();
  } else if (doc.msExitFullscreen) {
    doc.msExitFullscreen();
  } else if (doc.mozCancelFullScreen) {
    doc.mozCancelFullScreen();
  } else if ((document as any).webkitCancelFullScreen) {
    (document as any).webkitCancelFullScreen();
  }
};
export const PRESENTATION_VIEW_ROUTER_REGEXP = /^(\/presentation\/(\d+)\/view\/)/g;
export const PRESENTATION_SETUP_ROUTER_REGEXP = /^(\/presentation\/(\d+)\/setup)/g;

export const irrPlanIds = [
  'non_guaranteed_net_surrender_value_irr',
  'non_guaranteed_net_death_benefit_irr',
  'guaranteed_net_surrender_value_irr',
  'guaranteed_net_death_benefit_irr',
];

export const metricKeys = new Map([
  ['custom_visualization', []],
  ['charges', ['total_charges_db', 'total_charges_nsv', 'total_charges_yearly']],
]);

export enum ActionTypes {
  SetupDisclosureTextUpdateSuccess = '[Setup] Disclosure Text Update Success',
  ResetSetupState = '[Setup] Reset State',
  ChangeSelectedPageId = '[Setup] Changed selected page id',
  ChangeSelectedPage = '[Setup] Changed selected page',
  ChangeSelectedPlansValues = '[Setup] Change selected plans values',
  ShowSelectedPlan = '[Setup] Show selected plan',
  InfoShowSelectedPlan = '[Setup] Show info selected plan',
  AddSelectedPlanValues = '[Setup] Add selected plan values',
  ResetSelectedPlansValues = '[Setup] Reset selected plans values',
  SetPlanLoadingPending = '[Setup] Set Plan Loading Pending',
  SetPlanLoadingSuccess = '[Setup] Set Plan Loading Success',
  SetPlanLoadingFailure = '[Setup] Set Plan Loading Failure',
  ColorSchemeLoadSuccess = '[Color Scheme] Color Scheme Load Success',
  ColorSchemeLoadFailure = '[Color Scheme] Color Scheme Templates Load Failure',
  ColorSchemeLoadPending = '[Color Scheme] Color Scheme Templates Load Pending',
  ColorSchemeReset = '[Color Scheme] Color Scheme Reset',
  GetEndPagesLoadingSuccess = '[Setup] Required Pages Loading Success',
  GetEndPagesLoadingFailure = '[Setup] Required Pages Loading Failure',
  GetEndPagesLoadingPending = '[Setup] Required Pages Loading Pending',
  SetSingleViewTabs = '[Setup] Set Single View Tabs',
  GetSalesConceptsLoadingPending = '[Setup] Sales Concepts Loading Pending',
  GetSalesConceptsLoadingSuccess = '[Setup] Sales Concepts Loading Success',
  SalesConceptModalRequestsPending = '[Setup] Sales Concepts Modal Requests Pending',
  SalesConceptModalRequestsSuccess = '[Setup] Sales Concepts Modal Requests Success',
  GetSalesConceptsCountLoadingSuccess = '[Setup] Sales Concepts Count Loading Success',
  GetSalesConceptLoadingSuccess = '[Setup] Sales Concept Loading Success',
  GetSalesConceptsLoadingFailure = '[Setup] Sales Concepts Loading Failure',
  GetEndPageLoadingSuccess = '[Setup] Required Page Loading Success',
  SalesConceptsModalAvailable = '[Setup] Sales Concepts Modal Available',
  ResetEndPages = '[Setup] Required Pages Reset',
  ResetDisclosure = '[Setup] ResetDisclosure Reset',
  ResetSalesConcepts = '[Setup] Sales Concepts Reset',
  GetDependentPagesLoadingSuccess = '[Setup] Dependent Pages Loading Success',
  GetSalesConceptDependentPagesLoadingSuccess = '[Setup] Sales Concept Dependent Pages Loading Success',
  GetDependentPageLoadingSuccess = '[Setup] Dependent Page Loading Success',
  GetDependentPagesLoadingFailure = '[Setup] Dependent Pages Loading Failure',
  GetDependentPagesLoadingPending = '[Setup] Dependent Pages Loading Pending',
  ResetDependentPages = '[Setup] Dependent Pages Reset',
  GetDependentPagesConfigLoadingSuccess = '[Setup] Dependent Pages Config Loading Success',
  GetDependentPagesConfigLoadingFailure = '[Setup] Dependent Pages Config Loading Failure',
  GetDependentPagesConfigLoadingPending = '[Setup] Dependent Pages Config Loading Pending',
  ResetDependentPagesConfig = '[Setup] Dependent Pages Config Reset',
  SetSetupLoading = '[Setup] Set Setup Loading',
  SetPinValueOnEachChartInsert = '[Setup - Dependent Pages Config] Set chart pin value for each chart insert',
  UpdatePinValueOnEachChartInsert = '[Setup - Dependent Pages Config] Update chart pin value for each chart insert',
  GetNavbarCustomPagesLoadingSuccess = '[Setup] Navbar Custom Pages Loading Success',
  GetNavbarCustomPagesLoadingFailure = '[Setup] Navbar Custom Pages Loading Failure',
  GetNavbarCustomPagesLoadingPending = '[Setup] Navbar Custom Pages Loading Pending',
  ResetNavbarCustomPages = '[Setup] Reset navbar custom pages',
}

export const disclosureText =
  'Information regarding the products described herein is believed to be accurate at the time' +
  ' of publication but is not guaranteed. Clients should verify the accuracy of guaranteed and' +
  ' non-guaranteed illustration values by reviewing a current compliant illustration with their' +
  ' insurance agent prior to making an insurance policy decision. Ensight and its subsidiaries' +
  ' do not provide tax, legal advice or policy recommendations. Clients are encouraged to consult' +
  ' with their legal and tax professionals prior to entering into insurance transactions. All product' +
  ' names, brands and logos are the property of their respective owners.';

export const Y_DISCLOSURE_TEXT = ['Chart percentage limit was set to ', '% at the bottom', ' and ', '% at the top'];
