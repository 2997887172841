import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { ChartAPI } from 'c3';
import { selectAll, select } from 'd3';

import {
  C3_DEFAULT_OPTIONS,
  DEFAULT_BAR_OPACITY_VALUE,
  DEFAULT_OPACITY_VALUE,
  defaultXAxisTickColor,
  Y2_AXIS_LABEL_WIDTH,
  Y_AXIS_LABEL_WIDTH,
  DEFAULT_PADDING_IN_VW,
} from '../chart.constants';
import { C3AdapterService } from './c3-chart.adapter.service';
import { ChartData, ChartOptions, ChartLegend, LegendPosition } from '@core/model';
import { ChartService } from '@shared/components/chart/chart.service';
import { WINDOW_TOKEN } from '@core/constant';
import { isEqual } from 'lodash-es';
import { ChartTypes } from '@core/enums';

@Component({
  selector: 'ep-c3-chart',
  templateUrl: './c3-chart.component.html',
  styleUrls: ['./c3-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [C3AdapterService],
})
export class C3ChartComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() data: ChartData[];
  @Input() options: ChartOptions;

  // TODO: need to change - rename!!!!
  @Output() click = new EventEmitter();
  @Output() mouseup = new EventEmitter();
  @Output() mousemove = new EventEmitter();
  @Output() mouseover = new EventEmitter();
  @Output() mouseout = new EventEmitter();
  @Output() touchstart = new EventEmitter();
  @Output() touchend = new EventEmitter();
  @Output() onrendered = new EventEmitter();
  @Output() onMovePinByClick = new EventEmitter();

  @ViewChild('c3Chart') private c3ChartRef: ElementRef<HTMLElement>;

  @HostListener('window:resize', ['$event']) onResize() {
    // Don't rerender chart on PDF page https://assuranceapp.atlassian.net/browse/DAT-8208
    const currentWidth = this.window.innerWidth;

    if (!this.options.isPDF && this.initialWidth !== currentWidth) {
      this.initialWidth = currentWidth;
      this.chartC3?.internal.isResizing(true);
      this.chartC3?.resize();
    }
  }

  chartC3: ChartAPI;
  legendData: ChartLegend[] = [];
  legendPosition: LegendPosition;
  isSecondLineDescriptionLegend: boolean;
  shareableLink: string;
  isNoData = false;
  isValueTooltipHide = false;
  isPieOrDonutChart = false;
  isClusteredBarChart = false;
  isSinglePolicyOrRTEOrCharges = false;

  private initialWidth: number;
  private _data: ChartData[];

  constructor(
    private c3AdapterService: C3AdapterService,
    private cdr: ChangeDetectorRef,
    private chartService: ChartService,
    @Inject(WINDOW_TOKEN) private window: Window
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.options) {
      this.prepareData();

      // Temporary solution to update standard pages when xAxisSource and its data change
      const wasValuesChanged = !isEqual(
        changes.data?.currentValue[0]?.values,
        changes.data?.previousValue?.[0]?.values
      );

      if (wasValuesChanged && this.chartC3 && !this.isNoData) {
        this.generateChart();
      }
    }
  }

  ngOnInit(): void {
    this.isPieOrDonutChart = this.c3AdapterService.isPieOrDonutChart(this.options);
    this.isClusteredBarChart = this.c3AdapterService.isClusteredBarChart(this.options);
    this.isValueTooltipHide = this.c3AdapterService.isTooltipValueHide(this.options);
    this.prepareData();
    this.initialWidth = this.window.innerWidth;

    this.isSinglePolicyOrRTEOrCharges =
      this.options.id.includes('single_policy') ||
      this.options.id.includes('custom_visualization') ||
      this.options.id.includes('charges-total_charges') ||
      this.options.id.includes('retirement_shortfall');

    this.shareableLink = this.chartService.getShareableLink();
  }

  ngAfterViewInit(): void {
    if (!this.isNoData) {
      this.generateChart();
      this.generateCustomLegend();
    } else if (this.options.isPDF && this._data.length === 1 && this._data[0]?.isEmptyPlan) {
      this.onrendered.emit();
    }
  }

  focusOnChartDataById(id: string): void {
    this.chartC3.focus(id);
    this.toggleLegendDimming(id);
  }

  resetFocusOnChartData(): void {
    this.chartC3.revert();
    this.toggleLegendDimming();
  }

  private toggleLegendDimming(id?: string): void {
    this.legendData.forEach(item => {
      item.dimmed = id ? item.id !== id : false;
    });
    this.cdr.detectChanges();
  }

  private generateChart(): void {
    const x: ['x', ...number[]] = this.c3AdapterService.getC3X(this._data);
    const { types, groups, data } = this.c3AdapterService.getC3Data(this._data, x, this.options.chart.type);
    const colors = this.c3AdapterService.getC3Colors(this._data, this.options);
    const type = this.c3AdapterService.getC3StepType(this._data);
    const options = this.c3AdapterService.getOptions(this.options, this._data);
    const donut = this.c3AdapterService.getDonutOptions(this.options);
    const bar = this.c3AdapterService.getBarChartOptions(this.options, this.data);
    const tooltip = this.c3AdapterService.getTooltips(this.options, this._data);
    const pie = this.c3AdapterService.getPieOptions(this.options);
    const areaX: [] = x.slice(1, x.length) as unknown as [];
    const areaConfig = this.c3AdapterService.getChartAreaBetweenMetricsConfig(this._data, areaX);
    const chartId = 'c3Chart-' + this.options.id;
    const axes = this.c3AdapterService.getAxes(this._data, this.options);
    // We need to filter nulls to prevent chart corruption https://assuranceapp.atlassian.net/browse/DAT-9089
    const chartValues = this.c3AdapterService.replaceNullValues(data);
    let xChart: ['x', ...(number | string)[]] = x;

    if (this.isClusteredBarChart) {
      xChart = ['x', ...this._data.map(item => item.metricTitle)];
    }

    this.chartC3 = this.c3AdapterService.getChart({
      ...C3_DEFAULT_OPTIONS,
      ...options,
      bindto: this.c3ChartRef.nativeElement,
      padding: {
        left:
          this.isClusteredBarChart && !this.options.chart.rotatedAxes ? 0 : this.calculateVwToPx(DEFAULT_PADDING_IN_VW),
      },
      data: {
        ...C3_DEFAULT_OPTIONS.data,
        columns: [xChart, ...chartValues],
        types,
        colors,
        color: this.c3AdapterService.getChartColor(this.options.chart.type, Object.values(colors)),
        groups,
        axes,
        labels: this.c3AdapterService.getBarLabels(this.options, this._data),
        onmouseover: item => {
          if (this.isPieOrDonutChart) {
            this.focusOnChartDataById(item.id);
          }
        },
        onmouseout: () => {
          if (this.isPieOrDonutChart) {
            this.resetFocusOnChartData();
          }
        },
      },
      ...pie,
      ...donut,
      ...bar,
      ...tooltip,
      oninit: function () {
        this.config.bindto.setAttribute('id', chartId);
      },
      onrendered: () => {
        setTimeout(() => {
          this.setEvents();

          if (areaConfig?.json) {
            this.c3AdapterService.fillAreaBetweenMetrics(this.chartC3, areaConfig, chartId);
          }

          if (!this.isPieOrDonutChart) {
            this.applyMetricOpacities();
          }

          this.c3AdapterService.applyChartFontSizeAxesLabels(this.options);

          if (this.data.some(item => item.dashedMetric)) {
            this.c3AdapterService.setStrokeDasharray(this.options, this.data, Object.keys(types));
          }

          if (this.isClusteredBarChart) {
            this.c3AdapterService.addSpaceBetweenTspansLabel(this.options.id);
          }

          if (this.options.chart.type === ChartTypes.comboChart) {
            this.adjustComboChartLayout();

            const xAxisYearHighlight = this.options.chart.xAxisYearHighlight?.trim();

            if (xAxisYearHighlight !== '' && !isNaN(Number(xAxisYearHighlight))) {
              this.highlightXAxisTick();
            }
          }

          if (this.options.id === 'custom_visualization') {
            // Need to equalize c3-rects to set pin https://assuranceapp.atlassian.net/browse/DAT-9295
            this.equalizeEventRectWidths();
          }

          this.onrendered.emit();
        }, 0);
      },
      line: {
        step: {
          type,
        },
      },
    });

    this.overrideInternalRedraw();
  }

  private calculateVwToPx(vw: number): number {
    return (this.window.innerWidth * vw) / 100;
  }

  private equalizeEventRectWidths(): void {
    const { internal } = this.chartC3;
    const eventRects = internal.main.selectAll('.c3-event-rects .c3-event-rect');
    const equalWidth = internal.width / eventRects.size();

    eventRects.attr('width', equalWidth).attr('x', (_, i) => i * equalWidth);
    internal.eventRect.attr('width', equalWidth).attr('x', (_, i) => i * equalWidth);
  }

  private adjustComboChartLayout(): void {
    const yAxisLabel = this.c3ChartRef.nativeElement.querySelector('.c3-axis-y-label');
    const y2AxisLabel = this.c3ChartRef.nativeElement.querySelector('.c3-axis-y2-label');
    const yAxisTicks = this.c3ChartRef.nativeElement.querySelectorAll('.c3-axis-y g.tick text');
    const y2AxisTicks = this.c3ChartRef.nativeElement.querySelectorAll('.c3-axis-y2 g.tick text');
    const tickWidth =
      Math.max(...Array.from(yAxisTicks).map(tick => tick.getBoundingClientRect().width)) + Y_AXIS_LABEL_WIDTH;
    const tick2Width =
      Math.max(...Array.from(y2AxisTicks).map(tick => tick.getBoundingClientRect().width)) + Y2_AXIS_LABEL_WIDTH;

    if (yAxisLabel.innerHTML && tickWidth > 0) {
      yAxisLabel.setAttribute('dy', `-${tickWidth}`);
      y2AxisLabel.setAttribute('dy', `${tick2Width}`);
    }

    const svgChart = this.c3ChartRef.nativeElement.querySelector('svg');
    const gChart = svgChart.querySelector('g');
    const bbox = gChart.getBBox();
    const x = (svgChart.width.baseVal.value - bbox.width) / 2 - bbox.x;
    const y = (svgChart.height.baseVal.value - bbox.height) / 2 - bbox.y;
    gChart.setAttribute('transform', `translate(${x},${y})`);
  }

  private highlightXAxisTick(): void {
    const texts = selectAll(`#c3Chart-${this.options.id} text`);
    const tickColor = this.options.chart.xAxisYearHighlightColor || defaultXAxisTickColor;

    texts.each((_, i, nodes) => {
      const element = nodes[i] as HTMLElement;
      const tspans = element.querySelectorAll('tspan');

      tspans.forEach(tspan => {
        if (Number(tspan.textContent) === Number(this.options.chart.xAxisYearHighlight)) {
          tspan.style.fill = tickColor;
          tspan.style.fontWeight = '700';
          tspan.style.outline = `2px solid ${tickColor}`;
          tspan.style.outlineOffset = `2px`;

          (element as HTMLElement).style.display = 'block';
        }
      });
    });
  }

  private generateCustomLegend(): void {
    if (this.isPieOrDonutChart) {
      const { legendData, position } = this.c3AdapterService.generateLegend(this.chartC3, this._data, this.options);
      this.legendData = legendData;
      this.legendPosition = position;
      this.isSecondLineDescriptionLegend = this.legendData.some(item => item.description?.secondLineDescription);
    }
  }

  private applyMetricOpacities(): void {
    const chart = select(this.c3ChartRef.nativeElement);

    this._data.forEach(metric => {
      const colorHex = metric.color.trim();
      const opacityValue = metric.opacity ? metric.opacity / 100 : DEFAULT_OPACITY_VALUE;

      const fillColor = this.c3AdapterService.hexToRgba(colorHex);
      chart.selectAll(`.c3-area-data${metric.order}`).style('fill', fillColor).style('opacity', opacityValue);
      chart.selectAll(`path.area-between`).style('opacity', opacityValue);

      if (this.isClusteredBarChart) {
        chart.selectAll(`.c3-bar-${metric.order}`).style('fill-opacity', DEFAULT_BAR_OPACITY_VALUE);
      }
    });
  }

  private overrideInternalRedraw(): void {
    const originalRedraw = this.chartC3?.internal.redraw;
    let isResizing = false;

    this.chartC3.internal.redraw = function (options?: any) {
      if (isResizing) {
        return originalRedraw?.call(this, options);
      }

      return false;
    };

    this.chartC3.internal.isResizing = (value: boolean) => {
      isResizing = value;
    };
  }

  private setEvents(): void {
    // @ts-ignore
    this.chartC3.element.onclick = this.handleEvent.bind(this, this.click);
    // @ts-ignore
    this.chartC3.element.onmouseup = this.handleEvent.bind(this, this.mouseup);
    // @ts-ignore
    this.chartC3.element.onmousemove = this.handleEvent.bind(this, this.mousemove);
    // @ts-ignore
    this.chartC3.element.onmouseover = this.handleEvent.bind(this, this.mouseover);
    // @ts-ignore
    this.chartC3.element.onmouseout = this.handleEvent.bind(this, this.mouseout);
    // @ts-ignore
    this.chartC3.element.ontouchstart = this.handleEvent.bind(this, this.touchstart);
    // @ts-ignore
    this.chartC3.element.ontouchend = this.handleEvent.bind(this, this.touchend);

    this.chartC3.element.onclick = this.handleChartClick.bind(this);
  }

  private handleChartClick(event: MouseEvent): void {
    this.onMovePinByClick.emit(event);
  }

  private handleEvent(emitter: EventEmitter<HTMLElement>, e: PointerEvent & { path: HTMLElement[] }): void {
    e.stopPropagation();
    e?.path && emitter.emit(e.path[0]);
  }

  private prepareData(): void {
    this._data = this.isPieOrDonutChart ? this.c3AdapterService.getFilterData(this.data) : this.data;
    const isSingleEmptyPlan = this._data.length === 1 && this._data[0]?.isEmptyPlan;

    this.isNoData = this._data.length === 0 || isSingleEmptyPlan;
  }
}
