<div class="aapp-login-container">
  <div class="container">
    <div class="row justify-content-center">
      <h3 class="title col-12">Organization</h3>
      <div class="col-lg-6 col-sm-12">
        @if ((organizationsSwitchPending$ | async) === false) {
          <um-organizations-switch
            [response]="response$ | async"
            [switch]="organizationsSwitch"
            (submitForm)="submitForm($event)"
            (cancelForm)="cancelForm()"
          ></um-organizations-switch>
        }
        @if (organizationsSwitchPending$ | async) {
          <se-circle-loader
            type="ellipsis-pulse-l"
          ></se-circle-loader>
        }
      </div>
    </div>
  </div>
</div>
