<div class="modal-body-wrapper">
  @switch (placeholder?.insertType) {
    @case (insertTypeIds.text) {
      <div class="control-container">
        <se-textarea
          [formControl]="valueControl"
          name="value"
          [height]="70"
          resize="none"
        ></se-textarea>
        @for (message of getErrorMessages(valueControl.errors); track message) {
          <span
            class="error-message"
            >
            {{ message }}
          </span>
        }
      </div>
    }
    @case (insertTypeIds.variable) {
      <div class="control-container">
        <se-form-input
          [inputPattern]="inputPattern"
          [formControl]="valueControl"
        ></se-form-input>
        @for (message of getErrorMessages(valueControl.errors); track message) {
          <span
            class="error-message"
            >
            {{ message }}
          </span>
        }
      </div>
    }
    @case (insertTypeIds.image) {
      <ep-images-preview
        [imagesList]="imagesList"
        [hideDelete]="true"
        (selectImage)="onSelectImage($event)"
        (loadedImage)="onLoadedImage($event)"
      ></ep-images-preview>
    }
  }
</div>

<div class="btn-block">
  <ac-button class="secondary" (click)="cancel()">Cancel</ac-button>
  <ac-button
    [disabled]="
      !valueControl?.valid && placeholder?.insertType !== insertTypeIds.image
    "
    [loading]="loading"
    (click)="update()"
    >
    Update
  </ac-button>
</div>
