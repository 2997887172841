@if (loading) {
  <div class="spinner-holder">
    <ac-loader></ac-loader>
  </div>
}
<div class="page-settings-container">
  <ensight-custom-page-header
    [isEditMode]="editMode"
    [title]="editMode ? 'Edit Custom Page' : 'New Custom Page'"
    [showSaveBtn]="true"
    [disableSaveBtn]="
      customPageForm?.invalid ||
      loading ||
      dependentPagesLoading ||
      (editMode && isSaveDisabled && !isHTMLBodyChanged)
    "
    [loading]="loading || dependentPagesLoading"
    (onClose)="closeSettings()"
    (onSave)="savePage()"
    [addNoteControl]="note"
  ></ensight-custom-page-header>

  <div class="page-settings-content">
    @if (customPageForm) {
      <form class="custom-page-form" data-at-id="custom-page-form" [formGroup]="customPageForm">
        <section class="content-section" data-at-id="custom-page-form-name">
          <label class="label">Name</label>
          <input class="name-control" formControlName="name" maxlength="100" />
        </section>
        @if (editMode && isShowVersions) {
          <section class="content-section">
            <label class="label">Version Name</label>
            <ng-select
              class="ng-select-version"
              bindLabel="versionName"
              bindValue="pageId"
              formControlName="versionId"
              [multiple]="false"
              [items]="customPageVersions.versions"
              [clearable]="false"
              [searchable]="false"
              [placeholder]="'Select Version'"
            ></ng-select>
          </section>
        }
        <section class="content-section" data-at-id="custom-page-form-label">
          <label class="label">Page Label</label>
          <input class="name-control" formControlName="label" maxlength="100" />
        </section>
        @if (salesConceptSettingsShown) {
          <section class="content-section" data-at-id="custom-page-form-sales-concept-label">
            <label class="label">Sales Concept Label</label>
            <input class="name-control" formControlName="salesConceptLabel" maxlength="100"/>
          </section>
        }
        <section class="content-section">
          <label class="label">Layout</label>
          <se-button-group [buttons]="layoutButtons" size="medium" (onSelectButton)="onSelectLayout($event)"></se-button-group>
        </section>
        <section class="content-section body-section">
          <label class="label">Body</label>
          <ensight-tiny-editor
            controlName="htmlBody"
            [form]="customPageForm"
            class="body-control"
            data-at-id="custom-page-form-editor"
            [customInitConfig]="editorConfig"
          ></ensight-tiny-editor>
        </section>
        <section class="content-section">
          <ep-custom-page-presentation-compile
            [customPage]="pageOptions"
            [customPageVersions]="customPageVersions">
          </ep-custom-page-presentation-compile>
        </section>
        <ep-custom-page-inserts
          (openPlaceholdersWizardModal)="editPlaceholder($event)"
          (createPlaceholder)="addPlaceholder($event)"
          (deletePlaceholder)="deletePlaceholder($event)"
          (isSaveDisabled)="setIsSaveDisabled($event)">
        </ep-custom-page-inserts>
        @if (!editMode) {
          <section class="content-section page-type-section" data-at-id="custom-page-form-type">
            <label class="label">Page Type</label>
            <div class="custom-page-types" formArrayName="customPageType">
              @for (type of customPageType.controls; track type; let i = $index) {
                <se-radio-button
                  [label]="pageSettingsTypes[i].label"
                  [checked]="pageSettingsTypes[i].selected"
                  [value]="pageSettingsTypes[i].id"
                  (onSelect)="onCustomPageTypeSelect(pageSettingsTypes[i])"
                ></se-radio-button>
              }
              @if (customPageType.invalid) {
                <span class="error-message">
                  At least one option should be selected
                </span>
              }
            </div>
          </section>
        }
        @if (editMode && listOfSalesConceptsForDependentPage.length) {
          <section
            class="content-section custom-pages-list-section"
            data-at-id="custom-page-used-in-sales-concepts"
            >
            <label class="label">Used in Sales Concepts</label>
            <div class="custom-page-types">
              <ul class="list-of-custom-pages">
                @for (item of listOfSalesConceptsForDependentPage; track item; let i = $index) {
                  <li class="custom-page-item">
                    <a href="{{ CUSTOM_PAGES_LIST_URL }}/{{ item._id }}/edit" target="_blank">
                      <span class="custom-page-name">
                        {{ i + 1 }}. {{ item.name }}
                      </span>
                      <span class="open-icon"></span>
                    </a>
                  </li>
                }
              </ul>
            </div>
          </section>
        }
        <section class="content-section">
          <label class="label">Bulk edit for variable inserts</label>
          <se-toggler formControlName="isBulkEditOfVariables"></se-toggler>
        </section>
        @if (selectedPageType === pageTypes.requiredpage && endPageFormSettings) {
          <ng-container [formGroup]="endPageFormSettings">
            <section [formGroup]="endPageFormSettings" class="content-section">
              <label class="label">Ordering rank (smallest at the top)</label>
              <se-form-input formControlName="orderRank"></se-form-input>
            </section>
            <section class="content-section">
              <label class="label">Page location</label>
              <ng-select
                class="ng-select-location"
                bindLabel="label"
                bindValue="value"
                formControlName="isPageAtStartOfPresentation"
                [multiple]="false"
                [items]="pageLocations"
                [clearable]="false"
                [searchable]="false"
                [placeholder]="'Select page location'"
              ></ng-select>
            </section>
            <section class="content-section">
              <label class="label">Eligible for particular product types</label>
              <se-toggler
                [checked]="eligibleProductsActive"
                formControlName="eligibleProductsActive"
                (onChange)="toggleProductsSection(endPageFormSettings)"
              ></se-toggler>
            </section>
            @if (eligibleProductsActive) {
              <section class="content-section" formArrayName="eligibleProductTypes">
                <label class="label"></label>
                <div class="wrap-checkbox">
                  @for (type of productTypes; track type; let i = $index) {
                    <div class="product-checkbox">
                      <ac-control
                        type="checkbox"
                        [label]="type.label"
                        [formControlName]="i"
                        (toggle)="handleProductTypeSelection(endPageFormSettings)"
                      ></ac-control>
                    </div>
                  }
                </div>
              </section>
            }
          </ng-container>
        }
        @if (salesConceptSettingsShown && salesConceptSettingsForm) {
          <ng-container [formGroup]="salesConceptSettingsForm">
            <section class="content-section">
              <label class="label">Eligible for particular data sources</label>
              <se-toggler
                [checked]="eligibleDataSources"
                formControlName="eligibleDataSources"
                (onChange)="toggleDataSourcesSection()"
              ></se-toggler>
            </section>
            @if (eligibleDataSources) {
              <section class="data-sources" formArrayName="eligibleDataSourcesConfig">
                @for (dataSource of dataSourcesConfig; track dataSource; let i = $index) {
                  <div class="product-checkbox">
                    <se-checkbox
                      [label]="dataSource.label"
                      [bold]="true"
                      [formControlName]="i"
                      [checked]="dataSource.selected"
                      (onToggle)="handleDataSourceSelection()"
                    ></se-checkbox>
                  </div>
                }
              </section>
            }
            @if (eligibleDataSources) {
              <section class="content-section error-statement">
                <div class="error-statement-row">
                  <label class="label">
                    Error statement for unsupported data sources
                  </label>
                  <se-textarea
                    formControlName="unsupportedDataSourcesErrorMessage"
                    name="unsupportedDataSourcesErrorMessage"
                    [height]="100"
                    (keyup.enter)="$event.stopPropagation()"
                  ></se-textarea>
                </div>
              </section>
            }
            <section class="content-section">
              <label class="label">Eligible for particular product types</label>
              <se-toggler
                [checked]="eligibleProductsActive"
                formControlName="eligibleProductsActive"
                (onChange)="toggleProductsSection(salesConceptSettingsForm)"
              ></se-toggler>
            </section>
            @if (eligibleProductsActive) {
              <section class="product-types" formArrayName="eligibleProductTypes">
                @for (type of productTypes; track type; let i = $index) {
                  <div class="product-checkbox">
                    <se-checkbox
                      [label]="type.label"
                      [bold]="true"
                      [formControlName]="i"
                      [checked]="type.selected"
                      (onToggle)="handleProductTypeSelection(salesConceptSettingsForm)"
                    ></se-checkbox>
                  </div>
                }
              </section>
            }
            <section class="content-section">
              <label class="label">Dependent Pages Locked</label>
              <se-toggler formControlName="dependentPagesLocked"></se-toggler>
            </section>
            <section class="content-section">
              <label class="label">Locked and always enabled page</label>
              <se-toggler formControlName="isPageLockedAndEnabled"></se-toggler>
            </section>
            <section class="content-section">
              <label class="label">Eligible for particular carriers</label>
              <se-toggler formControlName="isEligibleForCarriers"></se-toggler>
            </section>
            <section class="content-section">
              <label class="label">Dependent Pages</label>
              <div class="dependent-pages-list">
                @if (!selectedDependentPages.length) {
                  <ul class="dependent-pages-list-wrapper no-data">
                    <li class="dependent-pages-item">No pages selected</li>
                  </ul>
                }
                @if (selectedDependentPages.length) {
                  <ol class="dependent-pages-list-wrapper">
                    @for (dependentPage of selectedDependentPages | sortBy: 'asc':'order'; track dependentPage) {
                      <li class="dependent-pages-item">{{ dependentPage.label }}</li>
                    }
                  </ol>
                }
                <se-button
                  [disabled]="!dependentPages?.length"
                  [loading]="dependentPagesLoading"
                  (click)="openDependentPagesModal()"
                  class="secondary"
                  >
                  Configure
                </se-button>
              </div>
            </section>
            <section class="content-section" data-at-id="custom-page-form-products-min">
              <label class="label">Products Min</label>
              <div class="products-counter">
                <ac-counter
                  formControlName="productsMin"
                  [counterRangeMax]="maxRangeForProductsMin"
                  [counterRangeMin]="productsQuantityValues.min"
                ></ac-counter>
              </div>
            </section>
            <section class="content-section" data-at-id="custom-page-form-products-max">
              <label class="label">Products Max</label>
              <div class="products-counter">
                <ac-counter
                  formControlName="productsMax"
                  [counterRangeMax]="productsQuantityValues.max"
                  [counterRangeMin]="minRangeForProductMax"
                ></ac-counter>
              </div>
            </section>
            @if (minRangeForProductMax > 1) {
              <section class="content-section textarea-container">
                <div class="textarea-row">
                  <label class="label">
                    Error statement for the insufficient number of products
                  </label>
                  <se-textarea
                    formControlName="insufficientProductsNumberErrorMessage"
                    name="insufficientProductsNumberErrorMessage"
                    [height]="100"
                    (keyup.enter)="$event.stopPropagation()"
                  ></se-textarea>
                </div>
              </section>
            }
            @if (maxRangeForProductsMin > 1) {
              <section class="content-section textarea-container">
                <div class="textarea-row">
                  <label class="label">Products Order Formula</label>
                  <se-textarea
                    formControlName="productsOrderFormula"
                    name="productsOrderFormula"
                    [height]="100"
                    (keyup.enter)="$event.stopPropagation()"
                  ></se-textarea>
                </div>
                <div class="formula-hint">{{ hintForProductsOrderFormula }}</div>
              </section>
            }
            <section class="content-section">
              <label class="label">Sales Concept Description</label>
              <se-textarea
                formControlName="description"
                name="description"
                [height]="100"
                resize="none"
                [maxLength]="1000"
              ></se-textarea>
            </section>
            <section class="content-section">
              <se-file-uploader
                [config]="previewUploadConfig"
                (onUpload)="addPreviewImage($event)"
                (onRemove)="removePreviewImage()"
              ></se-file-uploader>
            </section>
          </ng-container>
        }
        @if (dependentPageFormShown && dependentPageFormSettings) {
          <ng-container [formGroup]="dependentPageFormSettings">
            <section class="content-section">
              <label class="label">Turned off by default on the presentation</label>
              <se-toggler formControlName="disabled"></se-toggler>
            </section>
            <section class="content-section">
              <label class="label">Locked and always enabled page</label>
              <se-toggler formControlName="isPageLockedAndEnabled"></se-toggler>
            </section>
            <section class="content-section">
              <label class="label">Page Display Conditionality (0 to hide)</label>
              <se-textarea
                formControlName="showHideFormula"
                name="showHideFormula"
                [height]="100"
                resize="none"
              ></se-textarea>
            </section>
            <section class="content-section textarea-container">
              <div class="textarea-row">
                <label class="label">Products Order Formula</label>
                <se-textarea
                  formControlName="productsOrderFormula"
                  name="productsOrderFormula"
                  [height]="100"
                  resize="none"
                ></se-textarea>
              </div>
              <div class="formula-hint">{{ hintForProductsOrderFormula }}</div>
            </section>
          </ng-container>
        }
        @if (showCarriers) {
          <section class="content-section carriers-section" data-at-id="custom-page-form-carriers">
            <label class="label">Show to Carriers({{ selectedCarriers }})</label>
            <div class="carriers-list" data-at-id="custom-page-form-carriers-list" formArrayName="pageCarriers">
              @for (carrier of pageCarriers.controls; track carrier; let i = $index) {
                <ac-control
                  type="checkbox"
                  [label]="carriers[i].name"
                  [formControlName]="i"
                  (toggle)="onCarrierSelect()"
                ></ac-control>
              }
            </div>
          </section>
        }
        @if (selectedPageType === pageTypes.requiredpage && endPageFormSettings) {
          <ng-container [formGroup]="endPageFormSettings">
            <section class="content-section">
              <label class="label">Hide on Shareable Link</label>
              <se-toggler formControlName="hideOnShareableLink"></se-toggler>
            </section>
            <section class="content-section">
              <label class="label">Hide on PDF</label>
              <se-toggler formControlName="hideOnPDF"></se-toggler>
            </section>
          </ng-container>
        }
      </form>
    }
  </div>
</div>
