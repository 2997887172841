import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { get } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AlertService } from '@se/common';

import { CustomPage, CustomPageVersion, CustomPageVersions, Insert, MappedPresentation } from '@shared/models';
import { CompilationResultMessages, CUSTOM_PAGE_SETTINGS_PRODUCTS_QUANTITY } from '../../constants';
import * as PlaceholdersWizardActions from '../../redux/placeholders-wizard.actions';
import * as PlaceholdersWizardSelectors from '../../redux/placeholders-wizard.selectors';
import { Global } from '@shared/services';
import { AppState } from '../../../../reducers';
import { CustomPagePreviewService, ModalProviderService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'ep-custom-page-presentation-compile',
  templateUrl: './custom-page-presentation-compile.component.html',
  styleUrls: ['./custom-page-presentation-compile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPagePresentationCompileComponent implements OnInit {
  @Input() customPage: CustomPage;
  @Input() customPageVersions: CustomPageVersions;

  selectedPresentation: MappedPresentation;
  compilationPreviewInProgress = false;
  productsQuantityValues = CUSTOM_PAGE_SETTINGS_PRODUCTS_QUANTITY;
  getPlaceholders$ = this.store.pipe(
    select(PlaceholdersWizardSelectors.placeholdersMetadata),
    map(placeholders => placeholders.filter(placeholder => !placeholder.delete))
  );

  constructor(
    private store: Store<AppState>,
    private global: Global,
    private customPagePreviewService: CustomPagePreviewService,
    private modalProvider: ModalProviderService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.selectedPresentation = this.customPagePreviewService.getPresentation;
  }

  onChoosePresentationModal(): void {
    const productsMin = get(this, 'pageOptions.customFields.productsMin') || this.productsQuantityValues.min;
    const modal = this.modalProvider.openChoosePresentationModal(productsMin);

    modal.afterClosed
      .pipe(
        untilDestroyed(this),
        filter((result: MappedPresentation) => !!result)
      )
      .subscribe((presentation: MappedPresentation) => {
        this.customPagePreviewService.setPresentation = presentation;
        this.global.setPresentation = presentation;
        this.selectedPresentation = presentation;
        this.cdr.markForCheck();
      });
  }

  onClearPresentation(): void {
    this.selectedPresentation = null;
    this.customPagePreviewService.clearPresentationAndConfig();
  }

  onCompileInserts(): void {
    if (this.customPagePreviewService.getPresentation) {
      const currentVersion = this.customPageVersions?.versions.find(
        (version: CustomPageVersion) => version.pageId === this.customPage._id
      );
      this.compilationPreviewInProgress = true;
      this.clearPlaceholderErrors();

      this.customPagePreviewService
        .compilePreviewInserts(this.selectedPresentation.id, currentVersion.startDate, this.customPage)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (customPage: CustomPage) => {
            const errorsFound = customPage.inserts.some(insert => insert.errorMessage);
            this.alertService.openAlert({
              type: errorsFound ? 'error' : 'success',
              body: errorsFound ? CompilationResultMessages.failed : CompilationResultMessages.success,
              autoClose: 5000,
            });

            if (errorsFound) {
              this.updatePlaceholdersWithErrors(customPage.inserts);
            }

            this.compilationPreviewInProgress = false;
            this.cdr.markForCheck();
          },
        });
    } else {
      this.alertService.openAlert({
        type: 'warning',
        body: 'This presentation is no longer available',
        autoClose: 5000,
      });
      this.selectedPresentation = this.customPagePreviewService.getPresentation;
      this.cdr.markForCheck();
    }
  }

  private updatePlaceholdersWithErrors(inserts: Insert[]): void {
    this.getPlaceholders$.pipe(take(1)).subscribe(placeholders => {
      const updatedPlaceholders = placeholders.map(placeholder => {
        const insert = inserts.find(item => item.metadata.id === placeholder.id);

        if (insert?.errorMessage) {
          return { ...placeholder, errorMessage: insert.errorMessage };
        }

        return placeholder;
      });

      this.store.dispatch(PlaceholdersWizardActions.setPlaceholdersDataSuccess({ metadata: updatedPlaceholders }));
    });
  }

  private clearPlaceholderErrors(): void {
    this.getPlaceholders$
      .pipe(
        take(1),
        map(placeholders => placeholders.map(placeholder => ({ ...placeholder, errorMessage: undefined })))
      )
      .subscribe(updatedPlaceholders =>
        this.store.dispatch(PlaceholdersWizardActions.setPlaceholdersDataSuccess({ metadata: updatedPlaceholders }))
      );
  }
}
