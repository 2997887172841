import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  PrefixesModule,
  InputModule,
  ButtonModule,
  NotificationModule,
  ModalModule,
  IconModule,
} from '@assurance/bootstrap';

import { ShortfallSettingsModalComponent } from './shortfall-settings-modal.component';
import { ShortfallSettingsModalValidatorComponent } from './shortfall-settings-modal-msg.component';
import { RetirementShortfallService } from '../../setup/retirement-shortfall/retirement-shortfall.service';
import { AssurencePipesModule } from '@shared/pipes/assurence-pipes.module';

@NgModule({
  declarations: [ShortfallSettingsModalComponent, ShortfallSettingsModalValidatorComponent],
  imports: [
    CommonModule,
    PrefixesModule,
    IconModule,
    InputModule,
    ButtonModule,
    NotificationModule,
    ModalModule,
    ReactiveFormsModule,
    AssurencePipesModule,
  ],
  exports: [ShortfallSettingsModalComponent],
  providers: [RetirementShortfallService],
})
export class ShortfallSettingsModalModule {}
