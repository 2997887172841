<nav class="ep-navbar navbar navbar-light sticky-top d-flex">
  @if (showSidebarToggler) {
    <span class="d-flex justify-content-start">
      <span
      class="
        ensight-icon-menu
        ep-menu-btn ep-navbar-btn
        d-flex
        align-items-center
      "
        (click)="toggleSidebar()"
      ></span>
      <span class="divider"></span>
    </span>
  }

  <section class="ep-presentation-metadata trim p-2">
    <span
      class="logo-img aapp-header-logo a-app-logo-img"
      data-at-id="import-illustration-header-logo"
    ></span>
    <span data-at-id="import-illustration-header-title">{{ title }}</span>
  </section>
  <section class="d-flex justify-content-end align-items-center">
    @if (pdfDownload) {
      <div class="ep-action-message action-block">
        <ep-pdf-loading-message
          message="Your PDF is being generated."
        ></ep-pdf-loading-message>
      </div>
    }
    @if (showPdfDownloadBtn) {
      <div class="ep-bt-wrapper d-flex flex-column">
        <button
          [class.disabled]="pdfDownload"
        class="
          pdf-download-btn
          action-block
          d-flex
          justify-content-around
          align-items-center
          mx-auto
        "
          (click)="togglePdf()"
          >
          <span class="ensight-icon-download"></span>
          <span>PDF</span>
        </button>
      </div>
    }
    <span class="divider"></span>
    @if (!hideCloseButton) {
      <span
        (click)="toggleClose()"
      class="
        ensight-icon-close-small
        ep-back-btn ep-navbar-btn
        d-flex
        align-items-center
      "
        data-at-id="import-illustration-header-close"
      ></span>
    }
  </section>
</nav>
