<div class="dropdown-container" #dropdown>
  <button class="btn-dropdown" [ngClass]="{'dropdown-open': dropdownOpen }" type="button" (click)="toggleDropdown()">
    <span class="btn-text">Add Placeholder</span>
    <span class="btn-arrow"></span>
  </button>

  <div class="dropdown-list" [ngClass]="{'dropdown-open': dropdownOpen }">
    @for (item of menuItems; track item) {
      <div class="dropdown-option">
        <span class="dropdown-option-arrow"></span>
        <span class="dropdown-option-text">{{ item.text }}</span>
        <div class="dropdown-list sub-menu">
          @for (subMenuItem of item.subMenu; track subMenuItem) {
            <div class="dropdown-option" (click)="select(subMenuItem)">
              <span class="dropdown-option-text">{{ subMenuItem.text }}</span>
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
