import { createSelector } from '@ngrx/store';
import { cloneDeep, remove, some, uniqBy } from 'lodash-es';

import { CustomPage, FormConfigurations, Insert, Question } from '@shared/models';
import {
  ChartMetricFormValue,
  ChartPlaceholder,
  Placeholder,
  PlaceholdersDynamicFormValue,
  PlanConfigData,
  TextPlaceholder,
  VariablePlaceholder,
} from '@core/model';
import { CALCULATED_DATA_TARGET, ChartTypes, ChartDataSource, INSERT_TYPE } from '@core/enums';
import { AppState } from '../../../reducers';
import {
  CUSTOM_METRIC_TYPE,
  DEFAULT_METRICS_COLORS,
  DYNAMIC_FORM_CHART_METRICS,
  FORM_BY_STATE_ID,
  IMAGE_STATE,
  INSERT_TYPE_STATE,
  METRIC_BASED_CHART_BACKGROUND,
  METRICS_TO_EXCLUDE,
  NO_API_CALL_INSERTS_MSG,
} from '../constants';
import { ConfigurationData, MappedCarrierPlan, PlaceholderWizardState } from '../models';

function getFilteredQuestions(questions: Partial<Question>[], dataSource: string): Partial<Question>[] {
  return questions.filter((question: Partial<Question>) => !question.disableForDataSource?.includes(dataSource));
}

function getFilteredPlans(plansConfigData: PlanConfigData[]): PlanConfigData[] {
  return plansConfigData
    .filter(config => !METRICS_TO_EXCLUDE.includes(config.name))
    .map(config => ({ ...config, hint: null }));
}

function getFieldsToRemove(formValue: any): Record<string, boolean> {
  const isComboChart = formValue.chartType === ChartTypes.comboChart;
  const isAdditionalYaxis = formValue.axes === 'y2';

  return {
    metricTitle: formValue.pinType !== CUSTOM_METRIC_TYPE,
    background: formValue.chartBackground !== METRIC_BASED_CHART_BACKGROUND || isComboChart,
    stepLineType: !isComboChart,
    chartType: !isComboChart,
    hideMetric: !isComboChart || formValue.pinType === 'noPin',
    hideMetricOnChart: !isComboChart,
    dashedMetric: !isComboChart,
    dashLength: !isComboChart,
    dashInterval: !isComboChart,
    stackedMetric: !isComboChart,
    stackedGroup: !isComboChart,
    opacity: !isComboChart,
    yAxis: !isComboChart || !isAdditionalYaxis,
  };
}

function generateDynamicFormsChartMetrics(
  plansConfigData: PlanConfigData[],
  metricsNumber: number,
  placeholder?: Placeholder,
  formValue?: any,
  isDeleteMetric?: boolean,
  configurationData?: any[]
): FormConfigurations[] {
  const dynamicFormsChartMetrics = [];
  const options = getFilteredPlans(plansConfigData);
  const fieldsToRemove = getFieldsToRemove(formValue);
  const isClusteredBarChart = formValue.chartType === ChartTypes.clusteredBarChart;

  for (let metricIndex = 0; metricIndex < metricsNumber; metricIndex++) {
    const chartMetricForm = cloneDeep(DYNAMIC_FORM_CHART_METRICS);

    const questions = getFilteredQuestions(chartMetricForm.groups[0].questions, ChartDataSource.dynamicMetrics);

    remove(questions, item => fieldsToRemove[item.name]);

    questions.forEach(question => {
      let placeholderMetric = placeholder?.metrics.find(
        metric =>
          metric.order === metricIndex &&
          metric.metricType === ChartDataSource.dynamicMetrics &&
          metric.metricSource === formValue.metricDataSource
      );

      if (!placeholderMetric && isDeleteMetric) {
        placeholderMetric = configurationData?.find(item => item.order === metricIndex);
      }

      if (placeholderMetric) {
        const filedValue = placeholderMetric[question.name];

        const defaultValue =
          question.type === 'datalist' ||
          question.name === 'dashLength' ||
          question.name === 'dashInterval' ||
          question.name === 'stackedGroup'
            ? question.value
            : '';

        question.value =
          question.name === 'productIndex' && !isNaN(+filedValue) ? +filedValue + 1 : filedValue || defaultValue;
      }

      if (question.name === 'metricKey') {
        question.options = [...question.options, ...options];
        !metricIndex &&
          question.validationRules.push({
            rule: 'regex',
            value: '^(?!none$).*$',
            message: 'Metric is required',
          });

        question.label = isClusteredBarChart ? 'Metadata Metric' : 'Metric';
      }

      if (question.name === 'color' && !question.value) {
        question.value = DEFAULT_METRICS_COLORS[metricIndex] || null;
      }

      if (question.name === 'metricType') {
        question.value = ChartDataSource.dynamicMetrics;
      }

      if (question.name === 'metricSource') {
        question.value = formValue?.metricDataSource;
      }

      if (question.name === 'metricSource' && isClusteredBarChart) {
        question.value = CALCULATED_DATA_TARGET.metadata;
      }
    });

    chartMetricForm.groups[0].questions = questions;

    dynamicFormsChartMetrics.push(chartMetricForm);
  }

  return dynamicFormsChartMetrics;
}

function generateStaticFormsChartMetrics(
  plansConfigData: PlanConfigData[],
  placeholder: Placeholder,
  formValue?: any
): FormConfigurations[] {
  const options = getFilteredPlans(plansConfigData);
  const chartMetricForm = cloneDeep(DYNAMIC_FORM_CHART_METRICS);
  const questions = getFilteredQuestions(chartMetricForm.groups[0].questions, ChartDataSource.staticMetrics);
  const placeholderMetric = placeholder?.metrics.filter(
    metric => metric.metricType === ChartDataSource.staticMetrics && metric.metricSource === formValue.metricDataSource
  )[0];

  questions.forEach(question => {
    if (placeholderMetric) {
      const filedValue = placeholderMetric[question.name];
      question.value = question.name === 'productIndex' ? (filedValue as number) + 1 : filedValue || question.value;
    }

    if (question.name === 'metricKeyLabels' || question.name === 'metricKeyData') {
      question.options = [...question.options, ...options];
      question.validationRules.push({
        rule: 'required',
        value: 'true',
        message: 'Metric is required',
      });
    }

    if (question.name === 'metricType') {
      question.value = ChartDataSource.staticMetrics;
    }

    if (question.name === 'metricSource') {
      question.value = formValue?.metricDataSource;
    }
  });

  chartMetricForm.groups[0].questions = questions;

  return [chartMetricForm];
}

function setValuesForChartConfigurationForm(
  placeholder: TextPlaceholder | ChartPlaceholder | PlaceholdersDynamicFormValue,
  configurationForm: FormConfigurations,
  valuesField: string
): FormConfigurations {
  if (placeholder) {
    configurationForm = {
      groups: [
        {
          ...configurationForm.groups[0],
          questions: configurationForm.groups[0].questions.map(question => {
            let value = placeholder[question.name] ?? question.value;

            if (valuesField) {
              value = placeholder[valuesField]?.[0]?.[question.name] ?? value;
            }

            if (question.name === 'apiCallBody') {
              try {
                value = JSON.parse(value);
              } catch {
                value = '';
              }
            }

            return {
              ...question,
              value,
            };
          }),
        },
      ],
    };
  }

  return configurationForm;
}

function setApiCallsOptionsForButtonConfigurationForm(
  apiCalls: { name: string; label: string }[],
  configurationForm: FormConfigurations
): FormConfigurations {
  if (configurationForm) {
    configurationForm = {
      groups: [
        {
          ...configurationForm.groups[0],
          questions: configurationForm.groups[0].questions.map(question => {
            if (question.name === 'apiCalls') {
              return apiCalls?.length
                ? {
                    ...question,
                    options: apiCalls || [],
                  }
                : {
                    ...question,
                    type: 'label',
                    defaultValue: NO_API_CALL_INSERTS_MSG,
                  };
            }

            return question;
          }),
        },
      ],
    };
  }

  return configurationForm;
}

export const placeholdersWizard = (state: AppState): PlaceholderWizardState => state.placeholdersWizard;

export const insertTypeStates = createSelector(placeholdersWizard, state => state.insertTypeStates);

export const getPlaceholders = createSelector(placeholdersWizard, state => state && state.placeholders);

export const placeholdersMetadata = createSelector(getPlaceholders, state => state.metadata);

export const placeholderById = id =>
  createSelector(getPlaceholders, state => state.metadata.find(placeholder => placeholder.id === id));

export const dynamicFormValue = createSelector(
  placeholdersWizard,
  placeholdersWizard => placeholdersWizard.dynamicForm.content
);

export const getCurrentCustomPage = createSelector(placeholdersWizard, state => state?.currentCustomPage);

export const getCustomPagesList = createSelector(placeholdersWizard, state => state?.customPagesList);

export const getCustomPagesCount = createSelector(placeholdersWizard, state => state?.customPagesCount);

export const getSelectedCustomPage = createSelector(placeholdersWizard, state => state.selectedCustomPage);

export const getSelectedInserts = createSelector(placeholdersWizard, state => state.selectedInserts);

export const getCustomPageAndInserts = createSelector(placeholdersWizard, state => {
  return [state.selectedCustomPage, state.selectedInserts] as [CustomPage, Insert[]];
});

export const configurationForm = ({ id, stateId, valuesField = null }) =>
  createSelector(placeholdersMetadata, dynamicFormValue, (placeholders, formValue) => {
    const configurationForm = FORM_BY_STATE_ID[stateId] as any;
    let data: FormConfigurations = configurationForm;

    if (stateId === INSERT_TYPE.button) {
      const apiCalls = placeholders
        .filter(placeholder => placeholder.insertType === INSERT_TYPE.apiCall)
        .map(placeholder => ({
          name: placeholder.id,
          label: placeholder.placeholderName,
        }));
      data = setApiCallsOptionsForButtonConfigurationForm(apiCalls, configurationForm);
    }

    if (id) {
      const placeholder = Object.assign(
        {},
        placeholders.find(placeholder => placeholder.id === id)
      );

      // handle values for the charts created with old flow
      if (typeof placeholder.chartHeight === 'number' && typeof placeholder.chartFontSize === 'number') {
        placeholder.chartHeight += 'px';
        placeholder.chartFontSizeAxesLabels = placeholder.chartFontSize + 'px';
        [
          'chartFontSizeAxesNames',
          'chartFontSizeToolTipLabel',
          'chartFontSizeToolTipNumber',
          'chartFontSizeXAxisValuePin',
        ].forEach(key => (placeholder[key] = ''));
      }

      if (
        stateId === INSERT_TYPE.customTable &&
        (!placeholder.create || (placeholder.create && placeholder.reused)) &&
        !placeholder.edit
      ) {
        placeholder.productIndex = isNaN(+placeholder.productIndex)
          ? placeholder.productIndex
          : +placeholder.productIndex + 1;
      }

      if (!placeholder.edit) {
        placeholder.isNewVersion = placeholder?.isNewVersion || placeholder.version === 2;
      }

      data = setValuesForChartConfigurationForm(
        formValue && data.groups[0].questions.some(question => formValue[question.name]) ? formValue : placeholder,
        data,
        valuesField
      );
    } else {
      data = formValue ? setValuesForChartConfigurationForm(formValue, data, valuesField) : data;
    }

    return { data };
  });

export const changeFormByChartType = (data: ConfigurationData, dataSourceType: string) => {
  return createSelector(dynamicFormValue, (formValue: any) => {
    const questions = [...data.data.groups[0].questions];

    data.data.groups[0].questions = questions.filter((question: Partial<Question>) => {
      const isValidForChart = question.disableForChart ? !question.disableForChart.includes(formValue.chartType) : true;

      const isValidForDataSource = question.disableForDataSource
        ? !question.disableForDataSource.includes(dataSourceType)
        : true;

      return isValidForChart && isValidForDataSource;
    });

    return data;
  });
};

export const configurationFormWithMetricQuestion = ({ data, plansConfigData, plansConfigMeta }) =>
  createSelector(placeholdersMetadata, placeholders => {
    if (data?.data?.groups && data?.data?.groups[0]?.questions && plansConfigData) {
      const questions = data.data.groups[0].questions.map(question => {
        const names = ['xAxisMetric', 'yAxisMaxValueMetric', 'xAxisMaxValueMetric'];

        if (names.includes(question.name)) {
          const metrics = question.name === 'xAxisMetric' ? plansConfigData : plansConfigMeta;
          const options = [
            ...question.options,
            ...metrics,
            ...placeholders
              .filter(placeholder => placeholder.insertType === INSERT_TYPE.customMetric)
              .map(placeholder => ({
                label: placeholder.placeholderName,
                name: placeholder.placeholderKey,
              })),
          ];

          const mappedOptions = addKeyToLabel(options);

          return {
            ...question,
            options: mappedOptions,
          };
        }

        return question;
      });

      return {
        data: {
          groups: [
            {
              ...data.data.groups[0],
              questions,
            },
          ],
        },
      };
    }

    return data;
  });

export const chartMetricsForms = (formData: ChartMetricFormValue) =>
  createSelector(placeholdersMetadata, dynamicFormValue, (placeholders, formValue: any) => {
    const isPieOrDonut = formValue.chartType === ChartTypes.pie || formValue.chartType === ChartTypes.donut;
    const isStaticsMetrics = formValue.chartDataSource === ChartDataSource.staticMetrics;
    let plansConfig = formData.plansConfigData;

    const filteredPlansConfigMeta =
      formValue.chartType === ChartTypes.clusteredBarChart
        ? formData.plansConfigMeta.filter((item: MappedCarrierPlan) => item.isPrimitive)
        : formData.plansConfigMeta.filter((item: MappedCarrierPlan) => !item.isPrimitive);

    if (
      (isPieOrDonut && isStaticsMetrics && formValue.metricDataSource === CALCULATED_DATA_TARGET.metadata) ||
      formValue.chartType === ChartTypes.clusteredBarChart
    ) {
      plansConfig = filteredPlansConfigMeta;
    }

    const fullPlansConfigData = [
      ...plansConfig,
      ...placeholders
        .filter(placeholder => {
          if (formValue.chartType === ChartTypes.clusteredBarChart) {
            return (
              placeholder.insertType === INSERT_TYPE.customMetric &&
              placeholder.calculatedDataTarget === CALCULATED_DATA_TARGET.metadata
            );
          }

          return placeholder.insertType === INSERT_TYPE.customMetric;
        })
        .map(placeholder => ({
          label: placeholder.placeholderName,
          name: placeholder.placeholderKey,
        })),
    ];

    const mappedFullPlansConfigData = addKeyToLabel(fullPlansConfigData);

    const placeholder = formData.id ? placeholders.find(placeholder => placeholder.id === formData.id) : null;

    return isPieOrDonut && isStaticsMetrics
      ? generateStaticFormsChartMetrics(mappedFullPlansConfigData, placeholder, formValue)
      : generateDynamicFormsChartMetrics(
          mappedFullPlansConfigData,
          formData.metricsNumber,
          placeholder,
          formValue,
          formData.isDeleteMetric,
          formData.configurationData
        );
  });

export const getImagesList = createSelector(placeholdersWizard, placeholdersWizard =>
  placeholdersWizard.imagesList.filter(image => !image.delete)
);

export const getPersistImagesList = createSelector(
  placeholdersWizard,
  placeholdersWizard => placeholdersWizard.persistImagesList
);

export const selectedImageName = createSelector(
  getImagesList,
  imagesList => imagesList.find(image => image.selected)?.name
);

export const activeInsertTypeState = createSelector(
  insertTypeStates,
  insertTypeStates => insertTypeStates.typeStates.find(state => state.active) || INSERT_TYPE_STATE
);

export const selectedInsertType = createSelector(insertTypeStates, insertTypeStates => insertTypeStates.selectedType);

export const selectFormValidation = createSelector(
  placeholdersWizard,
  selectedImageName,
  (placeholdersWizard, selectedImageName) => {
    const activeStateId = placeholdersWizard.insertTypeStates.typeStates.find(state => state.active)?.id;
    const genericValidation = !some(placeholdersWizard.validationForm, ['isValid', false]);

    switch (activeStateId) {
      case IMAGE_STATE.id:
        return genericValidation && !!selectedImageName;

      default:
        return genericValidation;
    }
  }
);

export const selectDynamicFormOpen = createSelector(placeholdersWizard, placeholdersWizard => placeholdersWizard.open);

export const changeVariableConfiguration = (formConfiguration: ConfigurationData, formValue: VariablePlaceholder) => {
  formConfiguration.data.groups[0].questions = formConfiguration.data.groups[0].questions.map(
    (question: Partial<Question>) => {
      if (question.name === 'placeholderDefaultValue') {
        question.validationRules = formValue?.isInlineEditable ? [] : [{ rule: 'required', value: 'true' }];
        question.optional = formValue?.isInlineEditable;
      }

      return question;
    }
  );

  return formConfiguration;
};

const addKeyToLabel = (data: MappedCarrierPlan[]): MappedCarrierPlan[] => {
  const mappedDate = data.map((item: MappedCarrierPlan) => {
    return item.name.toLowerCase() === 'none' ? item : { ...item, label: `${item.label} (${item.name})` };
  });

  return uniqBy(mappedDate, 'label');
};
