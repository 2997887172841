<div class="aapp-login-container">
  <div class="container">
    <div class="row justify-content-center">
      <h3 class="title col-12">Manage Password</h3>
      <div class="col-lg-8 col-sm-12">
        @if (forced) {
          <um-manage-password-form
            [rememberPasswordLinkNavigateTo]="['/forgot-password']"
            (submitForm)="submitForm($event)"
          ></um-manage-password-form>
        }
        @if (!forced) {
          <um-manage-password-form
            [rememberPasswordLinkNavigateTo]="['/forgot-password']"
            (cancelForm)="cancelForm()"
            (submitForm)="submitForm($event)"
          ></um-manage-password-form>
        }
      </div>
    </div>
  </div>
</div>
