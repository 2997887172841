import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';

import { RetirementShortfallMetrics } from '@shared/constants';
import {
  RETIREMENT_NEED_POST_TAX_KEY,
  LIFE_INSURANCE_DISTRIBUTION_TAX_FREE_POLICY_KEY,
} from './retirement-shortfall.constants';
import { Global } from '@shared/services';
import { SelectedPlansValues } from '@shared/models';
import { ActivePlan, CareerPlan, Metric } from '@core/model';
import { CALCULATED_DATA_TARGET, ChartTypes } from '@core/enums';

@Injectable()
export class RetirementShortfallService {
  metrics: Metric[];
  allMetrics: Metric[];

  constructor(private global: Global) {}

  public setMetrics(activePlans: CareerPlan[], configActivePlans: ActivePlan[], isOn: boolean): void {
    const allMetrics = RetirementShortfallMetrics[isOn ? 'on' : 'off'];
    const lifeInsuranceMetric: Metric = allMetrics.find(
      item => item.configMetricKey === LIFE_INSURANCE_DISTRIBUTION_TAX_FREE_POLICY_KEY
    );

    if (lifeInsuranceMetric && activePlans && activePlans[0]) {
      lifeInsuranceMetric.name = activePlans[0].configjson.metadata.product_name;
      // metricId only use for static page not for sale concept
      const conf = configActivePlans.find((item: ActivePlan) => item.metricId === lifeInsuranceMetric.configMetricKey);

      if (conf) {
        lifeInsuranceMetric.show = conf.isShown;
      }
    }

    const metrics = allMetrics.filter(item => item.configMetricKey !== RETIREMENT_NEED_POST_TAX_KEY);
    this.allMetrics = allMetrics;
    this.metrics = metrics.map(metric => ({
      ...metric,
      chartType: ChartTypes.bar,
      stackedMetric: true,
      stackedGroup: '1',
    }));
  }

  public getRetirementNeedPostTax(configKey: string, selectedPlanValue: any) {
    const retirementNeed = this.allMetrics.find(item => item.configMetricKey === RETIREMENT_NEED_POST_TAX_KEY);

    return {
      visualizationUiId: configKey,
      value: selectedPlanValue,
      planId: retirementNeed.key,
      additionalLabel: retirementNeed && retirementNeed.title ? retirementNeed.title : '',
      color: null,
      show: true,
    } as SelectedPlansValues;
  }

  public getShortfallSettings(settings: any) {
    const clone = _.cloneDeep(settings);
    clone.lifeInsurancePremium = this.calculateLifeInsurancePremium();

    return clone;
  }

  public calculateLifeInsurancePremium() {
    return this.global.getCurrentCarrierPlans.reduce((prevMaxValue: number, plan) => {
      if (
        plan &&
        plan.configjson &&
        plan.configjson.metadata &&
        plan.configjson.metadata.initial_annual_premium &&
        (!prevMaxValue || Number(plan.configjson.metadata.initial_annual_premium) > prevMaxValue)
      ) {
        return Number(
          plan.configjson.metadata.initial_annual_retirement_modal || plan.configjson.metadata.initial_annual_premium
        );
      }

      return prevMaxValue;
    }, null);
  }

  public getTaxRate(
    x: number,
    id: string,
    activePlans: CareerPlan[],
    xAxisSource: string,
    distributionTaxFlag: boolean
  ): string {
    let taxRate = 'N/A';

    if (activePlans.length) {
      const resultArray = _.get(activePlans[0], `${CALCULATED_DATA_TARGET.data}.${xAxisSource}`);
      const year = _.indexOf(resultArray, `${x}`);

      if (year >= 0) {
        const policy_on = _.get(activePlans[0], `${CALCULATED_DATA_TARGET.data}.${id}_policy_on`) || [];
        const policy_off = _.get(activePlans[0], `${CALCULATED_DATA_TARGET.data}.${id}_policy_off`) || [];

        const rate = distributionTaxFlag ? policy_on[year] : policy_off[year];

        if (rate !== undefined) {
          taxRate = `${rate}%`;
        }
      }
    }

    return taxRate;
  }

  public getRetirementGoalMet(
    lastYearOfRetirement: number,
    xAxisSource: string,
    activePlans: CareerPlan[],
    distributionTaxFlag: boolean
  ) {
    if (activePlans.length) {
      let retirementGoalMet = 'N/A';
      const resultArray = _.get(activePlans[0], `${CALCULATED_DATA_TARGET.data}.${xAxisSource}`);
      const year = _.indexOf(resultArray, `${lastYearOfRetirement}`);

      if (year >= 0) {
        const policy_on =
          _.get(
            activePlans[0],
            `configjson.data.post_tax_percent_of_retirement_goal_met_if_retirement_ends_in_this_year_policy_on`
          ) || [];
        const policy_off =
          _.get(
            activePlans[0],
            `configjson.data.post_tax_percent_of_retirement_goal_met_if_retirement_ends_in_this_year_policy_off`
          ) || [];

        const goal = distributionTaxFlag ? policy_on[year] : policy_off[year];

        if (goal !== undefined) {
          retirementGoalMet = `${goal}%`;
        }
      }

      return retirementGoalMet;
    }
  }

  public hasShortfallSettings(config: any) {
    const shortfallSettings = _.get(config, 'config.shortfallSettings');
    const values = _.values(shortfallSettings);

    return values.length && !values.includes(null);
  }

  public contributionPercentCalculation(field: string, settings: any) {
    const contributionAmountControl = settings.contributionAmount;
    const retirementAssetsContributionOfIncomeControl = settings.retirementAssetsContributionOfIncome;
    const currentAnnualIncomeControl = settings.currentAnnualIncome;

    switch (field) {
      case 'contributionAmount':
        retirementAssetsContributionOfIncomeControl.patchValue(
          this.rounding((100 * contributionAmountControl.value) / currentAnnualIncomeControl.value),
          { emitEvent: false }
        );
        retirementAssetsContributionOfIncomeControl.markAsDirty();
        break;

      case 'retirementAssetsContributionOfIncome':
        retirementAssetsContributionOfIncomeControl.patchValue(
          this.rounding(retirementAssetsContributionOfIncomeControl.value),
          { emitEvent: false }
        );
        contributionAmountControl.patchValue(
          this.contributionAmountControlCalculate(
            currentAnnualIncomeControl.value,
            retirementAssetsContributionOfIncomeControl.value
          ),
          { emitEvent: false }
        );
        contributionAmountControl.markAsDirty();
        break;
    }
  }

  public contributionAmountControlCalculate(
    currentAnnualIncome: number,
    retirementAssetsContributionOfIncome: number
  ): number {
    return (currentAnnualIncome * retirementAssetsContributionOfIncome) / 100;
  }

  public isContributionAmountValid(contributionAmount: any, lifeInsurancePremium: any) {
    return parseFloat(contributionAmount) < parseFloat(lifeInsurancePremium);
  }

  public rounding(value: number): number {
    return Math.round(100 * value) / 100;
  }
}
