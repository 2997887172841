@if (isFocused) {
  @switch (metadata.placeholderValueType) {
    @case (valueType.number) {
      <div class="container-control d-inline-flex flex-column justify-content-start">
        <div class="d-inline-flex align-items-center">
          <input
            epFocusControl
            [mask]="mask"
            [dropSpecialCharacters]="false"
            [placeholder]="metadata.placeholderHint"
            [formControl]="formControl"
            [class.form-control-error]="formControl.errors?.pattern || formControl.errors?.max || formControl.errors?.min"
            class="form-control"
            (blur)="hideControl($event)"
            >
            <ng-container *ngTemplateOutlet="buttons"></ng-container>
          </div>
          <ng-container *ngTemplateOutlet="errorsMessages"></ng-container>
        </div>
      }
      @case (valueType.percentage) {
        <div class="container-control d-inline-flex container-percentage position-relative flex-column justify-content-start">
          <div class="d-flex align-items-center">
            <input
              epFocusControl
              suffix="%"
              [mask]="mask"
              [dropSpecialCharacters]="['%']"
              [placeholder]="metadata.placeholderHint"
              [formControl]="formControl"
              [class.form-control-error]="formControl.errors?.pattern || formControl.errors?.max || formControl.errors?.min"
              class="form-control control-symbol control-percentage"
              (blur)="hideControl($event)"
              >
              <ng-container *ngTemplateOutlet="buttons"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="errorsMessages"></ng-container>
          </div>
        }
        @case (valueType.currency) {
          <div class="container-control d-inline-flex container-currency position-relative flex-column justify-content-start">
            <div class="d-flex align-items-center">
              <input
                epFocusControl
                prefix="$"
                [mask]="mask"
                [dropSpecialCharacters]="['$']"
                [placeholder]="metadata.placeholderHint"
                [formControl]="formControl"
                [class.form-control-error]="formControl.errors?.pattern || formControl.errors?.max || formControl.errors?.min"
                class="form-control control-symbol control-currency"
                (blur)="hideControl($event)"
                >
                <ng-container *ngTemplateOutlet="buttons"></ng-container>
              </div>
              <ng-container *ngTemplateOutlet="errorsMessages"></ng-container>
            </div>
          }
          @case (valueType.textField) {
            <div class="container-control d-inline-flex flex-column justify-content-start">
              <div class="d-flex align-items-center">
                <input
                  epFocusControl
                  [placeholder]="metadata.placeholderHint"
                  [formControl]="formControl"
                  [class.form-control-error]="formControl.errors?.maxlength"
                  class="form-control"
                  (blur)="hideControl($event)">
                  <ng-container *ngTemplateOutlet="buttons"></ng-container>
                </div>
                <ng-container *ngTemplateOutlet="errorsMessages"></ng-container>
              </div>
            }
            @case (valueType.textArea) {
              <div class="container-control d-inline-flex flex-column justify-content-star page-break">
                <div class="d-flex align-items-start">
                  <textarea
                    class="form-control textarea-control"
                    [class.form-control-error]="formControl.errors?.maxlength"
                    epFocusControl
                    [placeholder]="metadata.placeholderHint"
                    (blur)="hideControl($event)"
                    [formControl]="formControl">
                  </textarea>
                </div>
                <div class="d-flex justify-content-between mt-2">
                  <ng-container *ngTemplateOutlet="errorsMessages"></ng-container>
                  <div class="d-flex justify-content-end text-area-btns">
                    <ng-container *ngTemplateOutlet="buttons"></ng-container>
                  </div>
                </div>
              </div>
            }
          }
        }

        @if (!isFocused) {
          <div
            class="container-value p-2 d-inline-flex justify-content-between align-items-center rounded"
            (click)="showControl()">
            <div class="d-inline-flex align-items-center">
              @if (metadata.placeholderValueType === valueType.currency && isCurrentValueDefined) {
                <span class="prefix-value">$</span>
              }
              <span [ngClass]="{'text-area-value': metadata.placeholderValueType === valueType.textArea }">
                {{  isCurrentValueDefined && isNumeric ? (+currentValue | thousandsSeparator) : currentValue || metadata.placeholderHint }}
              </span>
              @if (metadata.placeholderValueType === valueType.percentage && isCurrentValueDefined) {
                <span class="prefix-value">%</span>
              }
            </div>
            <span class="fa fa-pencil ml-2" aria-hidden="true"></span>
          </div>
        }

        <ng-template #buttons>
          <div class="wrap-btn" [class.d-none]="isActiveBulkEdit">
            <se-button class="secondary ml-2 save-inline-variable" (click)="save()">&#10003;</se-button>
            <se-button class="delete ml-2" (click)="hideControl($event)">&#10005;</se-button>
          </div>
        </ng-template>

        <ng-template #errorsMessages>
          @if (formControl.errors?.pattern) {
            <span class="error-message mt-1">
              Number of decimals should be {{ metadata.placeholderDecimals ? 'up to' : '' }} {{ metadata.placeholderDecimals }}
            </span>
          }
          @if (formControl.errors?.max) {
            <span class="error-message mt-1">
              Value should be less than or equal to {{ metadata.placeholderMaxValue }}
            </span>
          }
          @if (formControl.errors?.min) {
            <span class="error-message mt-1">
              Value should be greater than or equal to {{ metadata.placeholderMinValue }}
            </span>
          }
          @if (formControl.errors?.maxlength) {
            <span class="error-message mt-1">
              Please enter up to {{ metadata.placeholderMaxLength }} characters
            </span>
          }
        </ng-template>
