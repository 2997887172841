@if (options.isPDF) {
  @if (!isSinglePolicyOrRTEOrCharges) {
    <a [href]="shareableLink" target="_blank">
      <ng-container *ngTemplateOutlet="chartContent"></ng-container>
    </a>
  }
  @if (isSinglePolicyOrRTEOrCharges) {
    <ng-container *ngTemplateOutlet="chartContent"></ng-container>
  }
} @else {
  <ng-container *ngTemplateOutlet="chartContent"></ng-container>
}


<ng-template #chartContent>
  @if (isPieOrDonutChart && !isNoData) {
    <div
      class="chart-legend"
    [ngClass]="{
      'legend-right': legendPosition === 'right',
      'legend-bottom': legendPosition === 'bottom'
    }"
      >
      <ng-container *ngTemplateOutlet="chart"></ng-container>
      <div>
        <div>
          @if (options.chart.legendTitle) {
            <div class="chart-legend-title">
              {{ options.chart.legendTitle }}
            </div>
          }
          @if (options.chart.legendSubtitle) {
            <div class="chart-legend-subtitle">
              {{ options.chart.legendSubtitle }}
            </div>
          }
        </div>
        <div
          class="legend-container"
        [ngStyle]="{
          'flex-direction':
            (!isSecondLineDescriptionLegend && legendPosition) === 'bottom'
              ? 'row'
              : 'column'
        }"
          >
          @for (item of legendData; track item) {
            <div
              (mouseenter)="focusOnChartDataById(item.id)"
              (mouseleave)="resetFocusOnChartData()"
              class="legend-item"
              >
              <div
            [ngClass]="{
              'item-dimmed': item.dimmed,
              'has-legend-description': item.description
            }"
                class="legend-item-container"
                >
                <span
                  [ngStyle]="{ 'background-color': item.color }"
                  class="color-box"
                ></span>
                <div>
                  <div
                    class="legend-item-title"
                    [ngClass]="{ 'legend-item-title-bold': item.description }"
                    >
                    {{ item.title }}
                  </div>
                  @if (item.description) {
                    <div
                      class="legend-item-second-line-description"
                      >
                      @if (item.description.secondLineDescription) {
                        <span>
                          {{ item.description.secondLineDescription }}:
                        </span>
                      }
                      <span class="legend-item-value">
                        {{ item.description.nameAsValue }}
                      </span>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  }

  @if (!isNoData && !isPieOrDonutChart) {
    <ng-container *ngTemplateOutlet="chart"></ng-container>
  }

  <ng-template #chart>
    <div
      #c3Chart
      class="c3Chart"
      [ngClass]="{
        'tooltip-value-hide': isPieOrDonutChart && isValueTooltipHide,
        'container-pie-donut': isPieOrDonutChart,
        'rotated-bar-chart' : options.chart.rotatedAxes,
        'is-bar-chart' : isClusteredBarChart
      }"
    ></div>
  </ng-template>

  @if (isNoData) {
    <div
      class="no-data-message"
      [ngStyle]="{ height: options.chart.height + 'px' }"
      >
      <p>No Data Available</p>
    </div>
  }
</ng-template>
