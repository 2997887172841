import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { SingleViewExtendedTab, TabInfo } from '@shared/models';

@Component({
  selector: 'ep-tab-editing-modal',
  templateUrl: './tab-editing-modal.component.html',
  styleUrls: ['./tab-editing-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabEditingModalComponent implements OnInit {
  @Input() editMode: boolean;
  @Input() createMode: boolean;
  @Input() validationOptions: {
    tabsQuantity: number;
    tabsTitles: string[];
  };
  @Input() tab: SingleViewExtendedTab;
  @Output() output = new EventEmitter<TabInfo>();
  tabNameControl: UntypedFormControl;
  maxTabsQuantity = 8;

  ngOnInit(): void {
    this.tabNameControl = new UntypedFormControl(this.editMode ? this.tab.title : '', [
      Validators.required,
      Validators.maxLength(50),
      this.getTabsQuantityValidator(),
      this.getUniqueTabTitleValidator(),
    ]);
  }

  private getTabsQuantityValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      this.validationOptions.tabsQuantity >= this.maxTabsQuantity ? { tabsQuantity: { value: control.value } } : null;
  }

  private getUniqueTabTitleValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      this.validationOptions.tabsTitles.includes(control.value.trim())
        ? {
            uniqueTabTitle: { value: control.value },
          }
        : null;
  }

  createTab(): void {
    const value = this.tabNameControl.value.trim();

    if (this.tabNameControl.invalid || !value) return;

    this.output.emit({ tabTitle: value });
  }

  editTab(): void {
    const value = this.tabNameControl.value.trim();

    if (this.tabNameControl.invalid || !value) return;

    this.output.emit({
      currentTabTitle: this.tab.tabTitle,
      newTabTitle: value,
    });
  }

  cancel(): void {
    this.output.emit();
  }
}
