import { UntypedFormControl } from '@angular/forms';

import { IPagerOptions } from '@se/common/app/navigations/components/pager/pager.interface';
import { HeaderItem, TableDataItem } from '@shared/models';

export abstract class SummaryTable {
  searchControl: UntypedFormControl;
  tableData: {
    data: {
      data: TableDataItem;
    }[];
  }[];
  tableHeadersData: HeaderItem[];
  pagination: IPagerOptions = {
    itemsPerPage: 10,
    totalElements: 0,
    currentPage: 1,
    isManyType: false,
    hasNextPage: false,
  };

  /** maximum items on the page */
  itemsPerPage: number;
  /** maximum existing items */
  totalElements: number;
  /** current page */
  currentPage: number;
  /** flag for MANY flow */
  isManyType?: boolean;
  /** marker for next page existing */
  hasNextPage?: boolean;
  protected sortField = 'update_date';
  protected direction = 'desc';

  abstract onHeaderClick(headerItem: HeaderItem): void;
  abstract onPageChange(e): void;
  protected abstract setTableData(items): void;

  protected setTableHeadersData(headers: HeaderItem[] = [], headerItem?: HeaderItem): void {
    if (headerItem) {
      this.direction = headerItem.format.direction === 'asc' ? 'desc' : 'asc';
      this.sortField = headerItem.key;
    }

    this.tableHeadersData = headerItem
      ? this.tableHeadersData.map(header => ({
          ...header,
          format: {
            ...header.format,
            sorted: header.key === headerItem.key,
            direction: this.direction,
          },
        }))
      : headers;
  }
}
