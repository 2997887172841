@if ((coverSheet || coverSheetLoaded) && coverSheetTemplates && coverSheetTemplates.length) {
  <div class="template-holder" #templateRef>
    @if (selectedTemplateUid !== 'text') {
      <span [innerHTML]="innerHTML"></span>
    }
    @if (selectedTemplateUid === 'text') {
      <div>
        <div>
          @if (!PDFPrint) {
            <ng-template [ngTemplateOutlet]="header"></ng-template>
          }
          <section [innerHTML]="htmlBody" class="coversheet-text-body"></section>
        </div>
      </div>
    }
  </div>
}

@if ((selectedTemplateUid === 'allianz' || selectedTemplateUid === 'allianzretirement') && !landscape) {
  <div
    class="resize-button-holder print-elem-hide"
    (click)="toggleZoom()"
    >
    <ac-icon
      acSuffix
      icon="{{ fitPage ? 'zoomout' : 'zoomin' }}"
      color="#fff"
      [width]="22"
      [height]="22"
    ></ac-icon>
  </div>
}

<ng-template #header>
  <div class="template-header">
    <div class="a-app-logo-img"></div>
  </div>
</ng-template>
