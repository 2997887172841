@if (imagesList?.length) {
  <div class="image-container">
    @for (image of imagesList; track image) {
      <div class="image-item">
        @if (image.loading) {
          <se-circle-loader
            type="circle-scale"
          ></se-circle-loader>
        }
        <img
          [hidden]="image.loading"
          (load)="onImageLoad(image)"
          [src]="image.url | safeResource: 'url'"
          />
          <div [hidden]="image.loading" class="controls">
            <se-radio-button
              name="imageSelector"
              [checked]="image.selected"
              (onSelect)="onToggleImage(image)"
            ></se-radio-button>
            @if (!hideDelete) {
              <div
                (click)="onDeleteImage(image)"
                class="ensight-icon-trash"
              ></div>
            }
          </div>
        </div>
      }
    </div>
  }
