import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { INSERT_TYPE } from '@core/enums';
import { NUMBER_REGEXP } from '@shared/constants';
import { CustomPageService } from '../../setup/custom-page/custom-page.service';
import { GAService, ImageHandlerService } from '@shared/services';
import { ImageInfo } from '@shared/models';
import { ExtendedPlaceholderMetadata, ImagePlaceholder, TextPlaceholder, VariablePlaceholder } from '@core/model';
import { getValidatorsForEditablePlaceholder } from '@shared/utils';

@Component({
  selector: 'ep-placeholders-editing-modal',
  templateUrl: './placeholders-editing-modal.component.html',
  styleUrls: ['./placeholders-editing-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholdersEditingModalComponent implements OnInit {
  @Input() placeholder: ExtendedPlaceholderMetadata;
  @Output() output = new EventEmitter<unknown>();

  insertTypeIds = INSERT_TYPE;
  loading: boolean;
  valueControl: UntypedFormControl;
  inputPattern: RegExp;
  imagesList: ImageInfo[];

  private selectedImageInfo: ImageInfo;

  constructor(
    private customPageService: CustomPageService,
    private gaService: GAService,
    private imageHandlerService: ImageHandlerService
  ) {}

  ngOnInit(): void {
    if (this.placeholder.insertType === INSERT_TYPE.image) {
      const fileLink = this.imageHandlerService.getImageLink(
        this.placeholder.filesLinks,
        this.placeholder.value as string,
        (this.placeholder as ExtendedPlaceholderMetadata<ImagePlaceholder>).selectedImage
      );

      this.imagesList = this.imageHandlerService.getImageObjects(this.placeholder, fileLink.split('/').pop());

      return;
    }

    this.valueControl = new UntypedFormControl(
      this.placeholder.value === 0 ? 0 : this.placeholder.value || '',
      getValidatorsForEditablePlaceholder(
        this.placeholder as ExtendedPlaceholderMetadata<TextPlaceholder & VariablePlaceholder>
      )
    );

    if (this.placeholder.insertType === INSERT_TYPE.variable) this.inputPattern = new RegExp(NUMBER_REGEXP);
  }

  onLoadedImage(event: ImageInfo): void {
    this.imagesList = this.imagesList.map(image => {
      return image.name === event.name
        ? {
            ...image,
            loading: false,
          }
        : image;
    });
  }

  onSelectImage(event: ImageInfo): void {
    this.selectedImageInfo = event;
  }

  getErrorMessages(errors: Record<string, unknown>): string[] {
    return this.customPageService.getErrorMessagesForEditablePlaceholder(
      this.placeholder.insertType,
      this.placeholder,
      errors ?? {}
    );
  }

  cancel(): void {
    this.output.emit({ updatedInsert: null });
  }

  update(): void {
    const invalidValue = this.valueControl && (!this.valueControl.value || !this.valueControl.valid);
    const invalidImage = this.placeholder.insertType === INSERT_TYPE.image && !this.selectedImageInfo;

    if (invalidValue || invalidImage) {
      this.cancel();

      return;
    }

    let value;

    switch (this.placeholder.insertType) {
      case INSERT_TYPE.text:
        value = this.valueControl.value;
        this.gaService.sendInsertEvent({
          elementName: (this.placeholder as ExtendedPlaceholderMetadata<TextPlaceholder>).placeholderName,
          eventAction: 'Text Insert Changed',
        });
        break;
      case INSERT_TYPE.variable:
        value = Number(this.valueControl.value);
        this.gaService.sendInsertEvent({
          elementName: (this.placeholder as ExtendedPlaceholderMetadata<VariablePlaceholder>).placeholderName,
          eventAction: 'Variable Insert Changed',
        });
        break;
      case INSERT_TYPE.image:
        value = this.selectedImageInfo.name;
        this.gaService.sendInsertEvent({
          elementName: (this.placeholder as ExtendedPlaceholderMetadata<ImagePlaceholder>).placeholderName,
          eventAction: 'Image Insert Changed',
        });
        break;
    }

    const updatedInsert = {
      uiId: this.placeholder.id,
      value,
    };

    this.output.emit({ updatedInsert });
  }
}
