import { INSERT_TYPE } from '@core/enums';
import { InsertType } from '@core/model';

export const STYLE_EDITOR_SELECTOR = 'basicStyleElementButton';
export const WRAP_ELEMENT = 'basicWrapElementButton';
export const INIT_CONFIG = {
  base_url: '/tinymce',
  suffix: '.min',
  resize: false,
  plugins: ['lists', 'advlist', 'autolink', 'hr', 'image', 'link', 'lists', 'pagebreak', 'table', 'paste', 'anchor'],
  menubar: 'insert format table',
  force_br_newlines: false,
  force_p_newlines: false,
  toolbar: `undo redo | bold italic fontselect fontsizeselect
  | bullist numlist outdent indent hr pagebreak
  | image link table anchor | basicIfButton basicInsertButton
  | basicSelectElementButton | ${WRAP_ELEMENT} ${STYLE_EDITOR_SELECTOR}`,
  height: '100%',
  table_default_attributes: {
    cellpadding: '0',
  },
  default_link_target: '_blank',
  link_context_toolbar: true,
  image_title: true,
  image_advtab: true,
  image_description: false,
  forced_root_block: '',
  pagebreak_separator: '<div class="page-break"></div>',
  link_assume_external_targets: false,
  extended_valid_elements: 'app-chart[class],div[style,class],table[style,cellpadding]',
  valid_children:
    '+body[style],+table[style],+tbody[style],+tr[style],+td[style],+p[style],+section[style],+div[style]',
  custom_elements: 'app-chart',
  content_css: ['../../../../assets/tiny/load-fonts.css', '../../../../assets/tiny/tinymce-content.css'],
  font_formats:
    'Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats;Museo Sans=Museo Sans;Source Sans Pro=Source Sans Pro;Open Sans=Open Sans;Open Sans Condensed=Open Sans Condensed;Futura Std=Futura Std;Minion Pro=Minion Pro;Gill Sans=Gill Sans;Montserrat=Montserrat;Roboto=Roboto;Allianz Neo=Allianz Neo;Albert Pro=Albert Pro;Nunito=Nunito;Graphik=Graphik;Hellix=Hellix;Alda Pro=Alda Pro;Helvetica Neue LT Pro=Helvetica Neue LT Pro;Unbounded=Unbounded;Soleil=Soleil;Amplitude=Amplitude',
  style_formats: [
    {
      title: 'Headings',
      items: [
        { title: 'Heading 1', format: 'h1' },
        { title: 'Heading 2', format: 'h2' },
        { title: 'Heading 3', format: 'h3' },
        { title: 'Heading 4', format: 'h4' },
        { title: 'Heading 5', format: 'h5' },
        { title: 'Heading 6', format: 'h6' },
      ],
    },
    {
      title: 'Inline',
      items: [
        { title: 'Bold', icon: 'bold', format: 'bold' },
        { title: 'Italic', icon: 'italic', format: 'italic' },
        { title: 'Underline', icon: 'underline', format: 'underline' },
        {
          title: 'Strikethrough',
          icon: 'strikethrough',
          format: 'strikethrough',
        },
        { title: 'Superscript', icon: 'superscript', format: 'superscript' },
        { title: 'Subscript', icon: 'subscript', format: 'subscript' },
        { title: 'Code', icon: 'code', format: 'code' },
      ],
    },
    {
      title: 'Blocks',
      items: [
        { title: 'Paragraph', format: 'p' },
        { title: 'Blockquote', format: 'blockquote' },
        { title: 'Div', format: 'div' },
        { title: 'Pre', format: 'pre' },
      ],
    },
    {
      title: 'Alignment',
      items: [
        { title: 'Left', icon: 'alignleft', format: 'alignleft' },
        { title: 'Center', icon: 'aligncenter', format: 'aligncenter' },
        { title: 'Right', icon: 'alignright', format: 'alignright' },
        { title: 'Justify', icon: 'alignjustify', format: 'alignjustify' },
      ],
    },
    {
      title: 'Font Weight',
      items: [
        { title: '100', format: 'fontWeight100' },
        { title: '200', format: 'fontWeight200' },
        { title: '300', format: 'fontWeight300' },
        { title: '400', format: 'fontWeight400' },
        { title: '500', format: 'fontWeight500' },
        { title: '600', format: 'fontWeight600' },
        { title: '700', format: 'fontWeight700' },
        { title: '900', format: 'fontWeight900' },
      ],
    },
  ],
  formats: {
    bold: [
      { inline: 'span', styles: { 'font-weight': 'bold' } },
      { inline: 'strong', remove: 'all' },
      { inline: 'b', remove: 'all' },
    ],
    italic: [
      { inline: 'span', styles: { 'font-style': 'italic' } },
      { inline: 'em', remove: 'all' },
    ],
    fontWeight100: { inline: 'span', styles: { 'font-weight': '100' } },
    fontWeight200: { inline: 'span', styles: { 'font-weight': '200' } },
    fontWeight300: { inline: 'span', styles: { 'font-weight': '300' } },
    fontWeight400: { inline: 'span', styles: { 'font-weight': '400' } },
    fontWeight500: { inline: 'span', styles: { 'font-weight': '500' } },
    fontWeight600: { inline: 'span', styles: { 'font-weight': '600' } },
    fontWeight700: { inline: 'span', styles: { 'font-weight': '700' } },
    fontWeight900: { inline: 'span', styles: { 'font-weight': '900' } },
  },
};

export const UPLOAD_IMAGE_ERROR_MSG = 'An unexpected error occurred while uploading your image, please try again later';

export const NO_SELECTED_ELEMENT_MSG = 'Please, select element firstly.';
export const WRONG_SELECTED_ELEMENT_MSG = 'Selection contains tags. Please, select only text.';
export const MCE_MARKER_PATTERN = /<span id="mce_marker"[^>]*><\/span>/g;

export const CUSTOM_ELEMENTS_CONFIG = [
  {
    type: 'basicIfButton',
    config: {
      text: 'If',
      tooltip: 'Insert If Condition',
    },
  },
  {
    type: 'basicInsertButton',
    openWizard: true,
    config: {
      text: 'Insert...',
      tooltip: 'Inserts',
    },
  },
  {
    type: 'basicSelectElementButton',
    modifySelection: true,
    config: {
      icon: 'select-all',
      tooltip: 'Select element',
    },
  },
  {
    type: STYLE_EDITOR_SELECTOR,
    config: {
      text: 'Edit CSS',
      tooltip: 'Edit CSS',
    },
  },
  {
    type: WRAP_ELEMENT,
    config: {
      text: '< >',
      tooltip: 'Add wrapper for selection',
    },
  },
];
export const INSERT_PLACEHOLDER_SELECTOR = 'insert-placeholder';

export const PLACEHOLDER_MARKUP_BY_TYPE = {
  [INSERT_TYPE.chart]: `
    <span></span>
    <style data-style-id="<%= styleId %>">
      .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] {
        display:inline-block;
        padding: 12px 20px;
        margin-left: 1px;
        border-radius: 4px;
      }

        .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] .chart-legend {
          font-size: 14px;
          color: black;
          gap: 30px;
        }

        .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] .legend-container {
          gap: 16px;
        }

        .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] .chart-legend-title {
          font-size: 20px;
          color: black;
        }

        .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] .chart-legend-subtitle {
          font-size: 36px;
          font-weight: 700;
          color: black;
          padding-bottom: 15px;
        }

        .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] .legend-item-title {
          font-size: 18px;
        }

        .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] .legend-item-second-line-description {
          font-size: 16px;
        }

        .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] .c3-chart-texts .c3-text {
          fill: #474C50 !important;
        }
    </style>
    <div
      data-style-id="<%= styleId %>"
      contenteditable="false"
      class="${INSERT_PLACEHOLDER_SELECTOR}">
      <section>
        <span class='id <%= id %>' style="display: none;"><%= id %></span>
        <div style="font-size: 18px; line-height: 24px;">
          <span><b><span class='type <%= type %>'><%= type %></span></b></span>
        </div>
        <div style="font-size: 16px; line-height: 20px;">
          <span class='name'><%= name %></span>
        </div>
      </section>
    </div>
    <span></span>`,
  [INSERT_TYPE.customTable]: `
    <span></span>
    <style data-style-id="<%= styleId %>">
    .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] {
        display:inline-block;
        border-radius: 4px;
        padding: 12px 20px;
        margin-left:1px;
      }
      .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] .ng-star-inserted-highlighted {
        background-color: rgba(102, 202, 155, 0.25);
      }
    </style>
    <div
      data-style-id="<%= styleId %>"
      contenteditable="false"
      class="${INSERT_PLACEHOLDER_SELECTOR} ${INSERT_TYPE.customTable}">
      <section>
        <span class='id <%= id %>' style="display: none;"><%= id %></span>
        <div style="font-size: 18px; line-height: 24px;">
          <span><b><span class='type <%= type %>'><%= type %></span></b></span>
        </div>
        <div style="font-size: 16px; line-height: 20px;">
          <span class='name'><%= name %></span>
        </div>
      </section>
    </div>
    <span></span>`,
  [INSERT_TYPE.text]: `
    <span></span>
    <style data-style-id="<%= styleId %>">
    .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] {
        border-radius: 4px;
        padding: 5px 10px;
        margin-left:1px;
      }
    </style>
    <span
      data-style-id="<%= styleId %>"
      contenteditable="false"
      class="${INSERT_PLACEHOLDER_SELECTOR} ${INSERT_TYPE.text}">
        <span class='id <%= id %>' style="display: none;"><%= id %></span>
        <span class='type <%= type %>' style="display: none;"><%= type %></span>
        <span class='name'><%= name %></span>
    </span>
    <span></span>`,
  [INSERT_TYPE.variable]: `
  <span></span>
  <style data-style-id="<%= styleId %>">
  .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] {
      border-radius: 4px;
      padding: 5px 10px;
      margin-left:1px;
    }

    .container-value {}
    .container-value .fa-pencil {}
    .container-value .prefix-value {}
    .container-value .text-area-value {}

    .container-control {}
    .container-control .form-control {}
    .container-control .form-control.form-control-error {}
    .container-control .error-message {}
    .container-control .text-area-btns {}
    .container-control .wrap-btn {}
  </style>
  <span
    data-style-id="<%= styleId %>"
    contenteditable="false"
    class="${INSERT_PLACEHOLDER_SELECTOR} ${INSERT_TYPE.variable}">
      <span class='id <%= id %>' style="display: none;"><%= id %></span>
      <span class='type <%= type %>' style="display: none;"><%= type %></span>
      <span class='name'><%= name %></span>
  </span>
  <span></span>`,
  [INSERT_TYPE.image]: `
  <span></span>
  <style data-style-id="<%= styleId %>">
  .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] {
      display:inline-block;
      border-radius: 4px;
      padding: 12px 20px;
      margin-left: 1px;
      width: <%= width %>px;
      height: <%= height %>px;
    }
  </style>
  <div
    data-style-id="<%= styleId %>"
    contenteditable="false"
    class="${INSERT_PLACEHOLDER_SELECTOR} ${INSERT_TYPE.image}">
    <section>
      <span class='id <%= id %>' style="display: none;"><%= id %></span>
      <span class='type <%= type %>' style="display: none;"><%= type %></span>
      <div style="font-size: 18px; line-height: 24px;">
        <b><span class='name <%= type %>'><%= name %></span></b>
      </div>
      <div style="font-size: 16px; line-height: 20px;">
        <span><%= width %> x <%= height %></span>
      </div>
    </section>
  </div>
  <span></span>`,
  [INSERT_TYPE.button]: `
  <span></span>
  <style data-style-id="<%= styleId %>">
  .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] {
      border-radius: 4px;
      padding: 5px 10px;
      margin-left:1px;
    }
  </style>
  <span
    data-style-id="<%= styleId %>"
    contenteditable="false"
    class="${INSERT_PLACEHOLDER_SELECTOR} ${INSERT_TYPE.button}">
      <span class='id <%= id %>' style="display: none;"><%= id %></span>
      <span class='type <%= type %>' style="display: none;"><%= type %></span>
      <span class='name'><%= name %></span>
  </span>
  <span></span>`,
  [INSERT_TYPE.dropdown]: `
  <span></span>
   <style data-style-id="<%= styleId %>">
  .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] {
      border-radius: 4px;
      padding: 5px 10px;
      margin-left: 1px;
      width: 100px;
    }
  </style>
  <span
    data-style-id="<%= styleId %>"
    contenteditable="false"
    class="${INSERT_PLACEHOLDER_SELECTOR} ${INSERT_TYPE.dropdown}">
      <span class='id <%= id %>' style="display: none;"><%= id %></span>
      <span class='type <%= type %>' style="display: none;"><%= type %></span>
      <span class='name'><%= name %></span>
  </span>
  <span></span>
`,
  [INSERT_TYPE.productSelector]: `
  <span></span>
   <style data-style-id="<%= styleId %>">
    .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] {
      display:inline-block;
      border-radius: 4px;
      padding: 5px 10px;
      margin-left: 1px;
     }

    .insert-placeholder.productSelector .product-selector-card.selected {}
  </style>
  <span
    data-style-id="<%= styleId %>"
    contenteditable="false"
    class="${INSERT_PLACEHOLDER_SELECTOR} ${INSERT_TYPE.productSelector}">
      <span class='id <%= id %>' style="display: none;"><%= id %></span>
      <span class='type <%= type %>' style="display: none;"><%= type %></span>
      <span class='name'><%= name %></span>
  </span>
  <span></span>
`,
  [INSERT_TYPE.tab]: `
  <span></span>
   <style data-style-id="<%= styleId %>">
    .${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="<%= styleId %>"] {
      display:inline-block;
      border-radius: 4px;
      padding: 5px 10px;
      margin-left: 1px;
     }

    .insert-placeholder.tab .container-tab-insert.selected {}
  </style>
  <span
    data-style-id="<%= styleId %>"
    contenteditable="false"
    class="${INSERT_PLACEHOLDER_SELECTOR} ${INSERT_TYPE.tab}">
      <span class='id <%= id %>' style="display: none;"><%= id %></span>
      <span class='type <%= type %>' style="display: none;"><%= type %></span>
      <span class='name'><%= name %></span>
  </span>
  <span></span>
`,
};

export const CONTENT_TO_INSERT = {
  basicIfButton: () => '{{#if <--PLEASE FILL-->}} {{<--PLEASE FILL-->}} {{else}} {{<--PLEASE FILL-->}} {{/if}}',
  basicInsertButton: (type: InsertType) => PLACEHOLDER_MARKUP_BY_TYPE[type],
};
