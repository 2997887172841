<div class="setup-container">
  <ensight-navbar
    navName="Pages"
    [class.disabled]="global.pageLoading || (isPlanLoading$ | async)"
    [style.marginTop.px]="headerHeight"
    [disabled]="isPlanLoading$ | async"
    [staticPages]="navbarStaticPages"
    (updateStaticPages)="updateStaticPages($event)"
    [dndList]="menuPagesList"
    (pageMoved)="pageMoved($event)"
    (planMoved)="planMoved($event)"
    (dndSelect)="selectPage($event)"
    [selectedPlanId]="global.selectedPlanId"
    (collapseViewNavbar)="collapseViewNavbar($event.first); collapse = $event.state"
    [plansOptions]="plansOptions"
    [endPages]="endPages"
    [dependentPages]="getNavbarDependentPages$ | async"
  ></ensight-navbar>

  <article
    [ngClass]="{ 'collapsed-container': collapse }"
    [style.marginTop.px]="headerHeight"
    class="inner-container"
    (mousemove)="mousemoveEvent()"
    (click)="mousemoveEvent()">

    @if (showSpinner || (setupLoading$ | async)) {
      <div class="spinner-holder">
        <div class="container-holder">
          <ac-loader></ac-loader>
          <div class="message">One moment, your page is loading</div>
        </div>
      </div>
    }

    <div #contentContainer id="scrollable-container" class="wrap-content d-flex flex-column justify-content-between">
      @if (!compilePage || (setupLoading$ | async)) {
        <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate()"></router-outlet>
      }
      @if (selectedPageId && selectedPageId !== 'cover' && selectedPageId !== 'target_premium') {
        <footer
          class="content-footer a-app-main-container-background-color text-center"
          >
        </footer>
      }
    </div>

  </article>
  <ensight-confirm-modal></ensight-confirm-modal>
  <ensight-info-modal></ensight-info-modal>
</div>
