<div class="block-section">
  <div class="title">
    @if (plans.length) {
      <ensight-client-section [firstPlan]="plans[0]"></ensight-client-section>
    }
    <button class="btn remove print-elem-hide" (click)="removePage()" [disabled]="isPresentationLocked$ | async">
      Remove page
    </button>
  </div>

  <div class="content">
    <table>
      <thead>
        <tr>
          <th>Carrier and Product</th>
          @if (hasNotes) {
            <th>Notes</th>
          }
          <th>Target Premium</th>
        </tr>
      </thead>
      <tbody>
        @for (plan of plans; track plan) {
          <tr>
            <td>
              <div class="text-overflow-ellipsis company-name">
                <span [title]="plan.configjson.metadata.company_name">
                  {{ plan.configjson.metadata.company_name }}
                </span>
              </div>
              <div class="product-info">
                <div class="text-overflow-ellipsis product-name">
                  <span [title]="plan.configjson.metadata.product_name">
                    {{ plan.configjson.metadata.product_name }}
                  </span>
                </div>
              </div>
            </td>
            @if (hasNotes) {
              <td>
                <div class="note-info">
                  <div>{{ plan.configjson.metadata.note }}</div>
                </div>
              </td>
            }
            <td>
              <div class="target-premium-info">
                <div [innerHTML]="plan.configjson.metadata.target_premium | ifEmpty: 'currency'"></div>
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
