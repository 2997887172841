import { Inject, Injectable } from '@angular/core';

import { find, min, max, union, map, isNil } from 'lodash-es';
import { Store, select } from '@ngrx/store';
import { map as maprx } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as d3 from 'd3';
import { DEFAULT_TITLE_FIELD, NO_DATA_LABEL } from '@shared/constants';
import {
  Y_AXIS_LABEL_DISTANCE,
  MOBILE_DEVICES_REGEXP,
  RANGE_MARGIN,
  DISPLAYABLE_PIN_TYPES,
  COMBO_MARGIN_OFFSET,
  C3_SVG_SELECTOR,
  COMBO_PIN_MARGIN,
  TRANSFORM_SCALE_COEF,
  C3JS_CHART_TYPES,
} from './chart.constants';
import { AppState } from '../../../../reducers';
import { getPresentationPlans } from '../../../../components/presentation/presentation.selectors';
import { Global } from '@shared/services';
import {
  CareerPlan,
  ChartData,
  ChartOptions,
  ChartRange,
  ChartTooltipValues,
  ChartType,
  ConfigPlanOrganisationData,
  DataTarget,
  PlanMetadata,
  PointPosition,
  TotalPinChart,
} from '@core/model';
import { CALCULATED_DATA_TARGET, ChartTypes, PinTotalTypes } from '@core/enums';
import { isDefined, pixels } from '@core/utils';
import { WINDOW_TOKEN } from '@core/constant';
import { toggleNavbar } from 'src/app/common/shared/components/assurance-navbar/navbar.constants';

@Injectable()
export class ChartService {
  constructor(private store: Store<AppState>, private global: Global, @Inject(WINDOW_TOKEN) private window: Window) {}

  offsetXDistinctions: Map<string, number> = new Map();
  chartWrapBoundaries = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    x: 0,
    y: 0,
    height: 0,
    width: 0,
  } as DOMRect;
  transformScaleCoef = TRANSFORM_SCALE_COEF;

  private dataItem: { [key in ChartType]?: (data: ChartData[]) => ChartData[] } = {
    [ChartTypes.comboChart]: data => data,
    [ChartTypes.pie]: data => data,
    [ChartTypes.donut]: data => data,
    [ChartTypes.clusteredBarChart]: data => data,
  };

  private pinTypes: { [key in (typeof DISPLAYABLE_PIN_TYPES)[number]]: (item: ChartData) => Observable<string> } = {
    productComparePin: item =>
      this.getPlanMetadataById(item.planId).pipe(maprx(metadata => metadata && metadata.product_name)),
    metricComparePin: item => {
      const titleField = this.global.getMetricsTitleFieldName();
      const config = this.global.getMetricConfigByKey(item.metricId as string);

      return of(config?.[titleField] || config?.[DEFAULT_TITLE_FIELD] || item.metricTitle);
    },
    carrierComparePin: item =>
      this.getPlanMetadataById(item.planId).pipe(maprx(metadata => metadata && metadata.company_name)),
    customPin: item => {
      return of(item.metricTitle);
    },
  };

  applyStyleToPin(options: ChartOptions, selectorAxisX: string, minusDistance = 0): void {
    const chartPinLine = d3.select(`.${options.id} .chart-pin-line`);
    const chartPinPointSelection = d3.select(`.${options.id} .chart-pin-point`);
    const chartPinPoint = chartPinPointSelection.node() as HTMLElement;
    const axisXLine = d3.select(selectorAxisX).node() as HTMLElement;

    if (axisXLine && chartPinPoint) {
      const distance =
        axisXLine.getBoundingClientRect().top -
        chartPinPoint.getBoundingClientRect().top -
        pixels(chartPinPointSelection.style('height'));

      if (distance) {
        const pinHeight = this.checkTransformProperty()
          ? (distance - minusDistance) / this.transformScaleCoef
          : distance - minusDistance;
        chartPinLine.style('height', `${pinHeight}px`);
      }
    }
  }

  getShareableLink(): string {
    return `${location.origin}/shared-presentation/${this.global.getPresentation?.shareableToken}?linkSource=fromPDF`;
  }

  setOffsetXDistinction(id: string) {
    setTimeout(() => {
      const boundaries = this.getBoundaries(id);
      this.offsetXDistinctions.set(id, boundaries?.left);
    }, 200);
  }

  normalizeData(data: ChartData[], chartType: ChartType) {
    if (!data || !C3JS_CHART_TYPES.includes(chartType)) {
      return;
    }

    return this.dataItem[chartType](data);
  }

  getPositionByValue(value: number, containerSelector: string, isPdf: boolean): PointPosition {
    const isComboChart = true;
    const barWidth = 0;
    const points: NodeList = this.getComboPoints(containerSelector);

    const pointValue = { value, x: 0 };
    let validPoint: DOMRect;
    this.offsetXDistinctions.set(containerSelector, this.getBoundaries(containerSelector).left);
    const currentOffset = this.offsetXDistinctions.get(containerSelector);
    const isTransformedChart = this.checkTransformProperty();

    const adjustedOffset = isTransformedChart ? currentOffset / this.transformScaleCoef : currentOffset;

    let elementIndex = 0;

    for (let i = 0; i < points.length; i++) {
      const point = points[i] as SVGRectElement; // Node
      const data: any = d3.select(point).datum(); // Datum
      const x = data?.[0] ? data?.[0].x : data?.x;

      if (x === value) {
        validPoint = point.getBoundingClientRect();
        elementIndex = i;
        break;
      }
    }

    if (validPoint && isComboChart) {
      const xTick = this.setComboChartXPosition(containerSelector, elementIndex);

      pointValue.x = Math.floor(xTick - adjustedOffset - Y_AXIS_LABEL_DISTANCE + (isPdf ? COMBO_PIN_MARGIN : 0));
    } else if (validPoint) {
      const adjustedLeftPosition = isTransformedChart ? validPoint.left / TRANSFORM_SCALE_COEF : validPoint.left;

      pointValue.x = Math.floor(adjustedLeftPosition - adjustedOffset - Y_AXIS_LABEL_DISTANCE + barWidth);
    }

    return pointValue;
  }

  private setComboChartXPosition(containerSelector: string, elementIndex: number): number {
    const tickElements = d3.selectAll(`#c3Chart-${containerSelector} .c3-axis.c3-axis-x g.tick line`);
    const elementRect = (tickElements.nodes()[elementIndex] as HTMLElement)?.getBoundingClientRect()?.x;

    return this.checkTransformProperty() ? elementRect / this.transformScaleCoef : elementRect;
  }

  getValueByPointPosition(position: number, containerSelector: string): PointPosition {
    const isComboChart = true;
    const points: NodeListOf<SVGRectElement> = this.getComboPoints(containerSelector);

    return this.getBarChartData(points, position, containerSelector, isComboChart);
  }

  getTooltipDataByPinPosition(
    chartData: ChartData[],
    x: number,
    pinType: string,
    tooltipTotalOptions: TotalPinChart,
    metricDataSource: DataTarget
  ): ChartTooltipValues<Observable<string>>[] {
    let yTotal = 0;
    const source = metricDataSource === CALCULATED_DATA_TARGET.metadata ? 'meta' : 'data';

    const tooltipDataByPinPosition = chartData.map((item: ChartData) => {
      const y = this.getYFromMetricData(item, x, source) ?? this.getYByX(x, item.values);
      yTotal += isNil(y) || isNaN(y) ? 0 : Number(y);

      const formattedY = y === null ? y : item.isCustomMetric ? Number(y).toFixed(2) : `${y}`;

      return {
        color: item.color,
        y: formattedY,
        isPercentYAxis: item.isPercentYAxis,
        isWholeNumberYAxis: item.isWholeNumberYAxis,
        title$: this.pinTypes[pinType](item),
        noValueLabel: NO_DATA_LABEL,
        hideMetric: item.hideMetric,
        isCustomMetric: item.isCustomMetric,
      };
    });

    if (tooltipTotalOptions.pinTotalType && tooltipTotalOptions.pinTotalType !== PinTotalTypes.noTotal) {
      const total = {
        isTotal: true,
        color: tooltipTotalOptions.pinTotalColor,
        y: yTotal.toString(),
        isPercentYAxis: chartData[0]?.isPercentYAxis,
        isWholeNumberYAxis: chartData[0]?.isWholeNumberYAxis,
        title$: of(tooltipTotalOptions.pinTotalLabel),
        noValueLabel: NO_DATA_LABEL,
        hideMetric: false,
        isCustomMetric: chartData[0]?.isCustomMetric,
      };
      tooltipTotalOptions.pinTotalType === PinTotalTypes.bottomTotal
        ? tooltipDataByPinPosition.push(total)
        : tooltipDataByPinPosition.unshift(total);
    }

    return tooltipDataByPinPosition;
  }

  hoverBar(value: number, containerSelector: string, className: string, isPDF: boolean) {
    const points = this.getComboBarHighlightPoints(containerSelector);
    points.forEach(point => point.classList.remove(className));

    const matchingBar = Array.from(points).find(point => {
      const datum: any = d3.select(point).datum();

      if (datum.values) {
        return datum.values.some(v => v.x === value);
      }

      return datum.x === value;
    });

    if (matchingBar) {
      const datum: any = d3.select(matchingBar).datum();
      const shapeIndex = datum.values ? datum.values.findIndex(v => v.x === value) : datum.index;

      const targetBars = document.querySelectorAll(`#c3Chart-${containerSelector} .c3-shape-${shapeIndex}.c3-bar`);
      targetBars.forEach(bar => {
        bar.classList.add(className);

        // We have to add fill-opacity property for PDF generation.
        if (isPDF) {
          (bar as SVGElement).style.fillOpacity = '1';
        }
      });
    }
  }

  resetHoverBar(containerSelector: string, className: string, isPDF?: boolean) {
    const points: NodeListOf<SVGRectElement> = document.querySelectorAll(`.${containerSelector} .${className}`);

    for (let i = 0; i < points.length; i++) {
      points[i].classList.remove(className);
      isPDF && (points[i].style.fillOpacity = '');
    }
  }

  highlightBar(x: number, pageId: string, className: string, isPDF = false) {
    this.resetHoverBar(pageId, className, isPDF);
    this.hoverBar(x, pageId, className, isPDF);
  }

  getYByX(x: number, values: { x: number; y: number }[]): number | null {
    const point = find(values, { x });

    return point ? point.y : null;
  }

  getDataRange(data: ChartData[]): ChartRange {
    let x: number[] = [];
    let y: number[] = [];

    data.map((item: ChartData) => {
      if (!item.disable) {
        x = union(x, map(item.values, 'x')).map(Number);
        y = union(y, map(item.values, 'y')).map(Number);
      }
    });

    return {
      minX: min(x),
      maxX: max(x),
      minY: min(y),
      maxY: max(y),
    };
  }

  getForceY(data: ChartData[]): number[] {
    const range = this.getDataRange(data);
    const maxY = Number(range.maxY === 0) || RANGE_MARGIN * range.maxY;
    const minY = range.minY < 0 ? range.minY : 0;

    return [minY, maxY];
  }

  isMobileDevice(): boolean {
    return MOBILE_DEVICES_REGEXP.test(navigator.userAgent);
  }

  checkTransformProperty(): boolean {
    if (this.window.innerWidth >= toggleNavbar) {
      return false;
    }

    const element = document.querySelector('.main-content');
    const style = element && this.window.getComputedStyle(element);
    const matrix = style?.transform || style?.webkitTransform;

    if (style && style.getPropertyValue('zoom') && Number(style.getPropertyValue('zoom')) !== 1) {
      this.transformScaleCoef = Number(style.getPropertyValue('zoom'));

      return true;
    }

    if (matrix && matrix !== 'none') {
      const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');
      this.transformScaleCoef = Number(matrixValues[0]);

      return true;
    }

    return false;
  }

  drawUnlimitedLine(chart: any, data: ChartData[], isPdf: boolean): void {
    const found = data.find(
      (item: ChartData) => item.isTotalLTCBalance && item.isTotalLTCBalanceUnlimited && !item.disable
    );

    if (!found) {
      return;
    }

    const svg = d3.select(chart.element).select('svg');
    const width = d3.select(chart.element).node()?.offsetWidth || 0;

    if (data.length === 1) {
      svg.selectAll('.c3-axis-y .tick')?.style('display', 'none');
    }

    svg
      .select('.c3-chart')
      .append('g')
      .attr('class', 'labels-lines')
      .append('line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', isPdf ? 110 : 50)
      .attr('y2', isPdf ? 110 : 50)
      .style('stroke', found.colorBase)
      .attr('stroke-width', '0.25vw');

    svg
      .select('.c3-axis-y')
      .append('rect')
      .attr('x', -70)
      .attr('y', isPdf ? 100 : 40)
      .attr('width', 70)
      .attr('height', 20)
      .style('fill', '#fff');

    svg
      .select('.c3-axis-y')
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('class', 'unlimited-text')
      .attr('x', -35)
      .attr('y', isPdf ? 113 : 53)
      .attr('style', `font-size: 11px; font-weight: bold; stroke-width:1px; fill:${found.colorBase}`)
      .text('UNLIMITED');
  }

  private getBarChartData(
    points: NodeListOf<SVGRectElement>,
    position: number,
    containerSelector: string,
    isComboChart = true
  ): PointPosition {
    const pointData = Array.from(points).map(point => {
      const bounds = point.getBoundingClientRect();
      const leftBoundaries = this.checkTransformProperty() ? bounds.left / this.transformScaleCoef : bounds.left;
      const center = Math.floor(leftBoundaries + bounds.width / 2);

      const classNames = point.getAttribute('class') || '';
      const indexMatch = classNames.match(/c3-shape-(\d+)/);
      const dataIndex = indexMatch ? parseInt(indexMatch[1], 10) : -1;

      return {
        point,
        left: Math.floor(leftBoundaries),
        width: bounds.width,
        center,
        dataIndex,
      };
    });

    const columns = new Map();
    pointData.forEach(point => {
      if (!columns.has(point.dataIndex)) {
        columns.set(point.dataIndex, []);
      }

      columns.get(point.dataIndex).push(point);
    });

    let nearestPoint = pointData[0];
    let minDistance = Math.abs(position - nearestPoint.center);

    for (let i = 1; i < pointData.length; i++) {
      const distance = Math.abs(position - pointData[i].center);

      if (distance < minDistance) {
        minDistance = distance;
        nearestPoint = pointData[i];
      }
    }

    const point = nearestPoint.point;
    const datum: any = d3.select(point).datum();

    let value: number;

    if (datum?.values && datum.values[nearestPoint.dataIndex]) {
      value = datum.values[nearestPoint.dataIndex].x;
    } else {
      value = datum?.[0] ? datum[0].x : datum?.x;
    }

    let x: number;
    const currentOffset = this.offsetXDistinctions.get(containerSelector);

    if (isComboChart) {
      const xTick = this.setComboChartXPosition(containerSelector, nearestPoint.dataIndex);
      x = Math.floor(xTick - currentOffset + COMBO_MARGIN_OFFSET);
    } else {
      const barWidth = point.width.baseVal.value / 2;
      x = Math.floor(nearestPoint.left - currentOffset + barWidth);
    }

    return { value, x, isBarChart: true, isComboChart };
  }

  private getBoundaries(containerSelector: string, classSelector = C3_SVG_SELECTOR): DOMRect {
    const chartWrapArea: SVGAElement = document.querySelector(`.${containerSelector} ${classSelector}`);

    return chartWrapArea
      ? chartWrapArea.getBoundingClientRect()
      : ({
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          x: 0,
          y: 0,
          height: 0,
          width: 0,
        } as DOMRect);
  }

  private getPlanMetadataById(planId: number): Observable<PlanMetadata> {
    return this.store.pipe(
      select(getPresentationPlans),
      maprx((plans: CareerPlan[]) => {
        const plan = find(plans, { id: planId });

        return plan ? plan.configjson.metadata : null;
      })
    );
  }

  private getYFromMetricData(item: ChartData, x: number, source: string): number | null {
    const metricConfig = item.isCustomMetric
      ? this.global.getCustomMetricConfigByKey(item.metricId, item?.productIndex)
      : this.global.getMetricConfigByKey(item.metricId, source);

    const plan = this.global.getCurrentCarrierPlans?.find(plan => plan.id === item.planId)?.configjson[source];

    if (!metricConfig || !plan) {
      return null;
    }

    //TODO: planDataKeyX could be undefined
    const indexOfX = isDefined(item.planDataKeyX)
      ? plan[item.planDataKeyX]?.findIndex(value => parseInt(value, 10) === x)
      : -1;

    const y =
      indexOfX !== -1
        ? item.isCustomMetric
          ? metricConfig[indexOfX]
          : plan[(metricConfig as ConfigPlanOrganisationData).db][indexOfX]
        : null;

    return y;
  }

  private getComboPoints(containerSelector: string): NodeListOf<SVGRectElement> {
    return document.querySelectorAll(
      `.${containerSelector} .c3-chart-bars .c3-shape.c3-bar, .${containerSelector} .c3-chart-line .c3-circles .c3-circle`
    );
  }

  private getComboBarHighlightPoints(containerSelector: string): NodeListOf<SVGRectElement> {
    return document.querySelectorAll(`.${containerSelector} .c3 .c3-chart-bars .c3-bar`);
  }
}
