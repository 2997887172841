<ac-modal-header (close)="close()">
  <p class="title">{{ data.name }}</p>
</ac-modal-header>

<ac-modal-body>
  @if (data.subTitle) {
    <p class="title-list">{{ data.subTitle }}</p>
  }
  <ul class="item-list">
    @for (item of data.itemList; track item; let i = $index) {
      <li class="item">
        <a
          href="{{ item.link }}"
          target="_blank"
          class="link"
          >
          <span>{{ i + 1 }}. {{ item.name }}</span>
          @if (item.versionName) {
            <span > - Version: "{{ item.versionName }}"</span>
            <span class="open-icon"></span>
          }
        </a>
      </li>
    }
  </ul>
</ac-modal-body>

<ac-modal-footer>
  <se-button class="secondary" size="medium" (click)="close()">Close</se-button>
</ac-modal-footer>
