import { ChartConfiguration } from 'c3';
import { ChartBackgroundType, ChartType, PointData, XAxisSourceType } from '@core/model';
import { ChartTypes } from '@core/enums';

export const CHART_HEIGHT = 450;
export const PIN_LINE_HEIGHT = 345;
export const PIN_LINE_HEIGHT_BAR_CHART = 375;
export const X_AXIS_SOURCE_DEFAULT = 'eoy_age';
export const X_AXIS_SOURCES: XAxisSourceType[] = [X_AXIS_SOURCE_DEFAULT, 'policy_year'];
export const CHART_BACKGROUND_DEFAULT = 'line';
export const CHART_INTERPOLATE_DEFAULT = 'line';
export const CHART_BACKGROUNDS: ChartBackgroundType[] = [CHART_BACKGROUND_DEFAULT, 'solid', 'metricBased'];

export const C3JS_CHART_TYPES: ChartType[] = [
  ChartTypes.comboChart,
  ChartTypes.pie,
  ChartTypes.donut,
  ChartTypes.clusteredBarChart,
];
export const DISPLAYABLE_PIN_TYPES = <const>['productComparePin', 'metricComparePin', 'carrierComparePin', 'customPin'];
export const NON_DISPLAYABLE_PIN_TYPE = <const>'noPin';
export const CHART_MARGIN_BOTTOM = 60;
export const PIN_MARGIN = 50;
export const COMBO_PIN_MARGIN = 30;
export const PDF_COMBO_CHART_MARGIN = 50;
export const PIN_BOTTOM_MARGIN = 55;
export const PIN_MIN_POSITION = PIN_MARGIN * 1.2;
export const Y_AXIS_LABEL_DISTANCE = 50;
export const COMBO_Y_AXIS_LABEL_DISTANCE = 68;
export const COMBO_MARGIN_OFFSET = 18;
export const RANGE_MARGIN = 1.2;
export const PIN_VALUE_MARGIN = 10;
export const PIN_BLOCK_WIDTH = 100;
export const CHART_TOP_MARGIN = 0.33;
export const WIDTH_MULTIPLIER_VALUE = 0.2;
export const DEFAULT_OPACITY_VALUE = '0.2';
export const DEFAULT_BAR_OPACITY_VALUE = '1';
export const Y_AXIS_LABEL_WIDTH = 25;
export const Y2_AXIS_LABEL_WIDTH = 35;
export const PIN_BLOCK_PADDING = 6;
export const PIN_POINT_DIAMETER = 24;
export const defaultXAxisTickColor = '#148C53';
export const DEFAULT_PADDING_IN_VW = 2.604;

export const C3JSMobileOffSet = 3;
export const C3JSDefaultOffSet = 5;
export const TRANSFORM_SCALE_COEF = 1.5;

export enum ActionTypes {
  MouseUpSuccess = '[Chart Mouse Event] Chart Mouse Event Success',
  MouseUpReset = '[Chart Mouse Event] Chart Mouse Event Reset',
}

export const HOVERED_BAR_SELECTOR = 'bar-hovered';
export const SELECTED_BAR_SELECTOR = 'bar-selected';
export const C3_SVG_SELECTOR = '.c3 > svg';
export const MOBILE_DEVICES_REGEXP = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

export const DEFAULT_OPTIONS = {
  chart: {
    margin: {
      right: 20,
      bottom: CHART_MARGIN_BOTTOM,
    },
    showLegend: false,
    showControls: false,
    x: (d: PointData) => d && d.x,
    y: (d: PointData) => d && d.y,
    useVoronoi: false,
    clipEdge: true,
    xAxis: {
      showMaxMin: true,
    },
    yAxis: {
      axisLabelDistance: Y_AXIS_LABEL_DISTANCE,
      showMaxMin: false,
    },
    zoom: {
      enabled: false,
    },
    forceY: null,
  },
};

export const C3_DEFAULT_OPTIONS: ChartConfiguration = {
  data: {
    x: 'x',
    type: 'bar',
    order: null,
  },
  grid: {
    y: {
      show: true,
    },
  },
  tooltip: {
    show: false,
  },
  legend: {
    show: false,
  },
  interaction: {
    enabled: true,
  },
};

export const CUSTOM_CHART_OPTIONS = {
  chart: {
    useInteractiveGuideline: false,
    height: CHART_HEIGHT,
    pinLineHeight: PIN_LINE_HEIGHT,
    margin: {
      top: 20,
    },
  },
};
