import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { PermissionKeyEnum } from '@core/enums';
import {
  CUSTOM_PAGES_LIST_URL,
  CustomPageListLabelValueKey,
  CustomPageListSearchValueKey,
  CustomPageTableBodyColumns,
  CustomPageTableHeaderColumns,
  DefaultOrderByRequestParams,
  PageButtons,
  TableExpendedDetailsColumns,
} from '../../constants';
import { CustomPageListService, CustomPageService, ImportExportService, ModalProviderService } from '../../services';
import { NoDataMessage } from '@shared/constants';
import {
  CopyCustomPage,
  CustomPage,
  RowClickEvent,
  TableBodyColumn,
  TableColumn,
  TableHeaderColumn,
  TablePaginationOptions,
  TableRequestParams,
  UsedInItemList,
} from '@shared/models';
import { ModalExecutor } from '@shared/services';
import { ConfirmModalComponent } from '../../../../components/modals/confirm-modal/confirm-modal.component';
import { CustomPagesLists } from '../../models';
import { BrowserSessionStorage } from '@core/service';
import { Title } from '@angular/platform-browser';

@UntilDestroy()
@Component({
  selector: 'ep-custom-page-list',
  templateUrl: './custom-page-list.component.html',
  styleUrls: ['./custom-page-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CustomPageListComponent implements OnInit {
  pageButtons = PageButtons;
  Permissions = PermissionKeyEnum;
  importLoading = false;
  noDataMessage = NoDataMessage;
  loadingRecords = false;
  tableData: CustomPage[] = [];
  tableHeaderColum: TableHeaderColumn[] = [];
  tableBodyColum: TableBodyColumn[] = [];
  currentSalesConceptIndex: number;
  searchControl = new UntypedFormControl();
  paginationOptions: TablePaginationOptions;
  isExpended = false;
  expendedColumns: TableBodyColumn[] = [];
  isDependentPagesLoading = false;
  dependentPages: CustomPage[] = [];
  activeButtonKey: string;
  currentRowIndex: number;
  currentDependentPageIndex: number;
  isSalesConceptMenuOpen = false;
  isDependentPageMenuOpen = false;
  rowHeaderStyle = { border: 'none', boxShadow: 'none' };
  rowBodyStyle = { borderBottom: '1px solid #D3D3D3' };
  dependentTableRowColumn: TableBodyColumn[] = [];
  currentOrderByParams: TableRequestParams = DefaultOrderByRequestParams;
  listUrl = CUSTOM_PAGES_LIST_URL;

  private data: CustomPagesLists;

  constructor(
    private router: Router,
    private importExportService: ImportExportService,
    private cdr: ChangeDetectorRef,
    private modalExecutor: ModalExecutor,
    private customPageService: CustomPageService,
    private customPageListService: CustomPageListService,
    private modalProvider: ModalProviderService,
    private browserSessionStorage: BrowserSessionStorage,
    private titleService: Title
  ) {}

  @HostListener('window:resize', ['$event']) onResize() {
    this.closeMenus();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Custom Pages');
    this.checkIfStorageHasLabel();
    this.currentOrderByParams = this.customPageListService.getOrderByParams();
    this.watchForRequestParams(this.getInitialValue());
    this.watchForSearch();
    this.dependentTableRowColumn = this.getFilteredColumns(CustomPageTableBodyColumns, 'dependentTable');
    this.customPageService.setCurrentCustomPageAndVersions(null);
  }

  toggleSalesConceptMenu(index: number): void {
    this.isDependentPageMenuOpen = false;
    this.currentSalesConceptIndex = index;
    this.isSalesConceptMenuOpen = !this.isSalesConceptMenuOpen;
  }

  toggleDependentPageMenu(index: number): void {
    this.isSalesConceptMenuOpen = false;
    this.currentDependentPageIndex = index;
    this.isDependentPageMenuOpen = !this.isDependentPageMenuOpen;
  }

  setSortedCustomList(value: TableRequestParams): void {
    this.loadingRecords = true;
    this.currentOrderByParams = value;
    this.customPageListService.setOderByRequestParams(value);
  }

  closeMenus(): void {
    this.isSalesConceptMenuOpen = false;
    this.isDependentPageMenuOpen = false;
  }

  selectPage(event: Record<string, string>): void {
    this.resetExpended();
    this.activeButtonKey = event.key;
    this.browserSessionStorage.setItem(CustomPageListLabelValueKey, JSON.stringify({ LABEL: event.key }));
    this.pageButtons = this.pageButtons.map(item => ({ ...item, active: event.key === item.key }));
    this.currentOrderByParams = DefaultOrderByRequestParams;

    if (this.data[event.key].count) {
      this.loadingRecords = true;
      this.getList({ ...this.customPageListService.getSearchParams(), ...this.currentOrderByParams, PAGE: 1 });
    } else {
      this.tableData = [];
    }
  }

  goToPage(path: string[]): void {
    this.router.navigate([CUSTOM_PAGES_LIST_URL, ...path]);
    this.closeMenus();
  }

  expendRow(event: RowClickEvent<CustomPage>): void {
    if (!event.record.numDependentPages) {
      return;
    }

    this.isExpended = this.currentRowIndex === event.index ? !this.isExpended : true;
    this.currentRowIndex = event.index;
    this.isExpended ? this.getDependentPages(event.record._id) : (this.dependentPages = []);
  }

  importCustomPage({ file }: { file: File }): void {
    this.importLoading = true;

    this.importExportService
      .importCustomPage({ file })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.importLoading = false;
        this.getList();
      });
  }

  exportCustomPage(uiId: string, name: string): void {
    this.importExportService.exportCustomPage(uiId, name).pipe(untilDestroyed(this)).subscribe();
  }

  setPaginationParams(options: TablePaginationOptions): void {
    this.resetExpended();
    this.loadingRecords = true;
    this.customPageListService.setTableRequestParams(options);
  }

  copyCustomPage(customPageUUID: string, name: string): void {
    const modal = this.modalProvider.openCopyModal({ name, customPageUUID });

    modal.afterClosed
      .pipe(
        untilDestroyed(this),
        filter((value: null | CopyCustomPage) => !!value),
        switchMap((copyData: CopyCustomPage) => this.customPageService.copyCustomPage(copyData))
      )
      .subscribe(() => this.getList());
  }

  removeCustomPage(data: CustomPage): void {
    this.modalExecutor
      .execute(ConfirmModalComponent, {
        message: `You are going to remove ${data.name && data.name.toUpperCase()} custom page. Continue?`,
      })
      .pipe(
        filter((value: boolean) => !!value),
        switchMap(() => this.customPageService.removeCustomPage(data._id, data.uiId, data.labels)),
        untilDestroyed(this)
      )
      .subscribe(() => this.getList());
  }

  openUsedInGroupsModal(record: CustomPage): void {
    if (record.usedInGroups.length) {
      this.modalProvider.openUsedInModal({
        name: record.name,
        itemList: this.addLinkUsedInGroups(record.usedInGroups),
        subTitle: 'Groups:',
      });
    }
  }

  private addLinkUsedInGroups(record: UsedInItemList[]): UsedInItemList[] {
    return record.map(group => ({
      ...group,
      link: `/user-management/groups/${group.id}/permissions-rules`,
    }));
  }

  private getList(params?: TableRequestParams): void {
    this.loadingRecords = true;

    const pagination = this.customPageListService.getPaginationParams();
    const search = this.customPageListService.getSearchParams();

    const defaultParams = { ...pagination, ...search, ...DefaultOrderByRequestParams };

    this.customPageService
      .getPagesList({ ...(params || defaultParams), LABEL: this.activeButtonKey })
      .pipe(
        untilDestroyed(this),
        tap(() => this.resetExpended())
      )
      .subscribe((data: CustomPagesLists) => this.setData(data));
  }

  private prepareColumns(): void {
    this.tableHeaderColum = this.getFilteredColumns(CustomPageTableHeaderColumns) as TableHeaderColumn[];
    this.tableBodyColum = this.getFilteredColumns(CustomPageTableBodyColumns) as TableBodyColumn[];
  }

  private getFilteredColumns(columns: TableColumn[], key?: string): TableColumn[] {
    return columns.filter((column: TableColumn) => column.include.includes(key || this.activeButtonKey));
  }

  private watchForRequestParams(initialValue?: Record<string, string>): void {
    this.loadingRecords = true;

    this.customPageListService
      .watchForRequestParams()
      .pipe(
        tap(() => this.resetExpended()),
        switchMap((params: TableRequestParams) =>
          this.customPageService.getPagesList({ ...params, LABEL: this.activeButtonKey, ...initialValue })
        ),
        untilDestroyed(this)
      )
      .subscribe((data: CustomPagesLists) => {
        initialValue = null;
        this.setData(data);
      });
  }

  private setData(data: CustomPagesLists): void {
    const pages = data[this.activeButtonKey];

    this.prepareButtons(data);
    this.prepareColumns();
    this.prepareExpendedColumns();

    this.data = data;
    this.paginationOptions = {
      currentPage: pages.currentPage,
      totalElements: pages.count,
      itemsPerPage: 10,
    };
    this.tableData = pages.data;
    this.loadingRecords = false;
    this.cdr.markForCheck();
  }

  private prepareButtons(data: CustomPagesLists): void {
    this.pageButtons = PageButtons.map(btn => {
      const symbolDot = String.fromCodePoint(9679);
      const symbolEmpty = String.fromCodePoint(8203);

      return {
        ...btn,
        active: this.activeButtonKey === btn.key,
        label: `${btn.label} ${symbolEmpty} ${symbolDot} ${symbolEmpty} ${data[btn.key].count}`,
        disabled: false,
      };
    });
  }

  private watchForSearch(): void {
    this.searchControl.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        tap(() => (this.loadingRecords = true)),
        tap(() => this.resetExpended()),
        tap((value: string) => this.handleStorageSearchValue(value)),
        debounceTime(500)
      )
      .subscribe((value: string) => this.customPageListService.setSearchRequestParams(value));
  }

  private getInitialValue(): Record<string, string> {
    const search = this.browserSessionStorage.getItem(CustomPageListSearchValueKey);
    const label = this.browserSessionStorage.getItem(CustomPageListLabelValueKey);
    const searchValue = search ? JSON.parse(search)?.SEARCH_VALUE : null;

    this.customPageListService.setSearchRequestParams(searchValue);
    this.searchControl.setValue(searchValue || '');
    this.activeButtonKey = JSON.parse(label).LABEL;

    return { ...JSON.parse(search), ...JSON.parse(label) };
  }

  private prepareExpendedColumns(): void {
    this.expendedColumns = this.getFilteredColumns(TableExpendedDetailsColumns);
    this.expendedColumns.forEach((column: TableBodyColumn) => (column.colSpan = this.tableBodyColum.length));
  }

  private getDependentPages(id: string): void {
    this.isDependentPagesLoading = true;

    this.customPageService
      .getDependentPagesOfPage(id)
      .pipe(untilDestroyed(this))
      .subscribe((data: CustomPage[]) => {
        this.dependentPages = data.map(record => ({
          ...record,
          url: this.router.createUrlTree([CUSTOM_PAGES_LIST_URL, record._id]),
        }));
        this.isDependentPagesLoading = false;
      });
  }

  private resetExpended(): void {
    this.isExpended = false;
    this.dependentPages = [];
    this.cdr.markForCheck();
  }

  private handleStorageSearchValue(value: string): void {
    if (value) {
      this.browserSessionStorage.setItem(CustomPageListSearchValueKey, JSON.stringify({ SEARCH_VALUE: value }));
    } else {
      this.browserSessionStorage.removeItem(CustomPageListSearchValueKey);
    }
  }

  private checkIfStorageHasLabel(): void {
    const label = this.browserSessionStorage.getItem(CustomPageListLabelValueKey);

    if (!label) {
      this.browserSessionStorage.setItem(CustomPageListLabelValueKey, '{ "LABEL": "salesconcept" }');
    }
  }
}
